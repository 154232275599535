import { headerConf, productMetaDataDefinitionsConf } from "../conf";

const getOrder = (path: string) => {
  switch (path) {
    // Header

    case "Header":
      return headerConf.blockList.map((i) => i.displayElementName);

    // General Definitions

    case path.match(/GeneralDefinitions\.ControlGears\.ControlGear\[\d+\]/)
      ?.input:
      return [
        "@id",
        "Name.Locale",
        "Description.Locale",
        "NominalVoltage",
        "StandbyPower",
        "ConstantLightOutputStartPower",
        "ConstantLightOutputEndPower",
        "PowerConsumptionControls",
        "Dimmable",
        "ColorControllable",
        "Interfaces",
        "EnergyLabels",
      ];

    case path.match(
      /GeneralDefinitions\.Emitters\.Emitter\[\d+\]\.FixedLightEmitter\[\d+\]/
    )?.input:
      return [
        "Name.Locale",
        "Rotation",
        "PhotometryReference",
        "LightSourceReference",
        "ControlGearReference",
        "RatedLuminousFlux",
        "RatedLuminousFluxRGB",
        "EmergencyBallastLumenFactor",
        "EmergencyRatedLuminousFlux",
        "@emergencyBehaviour",
      ];

    case path.match(
      /GeneralDefinitions.Photometries.Photometry\[\d+\]\.DescriptivePhotometry/
    )?.input:
      return [
        "LuminaireLuminance",
        "LightOutputRatio",
        "LuminousEfficacy",
        "DownwardFluxFraction",
        "DownwardLightOutputRatio",
        "UpwardLightOutputRatio",
        "TenthPeakDivergence",
        "HalfPeakDivergence",
        "PhotometricCode",
        "CIE-FluxCode",
        "CutOffAngle",
        "UGR-4H8H-70-50-20-LQ",
        "IESNA-LightDistributionDefinition",
        "LightDistributionBUG-Rating",
      ];

    case path.match(/ProductDefinitions.Variants.Variant\[\d+\]$/)?.input:
      return [
        "@id",
        "ProductNumber.Locale",
        "Name.Locale",
        "Description.Locale",
        "TenderText.Locale",
        "GTIN",
        "Mountings",
        "Geometry",
        "ProductSeries",
        "Pictures",
        "Symbol",
        "DescriptiveAttributes",
      ];

    case path.match(/ProductDefinitions.Variants.Variant\[\d+\]\.Mountings/)
      ?.input:
      return ["Ceiling", "Wall", "WorkingPlane", "Ground"];

    case path.match(
      /GeneralDefinitions\.LightSources\.FixedLightSource\[\d+\]\.ColorInformation/
    )?.input:
      return [
        "ColorRenderingIndex",
        "Cie1931ColorAppearance",
        "InitialColorTolerance",
        "MaintainedColorTolerance",
        "RatedChromacityCoordinateValues",
        "TLCI",
        "IES-TM-30-15",
        "MelanopicFactor",
      ];

    case path.match(
      /GeneralDefinitions.LightSources.LightSource\[\d+\]\.ColorInformation\['IES-TM-30-15'\]/
    )?.input:
      return ["Rf", "Rg"];

    // Product Definitions

    case "ProductDefinitions.ProductMetaData":
      return productMetaDataDefinitionsConf.blockList.map(
        (i) => i.displayElementName
      );

    case "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Mechanical":
      return [
        "ProductSize",
        "ProductForm",
        "SealingMaterial.Locale",
        "Adjustabilities",
        "IKRating",
        "ProtectiveAreas",
        "Weight",
      ];

    case "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Mechanical.ProductSize":
      return ["Length", "Width", "Height"];

    case "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Electrical":
      return [
        "ClampingRange",
        "SwitchingCapacity",
        "ElectricalSafetyClass",
        "IngressProtectionIPCode",
        "PowerFactor",
        "ConstantLightOutput",
      ];

    case "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Marketing":
      return [
        "ListPrices.ListPrice",
        "HousingColors.HousingColor",
        "Markets.Market",
        "HyperLinks",
        "Designer",
        "ApprovalMarks.ApprovalMark",
        "DesignAwards.DesignAward",
        "Applications.Application",
      ];

      case "ProductDefinitions.ProductMetaData.DescriptiveAttributes.OperationsAndMaintenance":
      return [
        "UsefulLifeTimes.UsefulLife",
        "MedianUsefulLifeTimes.MedianUsefulLife",
        "OperatingTemperature",
        "AmbientTemperature",
        "RatedAmbientTemperature",
        "ATEX",
        "AcousticAbsorptionRates.AbsorptionRate"
      ];

    default:
      return "";
  }
};

export default getOrder;

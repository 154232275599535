import * as React from 'react';
import Mechanical from '../DescriptiveAttributes/Mechanical';
import Electrical from '../DescriptiveAttributes/Electrical';
import Emergency from '../DescriptiveAttributes/Emergency';
import MountingAndAccessory from '../DescriptiveAttributes/MountingAndAccessory';
import OperationsAndMaintenance from '../DescriptiveAttributes/OperationsAndMaintenance';
import Marketing from '../DescriptiveAttributes/Marketing';

import * as _ from 'lodash';

const VariantDescriptiveAttributes = ({
    mode,
    product,
    path,
    elementName,
    elementData,
    validationErrors,
    setValidationErrorsHandler,
    unSetValidationErrorsHandler,
    selectedVariant,
    debugElement,
    blockSubConf,
    changeAttribute,
    deleteAttribute,
}: any) => {
    debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

    if (typeof (selectedVariant) !== "undefined") {
        return (
            <>
                {blockSubConf === 'Mechanical' ? (
                    <Mechanical
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Mechanical"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        elementData={_.get(elementData, 'Mechanical')}
                    />
                ) : null}
                {blockSubConf === 'Electrical' ? (
                    <Electrical
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Electrical"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        elementData={_.get(elementData, 'Electrical')}
                    />
                ) : null}
                {blockSubConf === 'Emergency' ? (
                    <Emergency
                        product={product}
                        path={path}
                        elementName="Emergency"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        elementData={_.get(elementData, 'Emergency')}
                    />
                ) : null}
                {blockSubConf === 'MountingAndAccessory' ? (
                    <MountingAndAccessory
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="MountingAndAccessory"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        elementData={_.get(elementData, 'MountingAndAccessory')}
                    />
                ) : null}
                {blockSubConf === 'Marketing' ? (
                    <Marketing
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Marketing"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        elementData={_.get(elementData, 'Marketing')}
                    />
                ) : null}
                {blockSubConf === 'OperationsAndMaintenance' ? (
                    <OperationsAndMaintenance
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="OperationsAndMaintenance"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        elementData={_.get(elementData, 'OperationsAndMaintenance')}
                    />
                ) : null}
            </>
        );
    }

    return debugElement ?
        <strong style={{color: '#f68f9c'}}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default VariantDescriptiveAttributes;

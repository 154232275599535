import * as React from "react";
import { Button } from "@mui/material";
import { PlainNumber } from "..";
import { Validators } from "../../../util/validators";
import { blockSubGrouping } from "../../../css/theme";
import * as _ from "lodash";

const XYNumberElement = ({
  mode,
  isFloat,
  path,
  product,
  elementName,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement &&
    console.log(`REFERENCE DATA ${elementName}`, elementData, debugElement);
  if (!_.isNil(elementData)) {
    return (
      <>
        <div style={blockSubGrouping}>
          <div style={{ margin: "10px" }}>X</div>
          <PlainNumber
            mode={mode}
            isFloat={isFloat}
            product={product}
            path={`${path}.X`}
            key={`${elementName}-X`}
            classes="xyzelement-X-class"
            elementName={`${elementName}-X`}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            validationConf={[
              {
                validator: Validators.nonNegativeNumber,
              },
            ]}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.X}
          />
          <br />
          <div style={{ margin: "10px" }}>Y</div>
          <PlainNumber
            mode={mode}
            isFloat={isFloat}
            product={product}
            path={`${path}.Y`}
            key={`${elementName}-Y`}
            classes="xyzelement-Y-class"
            elementName={`${elementName}-Y`}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            validationConf={[
              {
                validator: Validators.nonNegativeNumber,
              },
            ]}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Y}
          />
          <br />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{
            border: "#8c6800 1px solid",
            color: "#fff",
            backgroundColor: "#ffbd002e",
            lineHeight: "0",
          }}
          onClick={() => {
            changeAttribute(path, { X: 1.01, Y: 2.01 }, true, false);
          }}
        >
          ADD
        </Button>
      </>
    );
  }
};

export default XYNumberElement;

import * as _ from "lodash";

//ToDo: add default value
//ToDo: add language selection
export const getVariantName = (product: any, selectedVariant: number) => {
  return _.get(
    product,
    `ProductDefinitions.Variants.Variant[${selectedVariant}]`
  ).Name.Locale[0]["$"];
};

export const getSelectedVariant = (product: any, selectedVariant: number) => {
  return _.get(
    product,
    `ProductDefinitions.Variants.Variant[${selectedVariant}]`
  );
};

export const getFiles = (product: any) => {
  return _.get(product, "GeneralDefinitions.Files.File");
};

export const splitParentPath = (
  path: string,
  isArrayAttribute: boolean | undefined
) => {
  const splitted = path.split(".");
  if (splitted.length > 1) {
    let last = splitted.splice(splitted.length - 1);
    if (splitted.length === 2 && splitted[1] === "Variants") {
      //do nothing
    } else { 
      // handle those that have '[]' like   CIE-FluxCode
      if (last[0][last[0].length - 1] === "]") {
        const tmp = last[0].split("['");
        splitted.push(tmp[0]);
        last[0] = tmp[1].substring(0, tmp[1].length - 2);
      }
      //handle Locale
      if (!_.isNil(isArrayAttribute) && isArrayAttribute) {
        let lastMinusOne = splitted.splice(splitted.length - 1);
        last = [`${lastMinusOne[0]}.${last[0]}`];
      }
    }
    return {
      path: splitted.join("."),
      last: last[0],
    };
  }
  return {
    path: "",
    last: path,
  };
};

import * as React from "react";
import { PlainString, NumberArray } from "../..";
import * as _ from "lodash";
import {blockTitleStyle} from "../../../../css/theme";

const Emergency = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  debugElement,
  changeAttribute,
  deleteAttribute
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (elementData) {
    return (
      <>
        <h3 style={blockTitleStyle}>Emergency</h3>
        <div style={{margin: '10px'}}>DurationTimeAndFlux</div>
        <NumberArray 
          mode={mode}
          product={product}
          path={`${path}.Emergency.DurationTimeAndFlux.Flux`}
          elementName="DurationTimeAndFlux"
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          elementSelectorName="hours"
          changeAttribute={changeAttribute}
          deleteAttribute={deleteAttribute}
          elementData={_.get(product, `${path}.Emergency.DurationTimeAndFlux.Flux`)}
        />
        <div style={{margin: '10px'}}>DedicatedEmergencyLightingType</div>
        <PlainString
          mode={mode}
          product={product}
          path={`${path}.Emergency.DedicatedEmergencyLightingType`}
          elementName="DedicatedEmergencyLightingType"
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.Emergency.DedicatedEmergencyLightingType`)}
        />
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default Emergency;

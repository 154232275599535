import { DisplayElementTypes } from "../consts"
const controlGearsConf = {
    blockName: "Control Gear",
    blockClasses: ["controlGearClass-1", "controlGearClass-2"],
    blockList: [
        {
            displayElementName: "ControlGear",
            displayElementType: DisplayElementTypes.ControlGears,
            displayElementClasses: ["controlGearClasssGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.ControlGears.ControlGear"
        },
        
    ]
}

export default controlGearsConf;
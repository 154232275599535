import { createTheme } from "@mui/material";

const modalStyle = {
    width: '60%',
    height: '70%',
    padding: '0',
    top: '50%',
    left: '58%',
    boxShadow: 24,
    color: 'white',
    fontSize: '14px',
    overflowY: 'auto',
    bgcolor: '#081627',
    transform: 'translate(-50%, -50%)',
    position: 'absolute' as 'absolute',
}

const blockTitleStyle = {
    padding: '1rem',
    border: '1px solid #0074ff',
    fontSize: '14px',
    color: 'rgb(255 255 255)',
    borderRadius:'0.5rem',
}

const blockSubTitleStyle = {
    padding: '1rem',
    border: '1px solid #13131300',
    fontSize: '14px',
    color: 'rgb(255 255 255)',
    borderRadius:'0.5rem',
    backgroundColor: '#0d356561'
}

const blockTopGrouping = {
    borderRadius: '10px',
    borderWidth: '1px 1px 1px 8px',
    borderStyle: 'solid',
    borderImage: 'initial',
    borderColor: 'rgb(255,189,0)',
    backgroundColor: 'rgba(47,30,0,0.2)',
    padding: '10px 20px',
    margin: '20px 0px',
}

const blockSubGrouping = {
    borderRadius: '10px',
    borderWidth: '1px 1px 1px 8px',
    borderStyle: 'solid',
    borderImage: 'initial',
    borderColor: 'rgb(222, 165, 0)',
    backgroundColor: 'rgba(90, 54, 0, 0.2)',
    padding: '20px 20px',
    margin: '20px 0px',
}

const blockFileRefGrouping = {
    borderRadius: '10px',
    borderWidth: '1px 1px 1px 8px',
    borderStyle: 'solid',
    borderImage: 'initial',
    borderColor: '#007fff',
    backgroundColor: '#007fff3d',
    padding: '20px 20px',
    margin: '20px 0px',
}

const errorsList = {
    color: '#f00',
    fontWeight: '700',
    borderRadius: '4px',
    border: '1px solid #f00',
    padding: '1rem',
    margin: '15px 10px 10px 0px',
    fontSize: '12px'
}

const validationErrorStyle = {
    maxWidth: '60%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 30%',
    fontSize: '0.8rem',
    border: '0.2rem solid #b1b1b117',
    background: '#7575751',
    marginTop: '10%',
    overflow: 'auto',
    padding: '2rem',
    maxHeight: '50%'
}

let theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            light: "#63ccff",
            main: "#fff",
            dark: "#006db3",
        },
        success: {
            main: '#4caf50',
            light: '#4caf50'
        },
        secondary: {
            main: '#fff',
        },
        info: {
            main: '#fff'
        }
    },
    typography: {
        fontFamily: 'IBM Plex Sans',
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: 14
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#081627",
                    width: 300,
                    border: 'none'
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "#113b6f !important",
                    },
                },
                content: {
                    alignItems: 'center'
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    border: '1.8px solid #ef5350',
                    fontSize: '1rem',
                    fontWeight: '700 !important',
                    color: '#ef5350'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(255,255,255,0.15)",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    background: '#007fff4f',
                    marginBottom: '.5rem',
                    marginTop: '1rem',
                },
                icon: {
                    color: '#fff',
                    marginTop: '0.2rem'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 'none'
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#fff"
                },
                colorDisabled: {
                    color: '#ffffff21'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    paddingTop: '2px',
                    fontWeight: 'bolder',
                    fontSize: '1.075rem'
                },
                icon: {
                    marginLeft: "9px"
                },
                outlined: {
                    minWidth: '150px',
                    margin: '3px 0',
                    border: 'none',
                    fontWeight: 'bolder',
                },
                root: {
                    borderRadius: '0px',
                    justifyContent: 'flex-start',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginLeft: '.5rem',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    fontFamily: 'IBM Plex Sans,sans-serif',
                    fontWeight: 'bold',
                    border: 'transparent',
                    fontSize: '0.875rem',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    color: 'rgb(255, 255, 255)',
                    cursor: 'pointer',
                    backgroundColor: 'rgb(0 116 255)',
                    '&:hover': {
                        border: 'none',
                        color: 'white'
                    }
                },
                contained: {
                    fontFamily: 'IBM Plex Sans,sans-serif',
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                    backgroundColor: '#007FFF',
                    padding: '12px 24px',
                    borderRadius: '8px',
                    color: 'white',
                    cursor: 'pointer',
                    border: 'none',
                },
                outlined: {
                    '&:hover': {
                        border: 'none',
                        color: 'white'
                    }
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    "&.Mui-selected": {
                        fontWeight: "800",
                        backgroundColor: "#113b6f"
                    },
                    padding: "5px !important"
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: '0px !important',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: 'white',
                    marginBottom: '4px'
                },
                input: {
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                    background: '#0000002e',
                    padding: '1rem !important'
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#9a9a9a1f',
                    "&:hover": {
                        backgroundColor: "#ffffff63 !important",
                    },
                },
                input: {
                    cursor: 'pointer',
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    color: 'white',
                },
                root: {
                    marginBottom: '1.2rem'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#fff'
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginRight: 10,
                    color: "inherit",
                    minWidth: "auto",
                    "& svg": {
                        fontSize: 20,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2e7d32',
                    color: 'white',
                    width: '100%',
                    padding: '0px 16px',
                    fontSize: '1rem',
                    lineHeight: '1.7rem',
                    fontWeight: 'bold'
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#fff'
                }
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: '#fff !important',
                    textTransform: "none",
                    margin: "0 16px",
                    minWidth: 0,
                    padding: 0,
                    "&:hover": {
                        color: "#fff !important",
                    },
                },
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

export {
    theme,
    modalStyle,
    errorsList,
    validationErrorStyle,
    blockTitleStyle,
    blockSubTitleStyle,
    blockSubGrouping,
    blockTopGrouping,
    blockFileRefGrouping
};

import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper, Tab, Tabs } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LightIcon from '@mui/icons-material/Light';
import { APIURL } from './consts';
import { copyProduct } from './util';
import axios from 'axios';

interface IUploadedFile {
  size: string;
  filename: string;
  uuid: string;
}

const Home = () => {
  const [value, setValue] = useState(0);
  const [acceptedFiles, setAcceptedFiles] = useState<IUploadedFile[]>([]);
  let navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabPanel = (children: React.ReactNode, value: number, index: number) => {
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
        {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
      </div>
    );
  };

  //ToDo: handle failure
  const StyledDropzone = () => {
    const onDrop = useCallback((acceptedFiles) => {
      var formData = new FormData();
      formData.append('gldf', acceptedFiles[0]);
      axios
        .post(APIURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((resp) => {
          if (typeof resp.data.uuid !== 'undefined') {
            setAcceptedFiles([
              {
                uuid: resp.data.uuid as string,
                filename: acceptedFiles[0].name,
                size: acceptedFiles[0].size,
              },
            ]);
          }
        });
    }, []);

    const baseStyle = {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    };

    const focusedStyle = {
      borderColor: '#2196f3',
    };

    const acceptStyle = {
      borderColor: '#00e676',
    };

    const rejectStyle = {
      borderColor: '#ff1744',
    };
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ onDrop, maxFiles: 1 });
    const acceptedFileItems = acceptedFiles.map(({ filename, uuid, size }) => (
      <Paper
        key={filename}
        sx={{
          boxShadow: 'none',
          backgroundColor: '#dea5002e',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '22px',
          lineHeight: '30px',
          padding: '1rem',
          mb: '1.8rem',
        }}>
        <UploadFileIcon sx={{ fontSize: '2rem', marginRight: '2rem' }} />
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}>
          {' '}
          <strong>[{size}] </strong>bytes - {filename}{' '}
        </div>
        <Button sx={{ height: '27px' }} variant={'contained'}>
          <Link to={`/editor?uuid=${uuid}`}>Open</Link>
        </Button>
      </Paper>
    ));

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div className="container">
        <div>{acceptedFileItems}</div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop GLDF file here, or click to select it</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Grid container spacing={3} alignItems={'center'} justifyContent={'center'} sx={{ height: '100vh' }}>
        <Grid item xs />
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper elevation={0} sx={{ padding: '2rem', height: '300px', marginTop: '7rem' }}>
                <div
                  style={{
                    color: 'black',
                    fontWeight: '700',
                    fontSize: '2rem',
                    paddingBottom: '1rem',
                  }}>
                  GLDF Editor <span style={{ fontWeight: 'normal' }}>Beta</span>
                </div>
                <div style={{ color: '#747474', fontSize: '1.5rem' }}>
                  <div style={{ marginBottom: '1rem' }}>Create your GLDF file with the GLDF Editor.</div>
                  <div style={{ marginBottom: '1rem' }}>Start with a template or simply upload an existing GLDF file to start editing.</div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} sx={{ padding: '1rem', height: '300px' }}>
                <Box sx={{ marginBottom: 2, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab sx={{ width: '50%' }} label="GLDF Templates" value={0} />
                    <Tab sx={{ width: '50%' }} label="Upload GLDF" value={1} />
                  </Tabs>
                </Box>
                {TabPanel(
                  <React.Fragment>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single round 1 variant
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_round_1_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single round 2 variants
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_round_2_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single round 3 variants
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_round_3_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single round 4 variants
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_round_4_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single rectangular 1 variant
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_rectangle_1_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single rectangular 2 variants
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_rectangle_2_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single rectangular 3 variants
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_rectangle_3_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      single rectangular 4 variants
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_single_rectangle_4_variant',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      Tracklum
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_tracklum___',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                    <Paper
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: '#1976d229',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '22px',
                        lineHeight: '53px',
                        padding: '1rem',
                        mb: '1.8rem',
                      }}>
                      <LightIcon sx={{ fontSize: '2rem' }} />
                      Uplighter
                      <Button
                        sx={{ height: '27px' }}
                        variant={'contained'}
                        onClick={() => {
                          copyProduct({
                            fileUUID: '_uplighter___',
                            callBackFunc: (uuid: any) => {
                              navigate(`/editor?uuid=${uuid}`, {
                                replace: true,
                              });
                            },
                          });
                        }}>
                        Open
                      </Button>
                    </Paper>
                  </React.Fragment>,
                  value,
                  0
                )}
                {TabPanel(
                  <React.Fragment>
                    <StyledDropzone />
                  </React.Fragment>,
                  value,
                  1
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs />
      </Grid>
    </>
  );
};

export default Home;

import * as React from 'react';
import './style.scss';
import { Chip, Paper } from '@mui/material';

const VariantSelectedLightSource = ({ selectedLightSource, variantLightEmitters, selectedLightEmitter }: any) => {
  const selectedEmitter = variantLightEmitters[selectedLightEmitter];
  return (
    <>
      <Paper elevation={3} sx={{ minWidth: '800px', padding: '2rem' }}>
        <div>
          <div
            style={{
              display: 'flex',
              width: '400px',
              justifyContent: 'flex-between',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
            }}>
            <div style={{ paddingLeft: '6px', marginBottom: '1rem', color: '#113b6f', fontWeight: '700' }}>Light Source</div>
          </div>
          {selectedLightSource?.Name?.Locale && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Name'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedLightSource.Name.Locale[0]['$']}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedLightSource?.Description?.Locale && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Description'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedLightSource.Description.Locale[0]['$']}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedLightSource?.RatedInputPower && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Rated Input Power'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedLightSource?.RatedInputPower}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedEmitter?.RatedLuminousFlux && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Rated Luminous Flux'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedEmitter?.RatedLuminousFlux}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedLightSource?.ColorInformation?.ColorRenderingIndex && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Color Rendering Index'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedLightSource?.ColorInformation?.ColorRenderingIndex}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedLightSource?.ColorInformation?.CorrelatedColorTemperature && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Correlated Color Temperature'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedLightSource?.ColorInformation?.CorrelatedColorTemperature} K</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedEmitter?.EmergencyBallastLumenFactor && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'[E] Ballast Lumen Factor'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedEmitter?.EmergencyBallastLumenFactor}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedEmitter?.EmergencyRatedLuminousFlux && (
            <div
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'[E] Rated Luminous Flux'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{selectedEmitter?.EmergencyRatedLuminousFlux}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {selectedLightSource.Changeable?.EnergyLabels.EnergyLabel.map((energyLabel: any, idx: number) => (
            <div
              key={idx}
              style={{
                display: 'flex',
                width: '400px',
                justifyContent: 'flex-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}>
              <Chip style={{ minWidth: '200px' }} variant={'outlined'} label={'Energy Label ' + '(' + energyLabel['@region'] + ')'} size="small" />
              <div style={{ marginLeft: '2rem' }}>
                <span>{energyLabel['$']}</span>
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </>
  );
};

export default VariantSelectedLightSource;

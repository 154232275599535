import * as React from 'react';
import classNames from 'classnames';
import { FormControl, Button, MenuItem, Select } from '@mui/material';
import { validateAll } from '../../../util';
import * as _ from 'lodash';

const SelectList = ({
  requiredField,
  readonly,
  product,
  mode,
  path,
  elementName,
  classes,
  availableListItems,
  validationConf,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  preProcessFunction,
  pathToOverride,
  debugElement,
  defaultValue,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  const [localValidationErrors, setLocalValidationErrors] = React.useState<string[]>([]);

  const changeHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!_.isNil(validationConf)) {
      const { isValid, validationErrors } = validateAll(e.target.value, validationConf);
      if (!isValid) {
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrors as string[]);
      } else {
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
    if (typeof preProcessFunction === 'function' && !_.isNil(pathToOverride)) {
      const overridenValue = preProcessFunction(product, pathToOverride, e.target.value);
      changeAttribute(pathToOverride, overridenValue);
    } else {
      changeAttribute(path, e.target.value);
    }
    debugElement && console.log(this);
  };

  React.useEffect(() => {
    if (!_.isNil(validationConf)) {
      const { validationErrors: validationErrorsArray } = validateAll(elementData, validationConf);
      setLocalValidationErrors(validationErrorsArray as string[]);
    }
  }, []);

  if (!_.isNil(elementData)) {
    const plainStringClassNames = classNames('select-list', classes);
    return (
      <>
        {!(mode && mode === 'editMode') ? (
          <span className={plainStringClassNames}>
            <FormControl required={requiredField} fullWidth={true}>
              <Select
                fullWidth={true}
                disabled={true}
                value={elementData}
                onChange={changeHandler}
                error={(requiredField && elementData === '') || (requiredField && !elementData)}
                sx={{ color: 'white', minWidth: '100%' }}>
                {availableListItems.map((currentListElement: string, idx: number) => {
                  return (
                    <MenuItem key={`${idx}-select-list`} value={currentListElement}>
                      {currentListElement}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </span>
        ) : (
          <span className={plainStringClassNames}>
            <FormControl required={requiredField} fullWidth={true}>
              <Select
                fullWidth={true}
                value={elementData}
                disabled={readonly}
                onChange={changeHandler}
                error={(requiredField && elementData === '') || (requiredField && !elementData)}
                sx={{ color: 'white', minWidth: '100%' }}>
                {availableListItems.map((currentListElement: string, idx: number) => {
                  return (
                    <MenuItem key={`${idx}-select-list`} value={currentListElement}>
                      {currentListElement}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </span>
        )}
        {localValidationErrors.length > 0 && <div style={{ color: 'red' }}>{localValidationErrors.join('<br/>')}</div>}
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0' }}
          onClick={() => {
            changeAttribute(path, defaultValue, true);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default SelectList;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { headerConf, variantDescriptiveAttributesConf } from '../conf';
import { MainBox, VariantPanel, GeneralModal, UploadModal} from './';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { theme, modalStyle } from '../css/theme';
import FlexDrawer from './UserInterfaceElements/FlexDrawer';
import {
  changeAttributeCallback,
  deleteAttributeCallback,
  openModal,
  closeModal,
  getVariantName,
  getSelectedVariant,
  getVariantEmitters,  
} from '../util';
import { GLDFBase64File } from "../interfaces";
import * as _ from 'lodash';
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

const GLDFContainer = ({ product, setProduct, debugEnabled, setDebugEnabled, fileUUID }: any) => {
  /* set event handler on boot time */
  useEffect(()=>{
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        setModalOpen(false);
        setUploadModalOpen(false);
      }
    });
  },[]);

  /* GLDFContainer State */

  /* product Files storage state */
  const [GLDFBase64FileArray, setGLDFBase64FileArray] = React.useState<GLDFBase64File[]>([]);
  
  /* UI state management */
  
  /* MODALS */
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    mode: '',
    menuConf: headerConf,
    subMenuConf: '',
  });

  /* VARIANT PANE PANELS */
  const [isVariantPanelOpen, setPaneViewOpen] = useState(false);
  const [variantPaneView, setVariantPaneView] = useState({
    descriptiveAttribute: '' as string,
    content: {} as any,
  });

  /* DRAWER PANEL */
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const toggleDrawer = () => {
    drawerOpen ? setDrawerOpen(false) : setDrawerOpen(true);
  };

  /* Variant / Equipment / Light Emitter */

  const [selectedVariant, setSelectedVariant] = useState(0);

  /* END: GLDFContainer State */

  const toggleVariantPane = (content: any, type: string) => {
    setVariantPaneView({
      descriptiveAttribute: type,
      content: content,
    });

    if (isVariantPanelOpen && variantPaneView.descriptiveAttribute === type) {
      setPaneViewOpen(false);
    } else {
      setPaneViewOpen(true);
    }
  };

  const changeAttribute = (path: any, data: any, isAddElement: boolean | undefined, isArrayAttribute: boolean |undefined) => {
    changeAttributeCallback(product, setProduct, path, data, isAddElement, isArrayAttribute, debugEnabled);
  };

  const deleteAttribute = (path: any, isArray: boolean, index: number) => {
    deleteAttributeCallback(product, path, isArray, index, setProduct );
  };

  const variantName = getVariantName(product, selectedVariant);
  const variant = getSelectedVariant(product, selectedVariant);
  const variantEmitters = getVariantEmitters(variant);
  
  //ToDo: the equipments part needs total overhaul
  const selectedEquipment = null;

  // Error handling
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  // Debug information
  debugEnabled && console.log('RENDER', product);
  console.log('BOOT + SELECTED VARIANT', selectedVariant);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* Menu Drawer */}
          {/* Drawer slider button*/}
          <FlexDrawer
            drawerOpen={drawerOpen}
            debugEnabled={debugEnabled}
            setDebugEnabled={setDebugEnabled}
            setUploadModalOpen={setUploadModalOpen}
            product={product}
            setSelectedVariant={setSelectedVariant}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            setModalView={setModalView}
            openModal={openModal}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            changeAttribute={changeAttribute}
          />
          <div
            onClick={() => toggleDrawer()}
            style={{
              backgroundColor: '#dea500',
              border: 0,
              position: 'fixed',
              top: '50%',
              boxShadow: '0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)',
              width: '23px',
              zIndex: drawerOpen ? '2000' : 'none',
              left: drawerOpen ? '277px' : '0px',
              height: '30px',
              cursor: 'pointer',
              borderRadius:  drawerOpen ? '16px 0px 0px 16px' : '0 16px 16px 0'
            }}>
            { drawerOpen ? ( <ChevronLeft style={{ height: '1.6em', marginLeft: '.4rem'}}/>) : (<ChevronRight style={{ height: '1.6em'}}/>) }
          </div>

          {/* Variant Descriptive Attributes Pane */}

          {isVariantPanelOpen && (
            <VariantPanel
              debugEnabled={debugEnabled}
              product={product}
              selectedVariant={selectedVariant}
              blockConf={variantDescriptiveAttributesConf}
              blockSubConf={variantPaneView.descriptiveAttribute}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              changeAttribute={changeAttribute}
            />
          )}

          <MainBox
            product={product}
            setUploadModalOpen={setUploadModalOpen}
            validationErrors={validationErrors}
            GLDFBase64FileArray={GLDFBase64FileArray}
            fileUUID={fileUUID}
            debugEnabled={debugEnabled}
            setDebugEnabled={setDebugEnabled}
            selectedVariant={selectedVariant}
            variantEmitters={variantEmitters}
            variant={variant}
            variantName={variantName}
            selectedEquipment={selectedEquipment}
            openModal={openModal}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            setModalView={setModalView}
            toggleVariantPane={toggleVariantPane}            
          />

          <GeneralModal 
            product={product}
            fileUUID={fileUUID}
            GLDFBase64FileArray={GLDFBase64FileArray}
            debugEnabled={debugEnabled}
            selectedVariant={selectedVariant}
            variant={variant}
            variantName={variantName}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalStyle={modalStyle}
            modalView={modalView}
            setModalView={setModalView}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            changeAttribute={changeAttribute}
            deleteAttribute={deleteAttribute}
          />

          <UploadModal 
            product={product}
            fileUUID={fileUUID}
            debugEnabled={debugEnabled}
            GLDFBase64FileArray={GLDFBase64FileArray}
            setGLDFBase64FileArray={setGLDFBase64FileArray}
            modalStyle={modalStyle}
            isUploadModalOpen={isUploadModalOpen}
            setUploadModalOpen={setUploadModalOpen}
            closeModal={closeModal}
            changeAttribute={changeAttribute}
          />
          
        </Box>
      </ThemeProvider>
    </>
  );
};

export default GLDFContainer;

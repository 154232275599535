import * as React from 'react';
import * as _ from 'lodash';

import { PlainNumber } from '..';
import { Validators } from '../../../consts';

const ColorInformation = ({
  mode,
  isFloat,
  product,
  path,
  elementName,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  elementData,
  debugElement,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (elementData) {
    return (
      <>
        <div key={`changeable-${elementName}`}>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>Color Rendering Index</div>
            <PlainNumber
              mode={mode}
              product={product}
              path={`${path}.ColorRenderingIndex`}
              key={`${elementName}-ColorRenderingIndex`}
              classes="lightSource-ColorInformation-ColorRenderingIndex-class"
              elementName={`${elementName}-ColorInformation-ColorRenderingIndex`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              validationConf={[
                {
                  validator: Validators.range,
                  minValue: 0,
                  maxValue: 100,
                  isInteger: false,
                },
              ]}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.ColorRenderingIndex`)}
            />
          </>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>Correlated Color Temperature</div>
            <PlainNumber
              mode={mode}
              product={product}
              path={`${path}.CorrelatedColorTemperature`}
              key={`${elementName}-CorrelatedColorTemperature`}
              classes="lightSource-ColorInformation-CorrelatedColorTemperature-class"
              elementName={`${elementName}-ColorInformation-CorrelatedColorTemperature`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.CorrelatedColorTemperature`)}
            />
          </>
          {/* <>
              <div style={{margin: '10px 10px 10px 0'}}>Color Temperature Adjusting Range</div>
              <Range
                mode={mode}
                product={product}
                path={`${path}.ColorTemperatureAdjustingRange`}
                key={`${elementName}-ColorTemperatureAdjustingRange`}
                classes="lightSource-ColorInformation-ColorTemperatureAdjustingRange-class"
                elementName={`${elementName}-ColorInformation-ColorTemperatureAdjustingRange`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(
                  product,
                  `${path}.ColorTemperatureAdjustingRange`
                )}
              />
            </> */}
          {/* <>
            <div style={{ margin: '10px 10px 10px 0' }}>Cie1931 Color Appearance</div>
            <XYZElement
              mode={mode}
              isFloat={true}
              product={product}
              path={`${path}.Cie1931ColorAppearance`}
              key={`${elementName}-Cie1931ColorAppearance`}
              classes="lightSource-ColorInformation-Cie1931ColorAppearance-class"
              elementName={`${elementName}-ColorInformation-Cie1931ColorAppearance`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.Cie1931ColorAppearance`)}
            />
          </>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>Initial Color Tolerance</div>
            <PlainString
              mode={mode}
              product={product}
              path={`${path}.InitialColorTolerance`}
              key={`${elementName}-InitialColorTolerance`}
              classes="lightSource-ColorInformation-InitialColorTolerance-class"
              elementName={`${elementName}-ColorInformation-InitialColorTolerance`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.InitialColorTolerance`)}
            />
          </>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>Maintained Color Tolerance</div>
            <PlainString
              mode={mode}
              product={product}
              path={`${path}.MaintainedColorTolerance`}
              key={`${elementName}-MaintainedColorTolerance`}
              classes="lightSource-ColorInformation-MaintainedColorTolerance-class"
              elementName={`${elementName}-ColorInformation-MaintainedColorTolerance`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.MaintainedColorTolerance`)}
            />
            <br />
          </>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>Rated Chromacity Coordinate Values</div>
            <XYZElement
              mode={mode}
              product={product}
              path={`${path}.RatedChromacityCoordinateValues`}
              key={`${elementName}-RatedChromacityCoordinateValues`}
              classes="lightSource-ColorInformation-RatedChromacityCoordinateValues-class"
              elementName={`${elementName}-ColorInformation-RatedChromacityCoordinateValues`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.RatedChromacityCoordinateValues`)}
            />
          </>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>IES-TM-30-15</div>
            <div style={blockSubGrouping}>
              <div style={{ margin: '10px 10px 10px 0' }}>Rf</div>
              <PlainNumber
                mode={mode}
                product={product}
                path={`${path}['IES-TM-30-15'].Rf`}
                key={`${elementName}-IES-TM-30-15.Rf`}
                classes="lightSource-ColorInformation-IES-TM-30-15-Rf-class"
                elementName={`${elementName}-ColorInformation-IES-TM-30-15-Rf`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(product, `${path}['IES-TM-30-15'].Rf`)}
              />
              <div style={{ margin: '10px 10px 10px 0' }}>Rg</div>
              <PlainNumber
                mode={mode}
                product={product}
                path={`${path}['IES-TM-30-15'].Rg`}
                key={`${elementName}-IES-TM-30-15.Rg`}
                classes="lightSource-ColorInformation-IES-TM-30-15-Rg-class"
                elementName={`${elementName}-ColorInformation-IES-TM-30-15-Rg`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(product, `${path}['IES-TM-30-15'].Rg`)}
              />
            </div>
          </> */}
          {/* <>
            <div style={{ margin: '10px 10px 10px 0' }}>TLCI</div>
            <PlainNumber
              mode={mode}
              product={product}
              path={`${path}.TLCI`}
              key={`${elementName}-TLCI`}
              classes="lightSource-ColorInformation-TLCI-class"
              elementName={`${elementName}-ColorInformation-TLCI`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.TLCI`)}
            />
          </>
          <>
            <div style={{ margin: '10px 10px 10px 0' }}>MelanopicFactor</div>
            <PlainNumber
              mode={mode}
              product={product}
              path={`${path}.MelanopicFactor`}
              key={`${elementName}-MelanopicFactor`}
              classes="lightSource-ColorInformation-MelanopicFactor-class"
              elementName={`${elementName}-ColorInformation-MelanopicFactor`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.MelanopicFactor`)}
            />
          </> */}
        </div>
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default ColorInformation;

import * as React from "react";
import * as _ from "lodash";
import { LocaleString, PlainString } from "..";
import {blockSubGrouping} from "../../../css/theme";

const EmittersSensor = ({
  mode,
  path,
  product,
  elementName,
  elementData,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (elementData) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div style={blockSubGrouping} key={`emitters-sensor-${element["@id"]}`}>
                <div style={{margin: '10px'}}>Display Name</div>
                <LocaleString
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}].DisplayName.Locale`}
                  key={`${elementName}-${idx}-locale`}
                  classes="sensor-${idx}-DisplayName-class"
                  elementName={`${elementName}-DisplayName`}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].DisplayName.Locale`)}
                />
                <div style={{margin: '10px'}}>Sensor ID</div>
                <PlainString
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}]['@sensorId']`}
                  key={`${elementName}-${idx}-sensorid`}
                  classes="sensor-${idx}-sensorID-class"
                  elementName={`${elementName}-${idx}-sensorid`}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}]['@sensorId']`)}
                />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default EmittersSensor;

import * as _ from 'lodash';
import {
  changeAttributeCallback,
  deleteAttributeCallback,
  getFileIds,
  getLightSourceIds,
  getControlGearIds,
  getPhotometryFileIds,
  getGeometryIds,
  getGeometryFileIds,
  getPhotometryIds,
  getEquipmentIds,
  getPictureFileIds,
  getEmitterIds,
} from './data';

import { submitProduct, copyProduct } from './network';

import { getVariantName, getSelectedVariant, getFiles, splitParentPath } from './helper';

import getOrder from './ordering';

import { openModal, closeModal } from './handleModals';
import { validateAll } from './validators';

const returnVariantMountingType = (variant: any): string[] => {
  let out: string[] = [];

  if (typeof variant.Mountings !== 'undefined') {
    //big if
    if (typeof variant.Mountings?.Ground?.PoleTop !== 'undefined') {
      out.push('Ground / Pole top');
    }
    if (typeof variant.Mountings?.Ground?.PoleIntegrated !== 'undefined') {
      out.push('Ground / Pole integrated');
    }
    if (typeof variant.Mountings?.Ground?.FreeStanding !== 'undefined') {
      out.push('Ground / Free standing');
    }
    if (typeof variant.Mountings?.Ground?.SurfaceMounted !== 'undefined') {
      out.push('Ground / Surface mounted');
    }
    if (typeof variant.Mountings?.Ground?.Recessed !== 'undefined') {
      out.push('Ground / Recessed');
    }
    if (typeof variant.Mountings?.Ceiling?.SurfaceMounted !== 'undefined') {
      out.push('Ceiling / Surface mounted');
    }
    if (typeof variant.Mountings?.Ceiling?.Pendant !== 'undefined') {
      out.push('Ceiling / Pendant');
    }
    if (typeof variant.Mountings?.Ceiling?.Recessed !== 'undefined') {
      out.push('Ceiling / Recessed');
    }
    if (typeof variant.Mountings?.Wall?.Recessed !== 'undefined') {
      out.push('Wall / Recessed');
    }
    if (typeof variant.Mountings?.Wall?.SurfaceMounted !== 'undefined') {
      out.push('Wall / Surface mounted');
    }
    if (typeof variant.Mountings?.WorkingPlane?.FreeStanding !== 'undefined') {
      out.push('Working Plane / Free standing');
    }


    return out;
  }
  return [];
};

const emergencyBehaviorList = ['None', 'Combined', 'EmergencyOnly'];

const getVariantEmitterId = (variant: any, selectedEmitter: number) => {
  if (_.get(variant, `Geometry.EmitterReference['@emitterId']`)) {
    return _.get(variant, `Geometry.EmitterReference['@emitterId']`);
  }
  if (_.get(variant, `Geometry.SimpleGeometryReference['@emitterId']`)) {
    return _.get(variant, `Geometry.SimpleGeometryReference['@emitterId']`);
  }
  if (_.get(variant, `Geometry.ModelGeometryReference.EmitterReference`)) {
    return _.get(variant, `Geometry.ModelGeometryReference.EmitterReference[${selectedEmitter}]['@emitterId']`);
  }

  //Todo: add GeometryReferences
  return false;
};

const getVariantEmitters = (variant: any) => {
  let emitters: any[] = [];
  let modelGeometryReferenceEmitterReferenceArray = _.get(variant, 'Geometry.ModelGeometryReference.EmitterReference', []);
  let emitterReferenceArray = _.get(variant, 'Geometry.EmitterReference', []);
  emitters = _.concat(emitters, modelGeometryReferenceEmitterReferenceArray);
  emitters = _.concat(emitters, emitterReferenceArray);
  return emitters;
};
const getVariantEmitterLightEmittersOrSensors = (currentEmitter: any, product: any) => {
  const emitterID = _.get(currentEmitter, '@emitterId');
  for (let i = 0; i < product.GeneralDefinitions.Emitters.Emitter.length; i++) {
    if (product.GeneralDefinitions.Emitters.Emitter[i]['@id'] === emitterID) {
      if (!_.isNil(product.GeneralDefinitions.Emitters.Emitter[i].FixedLightEmitter)) {
        return product.GeneralDefinitions.Emitters.Emitter[i].FixedLightEmitter;
      } else if (!_.isNil(product.GeneralDefinitions.Emitters.Emitter[i].ChangeableLightEmitter)) {
        return product.GeneralDefinitions.Emitters.Emitter[i].ChangeableLightEmitter;
      } else if (!_.isNil(product.GeneralDefinitions.Emitters.Emitter[i].Sensor)) {
        return product.GeneralDefinitions.Emitters.Emitter[i].Sensor;
      } else {
        return null;
      }
    }
  }
};

const getEquipment = (product: any, equipmentId: any) => {
  if (_.isNil(equipmentId)) return null;
  for (let i = 0; i < product.GeneralDefinitions.Equipments.Equipment.length; i++) {
    if (product.GeneralDefinitions.Equipments.Equipment[i]['@id'] === equipmentId) {
      return product.GeneralDefinitions.Equipments.Equipment[i];
    }
  }
};

const getLightSource = (product: any, lightSourceId: any) => {
  for (let i = 0; i < product.GeneralDefinitions.LightSources.FixedLightSource.length; i++) {
    if (product.GeneralDefinitions.LightSources.FixedLightSource[i]['@id'] === lightSourceId) {
      return product.GeneralDefinitions.LightSources.FixedLightSource[i];
    }
  }
  for (let i = 0; i < product.GeneralDefinitions.LightSources.ChangeableLightSource.length; i++) {
    if (product.GeneralDefinitions.LightSources.ChangeableLightSource[i]['@id'] === lightSourceId) {
      return product.GeneralDefinitions.LightSources.ChangeableLightSource[i];
    }
  }
};

const getLightSourceIdFromFixedLightEmitter = (currentEmitter: any) => {
  return _.get(currentEmitter, `LightSourceReference['@fixedLightSourceId']`, null);
};

const getLightSourceIdFromChangeableLightEmitter = (product: any, equipmentId: any) => {
  let lightSourceId = '';
  if (product.GeneralDefinitions?.Equipments?.Equipment) {
    product.GeneralDefinitions.Equipments.Equipment.forEach((equipment: any, idx: number) => {
      if (equipment['@id'] === equipmentId) {
        lightSourceId = equipment.LightSourceReference['@lightSourceId'];
      } else {
      }
    });
  }
  return lightSourceId;
};

const amountEmergencyEmitters = (emitters: any) => {
  let count = 0;
  for (let i = 0; i < emitters.length; i++) {
    if (Array.isArray(emitters[i])) {
      for (let j = 0; j < emitters[i].length; j++) {
        if (typeof emitters[i][j].emergencyBehaviour === 'undefined' || emitters[i][j].emergencyBehaviour === 'None') {
        } else if (emitters[i][j].emergencyBehaviour === 'Combined' || emitters[i][j].emergencyBehaviour === 'EmergencyOnly') {
          count++;
        }
      }
    } else {
      if (typeof emitters[i].emergencyBehaviour === 'undefined' || emitters[i].emergencyBehaviour === 'None') {
      } else if (emitters[i].emergencyBehaviour === 'Combined' || emitters[i].emergencyBehaviour === 'EmergencyOnly') {
        count++;
      }
    }
  }
  return count;
};

const amountSensorEmitters = (emitters: any) => {
  let count = 0;
  for (let i = 0; i < emitters.length; i++) {
    if (Array.isArray(emitters[i])) {
      for (let j = 0; j < emitters[i].length; j++) {
        if (typeof emitters[i][j].sensor !== 'undefined') {
          count++;
        } else {
          /* swallow */
        }
      }
    } else {
      if (typeof emitters[i].sensor !== 'undefined') {
        count++;
      } else {
        /* swallow */
      }
    }
  }
  return count;
};

const getLocale = (localeData: any, language: string) => {
  if (typeof localeData === 'undefined' || localeData === null) {
    return 'N/A';
  }
  for (let i = 0; i < localeData.length; i++) {
    if (localeData[i]['@language'] == language) {
      return localeData[i]['$'];
    }
  }
  return 'N/A';
};

const getLocaleIndex = (localeData: any, language: string) => {
  if (typeof localeData === 'undefined' || localeData === null) {
    return -1;
  }
  for (let i = 0; i < localeData.length; i++) {
    if (localeData[i]['@language'] == language) {
      return i;
    }
  }
  return -1;
};

const getPhotometryIndex = (product: any, photometryId: any) => {
  let outNumber = -1;
  if (product.GeneralDefinitions?.Photometries?.Photometry) {
    product.GeneralDefinitions.Photometries.Photometry.forEach((photometry: any, idx: number) => {
      if (photometry['@id'] === photometryId) {
        outNumber = idx;
      }
    });
  }
  return outNumber;
};

const getSensorIndex = (product: any, sensorId: any) => {
  let outNumber = -1;
  if (product.GeneralDefinitions?.Sensors?.Sensor) {
    product.GeneralDefinitions.Sensors.Sensor.forEach((sensor: any, idx: number) => {
      if (sensor['@id'] === sensorId) {
        outNumber = idx;
      }
    });
  }
  return outNumber;
};

//ToDo: need to support all kinds of geometries
const getGeometryIndex = (product: any, geometryId: any) => {
  let outNumber = -1;
  if (product.GeneralDefinitions?.Geometries?.ModelGeometry) {
    product.GeneralDefinitions.Geometries.ModelGeometry.forEach((geometry: any, idx: number) => {
      if (geometry['@id'] === geometryId) {
        outNumber = idx;
      }
    });
  }
  return outNumber;
};

const getEquipmentIndex = (product: any, equipmentId: any) => {
  let outNumber = -1;
  if (product.GeneralDefinitions?.Equipments?.Equipment) {
    product.GeneralDefinitions.Equipments.Equipment.forEach((equipment: any, idx: number) => {
      if (equipment['@id'] === equipmentId) {
        outNumber = idx;
      }
    });
  }
  return outNumber;
};

const getLightSourceIndex = (product: any, lightSourceId: any) => {
  let outNumber = -1;
  if (product.GeneralDefinitions?.LightSources?.LightSource) {
    product.GeneralDefinitions.LightSources.LightSource.forEach((lightSource: any, idx: number) => {
      if (lightSource['@id'] === lightSourceId) {
        outNumber = idx;
      }
    });
  }
  return outNumber;
};

const getLightSourceReferenceId = (product: any, equipmentId: any) => {
  const equId = getEquipmentIndex(product, equipmentId);
  // console.log(equId);
  // console.log(product?.GeneralDefinitions?.Equipments.Equipment[equId]?.LightSourceReference['@lightSourceId']);
  return product?.GeneralDefinitions?.Equipments?.Equipment[equId]?.LightSourceReference['@lightSourceId'];
};

const getFile = (product: any, fileId: any) => {
  let outFile = null;
  if (product.GeneralDefinitions?.Files?.File) {
    product.GeneralDefinitions.Files.File.forEach((file: any) => {
      if (file['@id'] === fileId) {
        outFile = file;
      }
    });
  }
  return outFile;
};

const getPhotometryFileReference = (product: any, photometryIndex: number) => {
  return _.get(product, `GeneralDefinitions.Photometries.Photometry[${photometryIndex}].PhotometryFileReference['@fileId']`);
};

const getSensorFileReference = (product: any, sensorIndex: number) => {
  return _.get(product, `GeneralDefinitions.Sensors.Sensor[${sensorIndex}].SensorFileReference['@fileId']`);
};

//ToDo: need to support all kinds of geometries
const getGeometryFileReference = (product: any, geometryIndex: number) => {
  return _.get(product, `GeneralDefinitions.Geometries.ModelGeometry[${geometryIndex}].GeometryFileReference[0]['@fileId']`);
};

//ToDo: add selected Emitter
//ToDo: make it more resilient
const getActiveLightDistribution = (product: any, photometryId: string) => {
  const photometryIndex = getPhotometryIndex(product, photometryId);
  const photometryFileId = getPhotometryFileReference(product, photometryIndex);
  const file = getFile(product, photometryFileId);
  return _.get(file, '$');
};

const getActiveSensorDistribution = (product: any, sensorId: string) => {
  const sensorIndex = getSensorIndex(product, sensorId);
  const sensorFileId = getSensorFileReference(product, sensorIndex);
  const file = getFile(product, sensorFileId);
  return _.get(file, '$');
};

getActiveSensorDistribution;

//ToDo: need to support all kinds of geometries + multiple geometries
const getActiveGeometryReference = (product: any, variant: any) => {
  const geometryId = _.get(variant, `Geometry.ModelGeometryReference['@geometryId']`);
  const geometryIndex = getGeometryIndex(product, geometryId);
  const geometryFileId = getGeometryFileReference(product, geometryIndex);
  const file = getFile(product, geometryFileId);
  return _.get(file, '$');
};

const returnVariantDescriptiveAttributesPath = (selectedVariant: number) => {
  return `ProductDefinitions.Variants.Variant[${selectedVariant}].DescriptiveAttributes`;
};

const returnVariantPath = (selectedVariant: number) => {
  return `ProductDefinitions.Variants.Variant[${selectedVariant}]`;
};

export {
  returnVariantMountingType,
  getLocale,
  getLocaleIndex,
  getPhotometryIndex,
  getGeometryIndex,
  getActiveLightDistribution,
  getActiveSensorDistribution,
  getActiveGeometryReference,
  getGeometryFileReference,
  getEquipmentIndex,
  getEquipment,
  getLightSourceIndex,
  getLightSourceIdFromChangeableLightEmitter,
  getLightSourceIdFromFixedLightEmitter,
  getLightSource,
  getLightSourceReferenceId,
  amountEmergencyEmitters,
  amountSensorEmitters,
  changeAttributeCallback,
  deleteAttributeCallback,
  getVariantEmitterId,
  getVariantEmitters,
  getVariantEmitterLightEmittersOrSensors,
  emergencyBehaviorList,
  returnVariantDescriptiveAttributesPath,
  returnVariantPath,
  openModal,
  closeModal,
  validateAll,
  getFileIds,
  getLightSourceIds,
  getControlGearIds,
  getPhotometryFileIds,
  getGeometryFileIds,
  getEquipmentIds,
  getGeometryIds,
  getEmitterIds,
  getPhotometryIds,
  getPictureFileIds,
  getVariantName,
  getSelectedVariant,
  getFile,
  getFiles,
  splitParentPath,
  getOrder,
  submitProduct,
  copyProduct,
};

import * as React from 'react';
import { PlainString, SelectList } from '..';
import { blockSubGrouping } from '../../../css/theme';
import { Validators } from '../../../consts';
import { getGeometryFileIds } from '../../../util';
import * as _ from 'lodash';

const Geometries = ({ mode, product, path, elementName, elementData, validationErrors, setValidationErrors, changeAttribute, debugElement }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  //ToDo: move up
  const setValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    if (tmpValidationErrors.indexOf(elementPath) < 0) {
      tmpValidationErrors.push(elementPath);
    }
    setValidationErrors(tmpValidationErrors);
  };

  const unSetValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    const index = tmpValidationErrors.indexOf(elementPath);
    if (index > -1) {
      _.pullAt(tmpValidationErrors, index);
      setValidationErrors(tmpValidationErrors);
    }
  };

  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div key={`${elementName}-main-${idx}`} style={blockSubGrouping}>
              <div style={{ margin: '10px' }}>ID</div>
              <PlainString
                mode={mode}
                product={product}
                path={`${path}[${idx}]['@id']`}
                key={`${elementName}-${idx}-ID`}
                classes="geometry-id-class"
                elementName={`${elementName}-${idx}-id`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonEmptyString,
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={element['@id']}
              />
              {element.GeometryFileReference && (
                <div style={blockSubGrouping}>
                  <div style={{ margin: '10px' }}>Geometry File Reference</div>
                  {element.GeometryFileReference.map((elmt: any, idx2: number) => {
                    return (
                      <>
                        <SelectList
                          mode={mode}
                          availableListItems={getGeometryFileIds(product)}
                          product={product}
                          path={`${path}[${idx}].GeometryFileReference[${idx2}]['@fileId']`}
                          referencePrefix="file"
                          key={`${elementName}-PhotometryFileReference`}
                          classes="geometry-GeometryFileReference-class"
                          elementName={`${elementName}-Equipment-GeometryFileReference`}
                          debugElement={debugElement}
                          changeAttribute={changeAttribute}
                          elementData={_.get(product, `${path}[${idx}].GeometryFileReference[${idx2}]['@fileId']`)}
                        />
                        <SelectList
                          mode={mode}
                          availableListItems={['Low', 'Medium', 'High']}
                          product={product}
                          path={`${path}[${idx}].GeometryFileReference[${idx2}]['@levelOfDetail']`}
                          referencePrefix="file"
                          key={`${elementName}-PhotometryFileReference-LevelOfDetail`}
                          classes="geometry-GeometryFileReference-LevelOfDetail-class"
                          elementName={`${elementName}-Equipment-GeometryFileReference-LevelOfDetail`}
                          debugElement={debugElement}
                          changeAttribute={changeAttribute}
                          defaultValue="Medium"
                          elementData={_.get(product, `${path}[${idx}].GeometryFileReference[${idx2}]['@levelOfDetail']`)}
                        />
                      </>
                    );
                  })}
                </div>
              )}
              <hr />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Geometries;

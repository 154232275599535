import * as React from 'react';
import { APIURL } from '../../../consts';
import { getFile } from '../../../util';
import * as _ from 'lodash';
import { isNil } from 'lodash';

interface IMainImage {
  fileId?: string;
  wideDisplay?: Boolean;
  product: any;
  fileUUID: string;
  GLDFBase64FileArray: any;
}

const ImageOrBase64 = ({ fileId, product, fileUUID, GLDFBase64FileArray, wideDisplay }: IMainImage) => {
  let localFileId = null;
  let src = null;
  if (isNil(fileId)) {
    localFileId = _.get(product, `ProductDefinitions.ProductMetaData.Pictures.Image[0]['@fileId']`);
  } else {
    localFileId = fileId;
  }
  const GLDFBase64FileArrayIds = GLDFBase64FileArray.map((i: any) => i.id);
  const idx = GLDFBase64FileArrayIds.indexOf(localFileId);

  if (idx > -1) {
    src = GLDFBase64FileArray[idx]['fileData'];
  } else {
    const fileName = _.get(getFile(product, localFileId), '$');
    src = `${APIURL}${fileUUID}/gldf/image/${fileName}`;
  }
  return <img id="productPicture" style={{  maxWidth: wideDisplay ? '300px' : '300px' }} src={src} alt={'productImage'} />;
};

export default ImageOrBase64;

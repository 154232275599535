import * as React from 'react';
import { NumberArray, StringArray, LocaleArray, HyperLink, PlainString } from '../..';
import * as _ from 'lodash';
import { blockTitleStyle } from '../../../../css/theme';
import { Validators } from '../../../../consts';

const Marketing = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  deleteAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  return (
    <>
      <h3 style={blockTitleStyle}>Marketing</h3>
      <div style={{ margin: '10px' }}>ListPrices</div>
      <NumberArray
        mode={mode}
        product={product}
        isFloat={true}
        path={`${path}.Marketing.ListPrices.ListPrice`}
        elementName="ListPrices"
        setValidationErrorsHandler={setValidationErrorsHandler}
        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
        elementSelectorName="currency"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        deleteAttribute={deleteAttribute}
        elementData={_.get(product, `${path}.Marketing.ListPrices.ListPrice`)}
      />
      <div style={{ margin: '10px' }}>HousingColors</div>
      <LocaleArray
        mode={mode}
        product={product}
        path={`${path}.Marketing.HousingColors.HousingColor`}
        elementName="HousingColors"
        elementSelectorName="ral"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Marketing.HousingColors.HousingColor`)}
      />
      <div style={{ margin: '10px' }}>Markets</div>
      <LocaleArray
        mode={mode}
        product={product}
        path={`${path}.Marketing.Markets.Region`}
        elementName="Markets"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Marketing.Markets.Region`)}
      />
      <div style={{ margin: '10px' }}>HyperLinks</div>
      <HyperLink
        mode={mode}
        product={product}
        path={`${path}.Marketing.Hyperlinks.Hyperlink`}
        elementName="Hyperlinks"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Marketing.Hyperlinks.Hyperlink`)}
      />
      <div style={{ margin: '10px' }}>Designer</div>
      <PlainString
        mode={mode}
        product={product}
        path={`${path}.Marketing.Designer`}
        elementName="Designer"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Marketing.Designer`)}
      />
      <div style={{ margin: '10px' }}>ApprovalMarks</div>
      <StringArray
        mode={mode}
        product={product}
        path={`${path}.Marketing.ApprovalMarks.ApprovalMark`}
        elementName="ApprovalMarks"
        validationErrors={validationErrors}
        setValidationErrorsHandler={setValidationErrorsHandler}
        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
        validationConf={[
          {
            validator: Validators.nonEmptyString,
          },
        ]}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        deleteAttribute={deleteAttribute}
        elementData={_.get(product, `${path}.Marketing.ApprovalMarks.ApprovalMark`)}
      />
      <div style={{ margin: '10px' }}>DesignAwards</div>
      <StringArray
        mode={mode}
        product={product}
        path={`${path}.Marketing.DesignAwards.DesignAward`}
        elementName="DesignAwards"
        validationErrors={validationErrors}
        setValidationErrorsHandler={setValidationErrorsHandler}
        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
        validationConf={[
          {
            validator: Validators.nonEmptyString,
          },
        ]}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        deleteAttribute={deleteAttribute}
        elementData={_.get(product, `${path}.Marketing.DesignAwards.DesignAward`)}
      />
      <div style={{ margin: '10px' }}>Applications</div>
      <StringArray
        mode={mode}
        requiredField
        isSelectList={true}
        availableListItems={[
          'Interior: Traffic Zones',
          'Interior: Traffic Zones: Corridors',
          'Interior: Traffic Zones: Staircases',
          'Interior: Traffic Zones: Loading Zones',
          'Interior: Traffic Zones: Cove Lighting / Cornices (Indoor)',
          'Interior: General Areas (Interior)',
          'Interior: General Areas (Interior): Break Rooms',
          'Interior: General Areas (Interior): Reception Areas',
          'Interior: Office',
          'Interior: Office: Office Desks',
          'Interior: Office: Group Offices',
          'Interior: Office: Discussions',
          'Interior: Office: Archives',
          'Interior: Industry/Craft',
          'Interior: Industry/Craft: Industrial Workshops',
          'Interior: Industry/Craft: Warehouses',
          'Interior: Industry/Craft: Cold Storage Facilities',
          'Interior: Industry/Craft: Kitchens',
          'Interior: Industry/Craft: Assembly Work Stations',
          'Interior: Industry/Craft: Machine Illumination',
          'Interior: Industry/Craft: Control Work Stations',
          'Interior: Industry/Craft: Laboratories',
          'Interior: Industry/Craft: Hangars',
          'Interior: Shop Lighting',
          'Interior: Shop Lighting: Retail',
          'Interior: Shop Lighting: Food',
          'Interior: Shop Lighting: Clothing',
          'Interior: Shop Lighting: Display Windows',
          'Interior: Shop Lighting: Halls',
          'Interior: Shop Lighting: Great Halls',
          'Interior: Shop Lighting: Mirrors',
          'Interior: Public Areas',
          'Interior: Public Areas: Restaurants',
          'Interior: Public Areas: Theatres',
          'Interior: Public Areas: Railway Stations',
          'Interior: Public Areas: Museums',
          'Interior: Public Areas: Fairs',
          'Interior: Public Areas: Prisons',
          'Interior: Public Areas: Canteens',
          'Interior: Emergency Lighting',
          'Interior: Emergency Lighting: Emergency Lighting',
          'Interior: Emergency Lighting: Signal Lighting',
          'Interior: Educational Facilities',
          'Interior: Educational Facilities: Classrooms',
          'Interior: Educational Facilities: Libraries',
          'Interior: Educational Facilities: Lounges',
          'Interior: Educational Facilities: Sports Halls',
          'Interior: Private Areas',
          'Interior: Private Areas: Living Areas',
          'Interior: Private Areas: Baths',
          'Interior: Private Areas: Kitchens',
          'Interior: Hospitals and Care Places',
          'Interior: Hospitals and Care Places: Hospital Wards',
          'Interior: Hospitals and Care Places: Health Care Patient Rooms',
          'Interior: Hospitals and Care Places: Health Care Clean Room Areas',
          'Interior: Hospitals and Care Places: Health Care Examination Rooms',
          'Interior: Hospitals and Care Places: Health Care Circulation Areas',
          'Exterior: General Areas (Exterior)',
          'Exterior: General Areas (Exterior): Places',
          'Exterior: General Areas (Exterior): Parks',
          'Exterior: General Areas (Exterior): Underpasses',
          'Exterior: General Areas (Exterior): (Outdoor) Stairs',
          'Exterior: General Areas (Exterior): Platform-Roofs',
          'Exterior: General Areas (Exterior): Parking Spaces (Indoor)',
          'Exterior: General Areas (Exterior): Outdoor Parkings',
          'Exterior: General Areas (Exterior): Pools',
          'Exterior: General Areas (Exterior): Fountains',
          'Exterior: Streets',
          'Exterior: Streets: Motorways',
          'Exterior: Streets: Access Roads',
          'Exterior: Streets: Residential Areas',
          'Exterior: Streets: Bicycle Paths',
          'Exterior: Streets: Footpaths',
          'Exterior: Streets: Petrol-Gas Stations',
          'Exterior: Streets: Tunnels',
          'Exterior: Sports Fields',
          'Exterior: Sports Fields: Spotlightings',
          'Exterior: Other',
          'Exterior: Other: Facades',
        ]}
        product={product}
        path={`${path}.Marketing.Applications.Application`}
        elementName="Applications"
        validationErrors={validationErrors}
        setValidationErrorsHandler={setValidationErrorsHandler}
        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
        validationConf={[
          {
            validator: Validators.nonEmptyString,
          },
        ]}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        deleteAttribute={deleteAttribute}
        elementData={_.get(product, `${path}.Marketing.Applications.Application`)}
      />
    </>
  );
};
//return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;

export default Marketing;

import * as React from "react";
import { PlainString, MaintenanceFactor } from "..";
import * as _ from "lodash";
import { isNil } from "lodash";
import {Validators} from "../../../consts";

const ProductMaintenance = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  changeAttribute,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  if (isNil(elementData)) return null;

  const {
    Cie97LuminaireType,
    CieLuminaireMaintenanceFactors,
    IesLuminaireLightLossFactors,
    JiegMaintenanceFactors,
  } = elementData;

  return (
    <>
      {Cie97LuminaireType && (
        <div>
          <div style={{margin: '10px'}}>Cie97 Luminaire Type</div>
          <PlainString
            mode={mode}
            product={product}
            path={`${path}.Cie97LuminaireType`}
            elementName={"Cie97LuminaireType"}
            changeAttribute={changeAttribute}
            elementData={_.get(product, `${path}.Cie97LuminaireType`)}
          />
        </div>
      )}
      {Array.isArray(
        CieLuminaireMaintenanceFactors?.LuminaireMaintenanceFactor
      ) && (
        <div>
          <div style={{margin: '10px'}}>Cie Luminaire Maintenance Factors</div>
          <MaintenanceFactor
            mode={mode}
            product={product}
            path={`${path}.CieLuminaireMaintenanceFactors.LuminaireMaintenanceFactor`}
            elementName={
              "CieLuminaireMaintenanceFactors.LuminaireMaintenanceFactor"
            }
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            changeAttribute={changeAttribute}
            roomCondition={["Very Clean", "Clean", "Normal", "Dirty"]}
            elementData={_.get(
              product,
              `${path}.CieLuminaireMaintenanceFactors.LuminaireMaintenanceFactor`
            )}
          />
        </div>
      )}
      {Array.isArray(
        IesLuminaireLightLossFactors?.LuminaireDirtDepreciation
      ) && (
        <div>
          <div style={{margin: '10px'}}>Ies Luminaire Light Loss Factors</div>
          <MaintenanceFactor
            mode={mode}
            product={product}
            path={`${path}.IesLuminaireLightLossFactors.LuminaireDirtDepreciation`}
            elementName={
              "IesLuminaireLightLossFactors.LuminaireDirtDepreciation"
            }
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            changeAttribute={changeAttribute}
            roomCondition={["Very Clean", "Clean", "Moderate", "Dirty", "Very Dirty"]}
            elementData={_.get(
              product,
              `${path}.IesLuminaireLightLossFactors.LuminaireDirtDepreciation`
            )}
          />
        </div>
      )}
      {Array.isArray(JiegMaintenanceFactors?.LuminaireMaintenanceFactor) && (
        <div>
          <div style={{margin: '10px'}}>Jieg Maintenance Factors</div>
          <MaintenanceFactor
            mode={mode}
            product={product}
            path={`${path}.JiegMaintenanceFactors.LuminaireMaintenanceFactor`}
            elementName={"JiegMaintenanceFactors.LuminaireMaintenanceFactor"}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            changeAttribute={changeAttribute}
            roomCondition={["Clean", "Normal", "Dirty"]}
            elementData={_.get(
              product,
              `${path}.JiegMaintenanceFactors.LuminaireMaintenanceFactor`
            )}
          />
        </div>
      )}
    </>
  );
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default ProductMaintenance;

import * as React from 'react';
import classNames from 'classnames';
import { TextField, Button } from '@mui/material';
import {blockSubGrouping, errorsList} from '../../../css/theme';

import './style.scss';
import * as _ from 'lodash';
import { validateAll } from '../../../util';

const LocaleString = ({
  mode,
  path,
  elementName,
  classes,
  elementData,
  debugElement,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  validationConf,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  const elementClassName = classNames('locale-element', classes);

  let initialValidationState = false;
  if (Array.isArray(validationErrors) && validationErrors.indexOf(path) > -1) initialValidationState = true;

  const [validation, setValidation] = React.useState(initialValidationState);
  const [localValidationErrors, setLocalValidationErrors] = React.useState<string[]>([]);

  const changeHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!_.isNil(validationConf)) {
      const { isValid, validationErrors } = validateAll(e.target.value, validationConf);
      if (!isValid) {
        setValidation(true);
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrors as string[]);
      } else {
        setValidation(false);
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
    debugElement && console.log(this);
  };

  React.useEffect(() => {
    if (!_.isNil(elementData)) {
      if (!_.isNil(validationConf)) {
        const { validationErrors: validationErrorsArray } = validateAll(elementData[0]['$'], validationConf);
        setLocalValidationErrors(validationErrorsArray as string[]);
      }
    }
  }, []);

  if (Array.isArray(elementData) && mode === 'displayMode') {
    return (
      <>
        {elementData.map((e: any, idx: number) => (
          <div className={elementClassName} key={`${elementName}${idx}`}>
            {localValidationErrors.length > 0 && <div style={errorsList}>{localValidationErrors.join('<br/>')}</div>}
            {/*{e["@language"]}:{' '}*/}{' '}
            {<TextField fullWidth={true} variant="filled" disabled style={{ color: 'white' }} size={'small'} value={e['$']} error={validation} />}
          </div>
        ))}
      </>
    );
  } else if (Array.isArray(elementData) && mode === 'editMode') {
    return (
      <>
        {elementData.map((e: any, idx: number) => (
          <div className={elementClassName} key={`${elementName}${idx}`}>
            {localValidationErrors.length > 0 && <div style={errorsList}>{localValidationErrors.join('<br/>')}</div>}
            {/*{e["@language"]}:{' '}*/}{' '}
            {
              <>
                <div style={blockSubGrouping}>
                  Lang
                  <TextField
                      variant="filled"
                      fullWidth={true}
                      style={{color: 'white'}}
                      size={'small'}
                      value={e['@language']}
                      error={validation}
                      onChange={(e) => {
                        changeHandler(e);
                        changeAttribute(`${path}[${idx}]['@language']`, e.target.value);
                      }}
                  />
                  Value
                  <TextField
                      variant="filled"
                      fullWidth={true}
                      style={{color: 'white'}}
                      size={'small'}
                      value={e['$']}
                      error={validation}
                      onChange={(e) => {
                        changeHandler(e);
                        changeAttribute(`${path}[${idx}]['$']`, e.target.value);
                      }}
                  />
                </div>
              </>
            }
          </div>
        ))}
        <Button
          sx={{ border: '#00ff45 1px solid', color: '#fff', backgroundColor: '#15ff0021', lineHeight: '0'}}
          onClick={() => {
            const updatedData = _.cloneDeep(elementData);
            updatedData.push({ $: '_empty_', '@language': 'zz' });
            changeAttribute(path, updatedData, false, false);
          }}>
          ADD LOCALE
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0'}}
        onClick={() => {
          changeAttribute(path, [{ $: '_empty_', '@language': 'zz' }], true, true);
        }}>
        ADD
      </Button>
    </>
  );
};

export default LocaleString;

import { DisplayElementTypes } from "../consts";
import { Validators } from "../consts";

const headerConf = {
  blockName: "Header",
  blockClasses: ["headerClass-1", "headerClass-2"],
  blockList: [
    {
      displayElementName: "Author",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["authorClass-1"],
      displayElementPath: "Header.Author",
    },
    {
      displayElementName: "Manufacturer",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["manufacturerClass-1"],
      displayElementPath: "Header.Manufacturer",
      validationConf: [
        {
          validator: Validators.nonEmptyString
        },
      ]
    },
    {
      displayElementName: "CreationTimeCode",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["creationTimeCodeClass-1"],
      displayElementPath: "Header.CreationTimeCode",
        validationConf: [
        {
          validator: Validators.nonEmptyString
        },
      ]
    },
    {
      displayElementName: "CreatedWithApplication",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["createdWithApplicationClass-1"],
      displayElementPath: "Header.CreatedWithApplication",
      validationConf: [
        {
          validator: Validators.nonEmptyString
        },
      ]
    },
    {
      displayElementName: "FormatVersion",
      displayElementType: DisplayElementTypes.PlainNumber,
      displayElementClasses: ["formatVersionClass-1"],
      displayElementPath: "Header.FormatVersion",
      validationConf: [
        {
          validator: Validators.nonEmptyString
        },
      ],
      readonly: true,
    },
    {
      displayElementName: "DefaultLanguage",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["defaultLanguageClass-1"],
      displayElementPath: "Header.DefaultLanguage",
    },
    {
      displayElementName: "LicenseKeys",
      displayElementType: DisplayElementTypes.LicenseKeys,
      displayElementClasses: ["licenseKeysClass-1"],
      displayElementPath: "Header.LicenseKeys.LicenseKey",
    },
    {
      displayElementName: "ReluxMemberId",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["reluxMemberId-1"],
      displayElementPath: "Header.ReluxMemberId",
    },
    {
      displayElementName: "DIALuxMemberId",
      displayElementType: DisplayElementTypes.PlainString,
      displayElementClasses: ["dIALuxMemberId-1"],
      displayElementPath: "Header.DIALuxMemberId",
    },
    {
      displayElementName: "Contact",
      displayElementType: DisplayElementTypes.ContactAddress,
      displayElementClasses: ["contactAddressClass-1"],
      displayElementPath: "Header.Contact.Address",
    },
  ],
};

export default headerConf;

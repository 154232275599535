import { DisplayElementTypes } from "../consts"
const photometriesConf = {
    blockName: "Photometries",
    blockClasses: ["photometriesClass-1", "photometriesClass-2"],
    blockList: [
        {
            displayElementName: "Photometries",
            displayElementType: DisplayElementTypes.Photometries,
            displayElementClasses: ["PhotometriesClasssGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.Photometries.Photometry"
        },
        
    ]
}

export default photometriesConf;
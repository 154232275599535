import { DisplayElementTypes } from "../consts"
const variantConf = {
    blockName: "variant",
    blockClasses: ["variantClass-1", "variantClass-2"],
    blockList: [
        {
            displayElementName: "Variant",
            displayElementType: DisplayElementTypes.Variant,
            displayElementClasses: ["variantClass-1"],
            displayElementPath: ""
        },
    ]
}

export default variantConf;
import { DisplayElementTypes } from "../consts"
const emittersConf = {
    blockName: "Emitters",
    blockClasses: ["emittersClass-1", "emittersClass-2"],
    blockList: [
        {
            displayElementName: "Emitters",
            displayElementType: DisplayElementTypes.Emitters,
            displayElementClasses: ["EmittersClasssGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.Emitters.Emitter"
        },
        
    ]
}

export default emittersConf;
import * as React from "react";

import {
    Architecture,
    Bolt,
    EmojiObjectsOutlined,
    Engineering,
    ExitToApp,
    FileDownload,
    FormatShapesOutlined,
    HomeRepairServiceOutlined,
    Light, LocalOffer,
    SelectAll,
    Settings,
    SignalWifiStatusbar4Bar,
    TungstenOutlined
} from "@mui/icons-material";

const categories = [
    {
        id: "Header",
        basePaths: ["Header"],
        children: [
            {
                id: "Header",
                basePath: "Header",
                icon: <Light/>,
                MenuConf: "headerConf",
                MenuSubConf: "None",
                editable: true,
                active: false                
            }
        ],
    },
    {
        id: "General Definitions",
        basePaths: ["GeneralDefinitions"],
        children: [
            {
                id: "Files",
                basePath: "GeneralDefinitions.Files",
                icon: <FileDownload/>,
                MenuConf: "filesConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            },
            {
                id: "Photometries",
                basePath: "GeneralDefinitions.Photometries",
                icon: <SignalWifiStatusbar4Bar/>,
                MenuConf: "photometriesConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            },
            {
                id: "Light Sources",
                basePath: "GeneralDefinitions.Sources",
                icon: <EmojiObjectsOutlined/>,
                MenuConf: "lightSourcesConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            },
            {
                id: "Control Gears",
                basePath: "GeneralDefinitions.ControlGears",
                icon: <Settings/>,
                MenuConf: "controlGearsConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            },
            {
                id: "Equipments",
                basePath: "GeneralDefinitions.Equipments",
                icon: <HomeRepairServiceOutlined/>,
                MenuConf: "equipmentsConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            },
            {
                id: "Emitters",
                basePath: "GeneralDefinitions.Emitters",
                icon: <TungstenOutlined/>,
                MenuConf: "emittersConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            },
            {
                id: "Geometries",
                basePath: "GeneralDefinitions.Geometries",
                icon: <FormatShapesOutlined/>,
                MenuConf: "geometriesConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            }
        ],
    },
    {
        id: "Product Metadata",
        basePaths: ["ProductDefinitions.ProductMetaData"],
        children: [
            {
                id: "Product Metadata",
                basePath: "ProductDefinitions.ProductMetaData",
                icon: <SelectAll/>,
                MenuConf: "productMetaDataDefinitionsConf",
                MenuSubConf: "None",
                editable: true,
                active: false,
            }
        ],
    },
    {
        id: "Descriptive Attributes",
        basePaths: ["ProductDefinitions.ProductMetaData.DescriptiveAttributes"],
        children: [
            {
                id: "Mechanical",
                basePath: "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Mechanical",
                icon: <Architecture/>,
                MenuConf: "DescriptiveAttributesConf",
                MenuSubConf: "Mechanical",
                editable: true,
                active: false,
            },
            {
                id: "Electrical",
                basePath: "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Electrical",
                icon: <Bolt/>,
                MenuConf: "DescriptiveAttributesConf",
                MenuSubConf: "Electrical",
                editable: true,
                active: false,
            },
            {
                id: "Emergency",
                basePath: "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Emergency",
                icon: <ExitToApp/>,
                MenuConf: "DescriptiveAttributesConf",
                MenuSubConf: "Emergency",
                editable: true,
                active: false,
            },
            {
                id: "Marketing",
                basePath: "ProductDefinitions.ProductMetaData.DescriptiveAttributes.Marketing",
                icon: <LocalOffer/>,
                MenuConf: "DescriptiveAttributesConf",
                MenuSubConf: "Marketing",
                editable: true,
                active: false,
            },
            {
                id: "Operations And Maintenance",
                basePath: "ProductDefinitions.ProductMetaData.DescriptiveAttributes.OperationsAndMaintenance",
                icon: <Engineering/>,
                MenuConf: "DescriptiveAttributesConf",
                MenuSubConf: "OperationsAndMaintenance",
                editable: true,
                active: false,
            },
        ],
    },
];

const item = {
    py: "2px",
    display: "flex",
    paddingLeft: 0,
    color: "rgba(255,255,255,0.75)",
};

const itemVariantinfo = {
    py: "2px",
    display: "flex",
    justifyContent: "center",
    height: "53px",
    fontWeight: 600,
    paddingLeft: 0,
    color: "white",
};

const itemCategory = {
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
    px: 3,
};

export { categories, item, itemCategory, itemVariantinfo };
import * as React from "react";
import "./style.scss";
import {Button} from "@mui/material";

const FlexButtonGroup = (({modalView, setModalView, closeModal}: any) => {
        return (
            <>
                {modalView.mode === "displayMode" && (
                    <>
                        {/* <Button sx={{ height: '30px', marginLeft: '1rem' }}
                            variant="outlined"
                            size={"small"}
                            onClick={() => {
                                setModalView({
                                    mode: "editMode",
                                    menuConf: modalView.menuConf,
                                    subMenuConf: modalView.subMenuConf,
                                });
                            }}
                        >
                            Edit
                        </Button> */}
                        <Button sx={{ height: '30px', marginLeft: 'auto', marginRight: '1rem' }}
                                variant="outlined"
                                size={"small"}
                                onClick={closeModal}
                        >
                            Close
                        </Button>
                    </>
                )}

                {modalView.mode === "editMode" && (
                    <>
                        {/* <Button sx={{ height: '30px', marginLeft: '1rem' }}
                                variant="outlined"
                                size={"small"}
                                onClick={() => {
                                    setModalView({
                                        mode: "displayMode",
                                        menuConf: modalView.menuConf,
                                        subMenuConf: modalView.subMenuConf,
                                    });
                                }}
                        >
                            Display
                        </Button> */}
                        <Button
                            sx={{ height: '30px', marginLeft: 'auto', marginRight: '1rem' }}
                            variant="contained"
                            size={"small"}
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            Close
                        </Button>
                    </>
                )}
            </>
        )
    }
)

export default FlexButtonGroup;
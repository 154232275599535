import axios from 'axios';
import { APIURL } from '../consts';

const submitProduct = (fileUUID: string, payload: any, submitCallBackFunc: Function) => {
  const url = `${APIURL}json/${fileUUID}/`;
  axios
    .post(url, payload)
    .then((resp) => {
      console.log(resp);
      if (resp.status === 201 || resp.status === 200) {
        if (resp.data.errors.length > 0) {
          submitCallBackFunc(false);
        } else {
          submitCallBackFunc(true);
        }
      }
    })
    .catch((error) => {
      console.log(error);
      window.alert('error:' + JSON.stringify(error));
    });
};
interface ICopyProduct {
  fileUUID: string;
  callBackFunc(uuid: string): void;
}
const copyProduct = ({ fileUUID, callBackFunc }: ICopyProduct) => {
  const url = `${APIURL}${fileUUID}/json/?copy_uuid=true`;
  axios
    .get(url)
    .then((resp) => {
      console.log(resp);
      if (resp.status === 200) {
        callBackFunc(resp.data);
      }
    })
    .catch((error) => {
      console.log(error);
      window.alert('error:' + JSON.stringify(error));
    });
};

export { submitProduct, copyProduct };

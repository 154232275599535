import * as React from 'react';
import * as _ from 'lodash';
import { PlainString } from '../../';
import { RecessedTypes, Validators } from '../../../../consts/index';

const WorkingPlane = ({ mode, product, path, elementName, elementData, debugElement, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  return (
    <>
      <PlainString
        readonly
        mode={mode}
        product={product}
        path={`${path}`}
        key={`${elementName}-WorkingPlane`}
        classes="working-plane"
        elementName={`${elementName}`}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.keys(_.get(product, `${path}`))[0]}
      />
    </>
  );

  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default WorkingPlane;

import * as React from "react";
import { PlainString, LocaleString } from "..";
import * as _ from "lodash";
import {Accordion, AccordionSummary } from "@mui/material";
import {ChevronLeft} from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const Variants = ({
  mode,
  setSelectedVariant,
  product,
  path,
  elementName,
  elementData,
  debugElement,
}: any) => {

  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  const [expanded, setExpanded] = React.useState<string | boolean>('panel0');

  const handleChange =
      (panel: string, elementID: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        setSelectedVariant(elementID);
        
      };

  if (Array.isArray(elementData)) {
    if (mode === 'listMode') {
      return (
          <>
            {elementData.map((element: any, idx: number) => (
                <Accordion expanded={expanded === 'panel' + idx}
                           onChange={handleChange('panel' + idx, idx)}
                           key={`${idx}`}
                           disableGutters={true}
                >
                  <AccordionSummary
                      sx={{bgcolor: "#101F33", color: "#fff"}}
                      expandIcon={<ChevronLeft color={"info"}/>}
                  >
                    <ListItem sx={{py: 2, px: 3}}>
                      <ListItemText sx={{color: "#fff"}}>
                        {element.Name.Locale[0]["$"]}
                      </ListItemText>
                    </ListItem>
                  </AccordionSummary>
                </Accordion>
            ))
            }
          </>
      )
    } else {
      return (
        <>
          {elementData.map((element: any, idx: number) => (
            <div key={`${idx}`}>
              <div>
                {element.Name.Locale[0]["$"]}
                <br />
                <strong>GTIN</strong><br />{" "}
                <PlainString
                  mode={mode}
                  classes={['variant-plain-string', 'variant-plain-string-gtin']}
                  elementName={`${elementName}-GTIN`}
                  debugElement={debugElement}
                  elementData={_.get(product, `${path}[${idx}].GTIN`)}
                />
                <br />
                <strong>Product Number</strong><br/>{" "}
                <PlainString
                  mode={mode}
                  classes={['variant-plain-string', 'variant-plain-string-product-number']}
                  elementName={`${elementName}-ProductNumber`}
                  debugElement={debugElement}
                  elementData={_.get(product, `${path}[${idx}].ProductNumber`)}
                />
                <br />
                <strong>Variant Name</strong><br/>{" "}
                <LocaleString
                  mode={mode}
                  classes="variant-name"
                  elementName={`${elementName}-Variant Name`}
                  debugElement={debugElement}
                  elementData={_.get(product,`${path}[${idx}].Name.Locale`)}
                />
                <br />
                <strong>Description</strong><br/>{" "}
                <LocaleString
                  mode={mode}
                  classes="variant-description"
                  elementName={`${elementName}-LocaleString`}
                  debugElement={debugElement}
                  elementData={_.get(product,`${path}[${idx}].Description.Locale`)}
                />
                <br />
                <strong>Tender Text</strong><br/>{" "}
                <LocaleString
                  mode={mode}
                  classes="variant-tender-text"
                  elementName={`${elementName}-TenderText`}
                  debugElement={debugElement}
                  elementData={_.get(product,`${path}[${idx}].TenderText.Locale`)}
                />
                <br />
              </div>
            </div>
          ))}
        </>
      );
    }
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default Variants;

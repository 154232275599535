import * as React from 'react';
import { Box, Button } from '@mui/material';
import { FlexModal, FlexRow } from './UserInterfaceElements';
import { FileInput, TFileInputType, EFileInputType } from './DisplayElements';
import { GLDFBase64File, IUploadModal } from '../interfaces';
import { getFileIds, getFiles } from '../util';
import * as _ from 'lodash';

const UploadModal = ({
  fileUUID,
  product,
  debugEnabled,
  GLDFBase64FileArray,
  setGLDFBase64FileArray,
  modalStyle,
  isUploadModalOpen,
  setUploadModalOpen,
  closeModal,
  changeAttribute,
}: IUploadModal) => (
  <FlexModal isUploadModalOpen={isUploadModalOpen} closeModal={() => closeModal(setUploadModalOpen)}>
    <Box sx={modalStyle}>
      <FlexRow align={'center'} justify={'flex-start'} rowBg={'#003a7f'} minHeight={'53px'} padding={'.7rem'}>
        <h3 style={{ margin: '0px', padding: '0 1rem' }}>Upload File</h3>
        <Button sx={{ height: '30px', marginLeft: 'auto' }} variant="outlined" size={'small'} onClick={() => setUploadModalOpen(false)}>
          Close
        </Button>
      </FlexRow>
      <FileInput
        fileUUID={fileUUID}
        elementName="testfileinput"
        elementData=""
        debugElement={debugEnabled}
        existingIds={getFileIds(product)}
        errorHandler={() => {}}
        changeHandlerFileInput={(
          elementName: string,
          id: string,
          fileType: TFileInputType,
          fileContentType: string,
          fileName: string,
          fileData: any,
          fileSize: number,
          url: string,
          isLDTOrL3D: boolean
        ) => {
          console.log(elementName, id, url, fileType, fileContentType, fileName, fileData, fileSize, isLDTOrL3D);
          let tmpFiles = getFiles(product);
          switch (fileType) {
            case EFileInputType.localFileName:
              tmpFiles.push({
                '@id': id,
                '@contentType': fileContentType,
                '@type': 'localFileName',
                $: fileName,
              });
              changeAttribute(`GeneralDefinitions.Files.File`, tmpFiles);
              if (fileContentType === 'ldc/ies' || fileContentType === 'ldc/eulumdat' || fileContentType === 'ldc/iesxml') {
                let newPhotometry = {
                  '@id': `photometry_${id}`,
                  PhotometryFileReference: {
                    '@fileId': `${id}`,
                  },
                };
                let currentPhotometries = _.get(product, `GeneralDefinitions.Photometries.Photometry`);
                currentPhotometries.push(newPhotometry);
                changeAttribute(`GeneralDefinitions.Photometries.Photometry`, currentPhotometries);
              }

              if (fileContentType === 'geo/l3d') {
                let newGeometry = {
                  '@id': `geometry_${id}`,
                  GeometryFileReference: [{
                    '@fileId': `${id}`,
                  }],
                };
                let currentPhotometries = _.get(product, `GeneralDefinitions.Geometries.ModelGeometry`);
                currentPhotometries.push(newGeometry);
                changeAttribute(`GeneralDefinitions.Geometries.ModelGeometry`, currentPhotometries);
              }

              //LDT/L3D files are already uploaded to the server
              if (!isLDTOrL3D) {
                let tmpGLDFBase64FileArray = _.cloneDeep(GLDFBase64FileArray);
                let tmpGLDFBase64File: GLDFBase64File = {
                  id: id,
                  fileContentType: fileContentType,
                  fileName: fileName,
                  fileData: fileData,
                };
                tmpGLDFBase64FileArray.push(tmpGLDFBase64File);
                setGLDFBase64FileArray(tmpGLDFBase64FileArray);
              }
              break;
            case EFileInputType.url:
              tmpFiles.push({
                '@id': id,
                '@contentType': fileContentType,
                '@type': 'url',
                $: url,
              });
              changeAttribute(`GeneralDefinitions.Files.File`, tmpFiles);
              if (fileContentType === 'ldc/ies' || fileContentType === 'ldc/eulumdat' || fileContentType === 'ldc/iesxml') {
                let newPhotometry = {
                  '@id': `photometry_${id}`,
                  PhotometryFileReference: {
                    '@fileId': `${id}`,
                  },
                };
                let currentPhotometries = _.get(product, `GeneralDefinitions.Photometries.Photometry`);
                currentPhotometries.push(newPhotometry);
                changeAttribute(`GeneralDefinitions.Photometries.Photometry`, currentPhotometries);
              }
              if (fileContentType === 'geo/l3d') {
                let newGeometry = {
                  '@id': `geometry_${id}`,
                  GeometryFileReference: [{
                    '@fileId': `${id}`,
                  }],
                };
                let currentPhotometries = _.get(product, `GeneralDefinitions.Geometries.ModelGeometry`);
                currentPhotometries.push(newGeometry);
                changeAttribute(`GeneralDefinitions.Geometries.ModelGeometry`, currentPhotometries);
              }
              break;
            default:
              break;
          }
        }}
      />
    </Box>
  </FlexModal>
);

export default UploadModal;

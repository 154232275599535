import * as React from "react";
import classNames from "classnames";
import { Checkbox } from "@mui/material";
import { validateAll } from "../../../util";
import * as _ from "lodash";

const CheckboxArray = ({
  mode,
  path,
  elementName,
  classes,
  availableCheckboxList,
  validationConf,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  const [localValidationErrors, setLocalValidationErrors] = React.useState<
    string[]
  >([]);

  const changeHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    element: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    let tmpElementData = _.cloneDeep(elementData);
    if (_.indexOf(elementData, element) > -1) {
      tmpElementData = _.difference(tmpElementData, [element]);
    } else {
      tmpElementData = _.union(tmpElementData, [element]);
    }
    if (!_.isNil(validationConf)) {
      const { isValid, validationErrors } = validateAll(
        tmpElementData,
        validationConf
      );
      if (!isValid) {
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrors as string[]);
      } else {
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
    changeAttribute(path, tmpElementData);
    debugElement && console.log(this);
  };

  React.useEffect(() => {
    if (!_.isNil(validationConf)) {
      const { validationErrors: validationErrorsArray } = validateAll(
        elementData,
        validationConf
      );
      setLocalValidationErrors(validationErrorsArray as string[]);
    }
  }, []);

  if (Array.isArray(elementData)) {
    const plainStringClassNames = classNames("checkbox-array", classes);
    return (
      <>
        <span className={plainStringClassNames}>
          {localValidationErrors.length > 0 && (
            <div style={{ color: "red" }}>
              {localValidationErrors.join("<br/>")}
            </div>
          )}
          {availableCheckboxList.map((currentCheckbox: string, idx: number) => {
            return (
              <div key={`${currentCheckbox}-${idx}`}>
                {currentCheckbox}
                {!(mode && mode === "editMode") ? (
                  <Checkbox
                    disabled
                    checked={_.indexOf(elementData, currentCheckbox) > -1}
                  />
                ) : (
                  <Checkbox
                    checked={_.indexOf(elementData, currentCheckbox) > -1}
                    onClick={(e) => changeHandler(e, currentCheckbox)}
                  />
                )}
              </div>
            );
          })}
        </span>
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default CheckboxArray;

import * as React from 'react';
import { Chip, Paper } from '@mui/material';
import FlexRow from '../../FlexRow';
import { returnVariantMountingType } from '../../../../util';
import './style.scss';
import * as _ from 'lodash';

const VariantInfo = ({ selectedVariant, selectedEquipment }: any) => {
  if (typeof selectedVariant === 'undefined') {
    return null;
  }

  return (
    <>
      <div style={{ marginLeft: '2rem' }}>
        <FlexRow minHeight={'100%'} flexDirection={'row'} justify={'flex-start'} rowBg={'#fff'}>
          <Paper elevation={3} sx={{ minWidth: '500px', padding: '2rem', maxWidth: '500px' }}>
            <div style={{ paddingLeft: '6px', marginBottom: '1rem', color: '#113b6f', fontWeight: '700' }}>Variant</div>
            {selectedVariant && (
              <div
                style={{
                  display: 'flex',
                  width: `100%`,
                  justifyContent: 'flex-between',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'Description'} size="small" />
                <div
                  style={{
                    marginLeft: '2rem',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    marginRight: '1.5rem',
                  }}>
                  {_.get(selectedVariant, `Description.Locale[0]['$']`, 'No description available')}
                </div>
              </div>
            )}
            {selectedVariant && (
              <div
                style={{
                  display: 'flex',
                  width: `100%`,
                  justifyContent: 'flex-between',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'TenderText'} size="small" />
                <div
                  style={{
                    marginLeft: '2rem',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    marginRight: '1.5rem',
                  }}>
                  {_.get(selectedVariant, `TenderText.Locale[0]['$']`, '---empty---')}
                </div>
              </div>
            )}
            {selectedVariant && (
              <div
                style={{
                  display: 'flex',
                  width: '400px',
                  justifyContent: 'flex-between',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'GTIN'} size="small" />
                <div style={{ marginLeft: '2rem' }}>{selectedVariant.GTIN}</div>
              </div>
            )}
            {selectedVariant && (
              <div
                style={{
                  display: 'flex',
                  width: `100%`,
                  justifyContent: 'flex-between',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'Product Number'} size="small" />
                <div
                  style={{
                    marginLeft: '2rem',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    marginRight: '1.5rem',
                  }}>
                  {_.get(selectedVariant, `ProductNumber.Locale[0]['$']`, '---empty---')}
                </div>
              </div>
            )}
            {selectedVariant && (
              <div
                style={{
                  display: 'flex',
                  width: '400px',
                  justifyContent: 'flex-between',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'Mountings'} size="small" />
                <div style={{ marginLeft: '2rem' }}>
                  {(returnVariantMountingType(selectedVariant) as string[]).map((el: any) => {
                    return <div>{el}</div>;
                  })}
                </div>
              </div>
            )}
          </Paper>
          {selectedEquipment && (
            <Paper elevation={3} sx={{ padding: '1rem', marginLeft: '2rem', maxWidth: '300px' }}>
              <div
                style={{
                  display: 'flex',
                  width: '250px',
                  justifyContent: 'flex-between',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}>
                <Chip sx={{ color: '#1976d2' }} variant={'outlined'} label={'Equipment'} size="small" />
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '250px',
                  justifyContent: 'flex-between',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'Rated Input Power'} size="small" />
                <div style={{ marginLeft: '2rem' }}>{selectedEquipment.RatedInputPower}</div>
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '250px',
                  justifyContent: 'flex-between',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}>
                <Chip variant={'outlined'} label={'Rated Luminous Flux'} size="small" />
                <div style={{ marginLeft: '2rem' }}>{selectedEquipment.RatedLuminousFlux}</div>
              </div>

              {selectedEquipment?.EmergencyBallastLumenFactor && (
                <div
                  style={{
                    display: 'flex',
                    width: '250px',
                    justifyContent: 'flex-between',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}>
                  <Chip variant={'outlined'} label={'[E] Ballast Lumen Factor'} size="small" />
                  <div style={{ marginLeft: '2rem' }}>{selectedEquipment.EmergencyBallastLumenFactor}</div>
                </div>
              )}
              {selectedEquipment?.EmergencyRatedLuminousFlux && (
                <div
                  style={{
                    display: 'flex',
                    width: '250px',
                    justifyContent: 'flex-between',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}>
                  <Chip variant={'outlined'} label={'[E] Rated Luminous Flux'} size="small" />
                  <div style={{ marginLeft: '2rem' }}>{selectedEquipment.EmergencyRatedLuminousFlux}</div>
                </div>
              )}
            </Paper>
          )}
        </FlexRow>
      </div>
    </>
  );
};

export default VariantInfo;

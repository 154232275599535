import * as React from "react";
import * as _ from "lodash";
import Ceiling from "./Ceiling";
import Wall from "./Wall";
import WorkingPlane from "./WorkingPlane";
import Ground from "./Ground";

const Mountings = ({mode, mountingType, product, path, elementName, elementData, debugElement, changeAttribute}: any) => {
    debugElement && console.log(`DATA ${elementName}`, elementData, debugElement)
    if (mountingType === 'Ceiling') {
        return (
            <>
                <Ceiling
                    mode={mode}
                    path={`${path}`}
                    product={product}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={elementData}
                >
                </Ceiling>
            </>
        )
    }
    if (mountingType === 'Wall') {
        return (
            <>
                <Wall
                    mode={mode}
                    path={`${path}`}
                    product={product}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={elementData}
                >
                </Wall>
            </>
        )
    }
    if (mountingType === 'WorkingPlane') {
        return (
            <>
                <WorkingPlane
                    mode={mode}
                    path={`${path}`}
                    product={product}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={elementData}
                >
                </WorkingPlane>
            </>
        )
    }
    if (mountingType === 'Ground') {
        return (
            <>
                <Ground
                    mode={mode}
                    path={path}
                    product={product}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={elementData}
                >
                </Ground>
            </>
        )
    }
    return (debugElement ?
        <strong style={{color: "#f68f9c"}}>{`Element: ${elementName} could not be found`}</strong> : null);
}

export default Mountings;
import * as React from "react";
import {Input, TextField} from "@mui/material";

const HyperLink = ({mode, path, elementName, elementData, debugElement, changeAttribute}: any) => {
    debugElement && console.log(`DATA ${elementName}`, elementData, debugElement)
    if (Array.isArray(elementData)) {
        return (<>
            {elementData.map((element: any, idx: number) => {
                return (
                    <React.Fragment key={idx}>
                        {mode === 'displayMode' || !mode ? (
                            <>
                                <TextField
                                    variant={"filled"}
                                    fullWidth={true}
                                    disabled
                                    style={{color: 'white'}}
                                    size={'small'}
                                    value={element['@href']}/>
                                <TextField
                                    variant={"filled"}
                                    fullWidth={true}
                                    disabled
                                    style={{color: 'white'}}
                                    size={'small'}
                                    value={element['$']}/>
                            </>
                        ) : (
                            <>
                                <TextField
                                    variant={"filled"}
                                    fullWidth={true}
                                    size={'small'}
                                    value={element['@href']}
                                    onChange={(e) => changeAttribute(`${path}[${idx}]['@href']`, e.target.value)}
                                />
                                <TextField
                                    variant={"filled"}
                                    fullWidth={true}
                                    size={'small'}
                                    value={element['$']}
                                    onChange={(e) => changeAttribute(`${path}[${idx}]['$']`, e.target.value)}
                                />
                            </>
                        )}
                    </React.Fragment>
                )
            })}
        </>)
    }
    return (debugElement? <strong style={{color: "#f68f9c"}}>{`Element: ${elementName} could not be found`}</strong>: null);
}

export default HyperLink;

import * as React from 'react';
import * as _ from 'lodash';
import { Validators } from '../../../consts';
import { PlainNumber, PlainString, XYNumberElement, SelectList, PeakDivergence } from '..';
import { getPhotometryFileIds } from '../../../util';
import {blockFileRefGrouping, blockSubGrouping} from '../../../css/theme';

const PhotometryOverrideFunction = (product: any, path: string, data: any) => {
  let out = _.get(product, path);
  if (!_.isNil(out.DescriptivePhotometry)) {
    _.unset(out, 'DescriptivePhotometry');
  }
  _.set(out, `PhotometryFileReference["@fileId"]`, data);
  return out;
};

const Photometries = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  validationErrors,
  setValidationErrors,
  debugElement,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  // ToDo: move up
  const setValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    if (tmpValidationErrors.indexOf(elementPath) < 0) {
      tmpValidationErrors.push(elementPath);
    }
    setValidationErrors(tmpValidationErrors);
  };

  const unSetValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    const index = tmpValidationErrors.indexOf(elementPath);
    if (index > -1) {
      _.pullAt(tmpValidationErrors, index);
      setValidationErrors(tmpValidationErrors);
    }
  };
  if (Array.isArray(elementData)) {
    return (
      <>
        {/*{JSON.stringify(elementData)}*/}
        {elementData.map((element: any, idx: number) => {
          return (
            <div key={`equipments-${idx}`}>
              <PlainString
                mode={mode}
                product={product}
                path={`${path}[${idx}]['@id']`}
                key={`${elementName}-${idx}-ID`}
                classes="photometry-id-class"
                elementName={`${elementName}-${idx}-id`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonEmptyString,
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={element['@id']}
              />
              <>
                <div style={blockFileRefGrouping}>
                  <div style={{margin: '10px 10px 10px 0'}}>Photometry File Reference</div>
                  <SelectList
                    mode={mode}
                    availableListItems={getPhotometryFileIds(product)}
                    product={product}
                    path={`${path}[${idx}].PhotometryFileReference['@fileId']`}
                    referencePrefix="file"
                    key={`${elementName}-PhotometryFileReference`}
                    classes="photometry-PhotometryFileReference-class"
                    elementName={`${elementName}-Photometry-PhotometryFileReference`}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    preProcessFunction={PhotometryOverrideFunction}
                    pathToOverride={`${path}[${idx}]`}
                    elementData={_.get(product, `${path}[${idx}].PhotometryFileReference['@fileId']`)}
                  />
                </div>
              </>
              {element.DescriptivePhotometry && (
                <>
                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Luminaire Luminance</div>
                    <PlainNumber
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.LuminaireLuminance`}
                      key={`${elementName}-DescriptivePhotometry-LuminaireLuminance`}
                      classes="photometry-DescriptivePhotometry-LuminaireLuminance-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-LuminaireLuminance`}
                      debugElement={debugElement}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.integersOnly,
                        },
                      ]}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.LuminaireLuminance`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Light Output Ratio</div>
                    <PlainNumber
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.LightOutputRatio`}
                      key={`${elementName}.DescriptivePhotometry-LightOutputRatio`}
                      classes="photometry-DescriptivePhotometry-LightOutputRatio-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-LightOutputRatio`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.range,
                          minValue: 0,
                          maxValue: 1,
                          isInteger: false,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.LightOutputRatio`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Luminous Efficacy</div>
                    <PlainNumber
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.LuminousEfficacy`}
                      key={`${elementName}.DescriptivePhotometry-LuminousEfficacy`}
                      classes="photometry-DescriptivePhotometry-LuminousEfficacy-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-LuminousEfficacy`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.range,
                          minValue: 0,
                          maxValue: 683,
                          isInteger: false,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.LuminousEfficacy`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Downward Flux Fraction</div>
                    <PlainNumber
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.DownwardFluxFraction`}
                      key={`${elementName}.DescriptivePhotometry-DownwardFluxFraction`}
                      classes="photometry-DescriptivePhotometry-DownwardFluxFraction-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-DownwardFluxFraction`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.range,
                          minValue: 0,
                          maxValue: 1,
                          isInteger: false,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.DownwardFluxFraction`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Downward Light Output Ratio</div>
                    <PlainNumber
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.DownwardLightOutputRatio`}
                      key={`${elementName}.DescriptivePhotometry-DownwardLightOutputRatio`}
                      classes="photometry-DescriptivePhotometry-DownwardLightOutputRatio-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-DownwardLightOutputRatio`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.range,
                          minValue: 0,
                          maxValue: 1,
                          isInteger: false,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.DownwardLightOutputRatio`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Upward Light Οutput Ratio</div>
                    <PlainNumber
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.UpwardLightOutputRatio`}
                      key={`${elementName}.DescriptivePhotometry-UpwardLightOutputRatio`}
                      classes="photometry-DescriptivePhotometry-UpwardLightOutputRatio-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-UpwardLightOutputRatio`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.range,
                          minValue: 0,
                          maxValue: 1,
                          isInteger: false,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.UpwardLightOutputRatio`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Tenth Peak Divergence</div>
                    <PeakDivergence
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.TenthPeakDivergence`}
                      key={`${elementName}.DescriptivePhotometry-TenthPeakDivergence`}
                      classes="photometry-DescriptivePhotometry-TenthPeakDivergence-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-TenthPeakDivergence`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.TenthPeakDivergence`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Half Peak Divergence</div>
                    <PeakDivergence
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.HalfPeakDivergence`}
                      key={`${elementName}.DescriptivePhotometry-HalfPeakDivergence`}
                      classes="photometry-DescriptivePhotometry-HalfPeakDivergence-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-HalfPeakDivergence`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.HalfPeakDivergence`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Photometric Code</div>
                    <PlainString
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.PhotometricCode`}
                      key={`${elementName}.DescriptivePhotometry-PhotometricCode`}
                      classes="photometry-DescriptivePhotometry-PhotometricCode-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-PhotometricCode`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.nonEmptyString,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.PhotometricCode`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>CIE FluxCode</div>
                    <PlainString
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry['CIE-FluxCode']`}
                      key={`${elementName}.DescriptivePhotometry-CIE-FluxCode`}
                      classes="photometry-DescriptivePhotometry-CIE-FluxCode-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-CIE-FluxCode`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.nonEmptyString,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry['CIE-FluxCode']`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>CutOff Angle</div>
                    <PlainNumber
                      mode={mode}
                      isFloat={true}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry.CutOffAngle`}
                      key={`${elementName}.DescriptivePhotometry-CutOffAngle`}
                      classes="photometry-DescriptivePhotometry-CutOffAngle-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-CutOffAngle`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.anyNumber,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry.CutOffAngle`)}
                    />
                  </>

                  <>
                    <div style={blockSubGrouping}>
                      <div style={{ margin: '10px 10px 10px 0' }}>UGR-4H8H-70-50-20-LQ</div>
                      <XYNumberElement
                        mode={mode}
                        isFloat={true}
                        product={product}
                        path={`${path}[${idx}].DescriptivePhotometry['UGR-4H8H-70-50-20-LQ']`}
                        key={`${elementName}.DescriptivePhotometry-UGR-4H8H-70-50-20-LQ`}
                        classes="photometry-DescriptivePhotometry-UGR-4H8H-70-50-20-LQ-class"
                        elementName={`${elementName}-Photometry-DescriptivePhotometry-UGR-4H8H-70-50-20-LQ`}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry['UGR-4H8H-70-50-20-LQ']`)}
                      />
                    </div>
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>IESNA-Light Distribution Definition</div>
                    <PlainString
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry['IESNA-LightDistributionDefinition']`}
                      key={`${elementName}.DescriptivePhotometry-IESNA-LightDistributionDefinition`}
                      classes="photometry-DescriptivePhotometry-IESNA-LightDistributionDefinition-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-IESNA-LightDistributionDefinition`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.nonEmptyString,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry['IESNA-LightDistributionDefinition']`)}
                    />
                  </>

                  <>
                    <div style={{ margin: '10px 10px 10px 0' }}>Light Distribution BUG-Rating</div>
                    <PlainString
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].DescriptivePhotometry['LightDistributionBUG-Rating']`}
                      key={`${elementName}.DescriptivePhotometry-LightDistributionBUG-Rating`}
                      classes="photometry-DescriptivePhotometry-LightDistributionBUG-Rating-class"
                      elementName={`${elementName}-Photometry-DescriptivePhotometry-LightDistributionBUG-Rating`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.nonEmptyString,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].DescriptivePhotometry['LightDistributionBUG-Rating']`)}
                    />
                  </>
                </>
              )}
              <hr />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Photometries;

import * as React from 'react';
import { PlainNumber } from '..';
import { Button } from '@mui/material';
import { blockSubGrouping } from '../../../css/theme';
import { Validators } from '../../../consts';
import * as _ from 'lodash';

const Range = ({
  mode,
  isFloat,
  product,
  path,
  elementName,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  extraFields,
  debugElement,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  
  //ToDo: Support either number or double
  //ToDo: Support default Values
  if (elementData) {
    const { Upper, Lower } = elementData;
    debugElement && console.log(Upper, Lower);
    return (
      <>
        <div style={blockSubGrouping}>
          <div>
            <strong>Lower</strong>
            <PlainNumber
              mode={mode}
              isFloat={isFloat ? true : false}
              product={product}
              path={`${path}.Lower`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              validationConf={[
                {
                  validator: Validators.anyNumber,
                },
              ]}
              elementName={`${elementName} Lower`}
              debugElement={debugElement}
              elementData={Lower}
              changeAttribute={changeAttribute}
            />
          </div>
          <div>
            <strong>Upper</strong>
            <PlainNumber
              mode={mode}
              isFloat={isFloat ? true : false}
              product={product}
              path={`${path}.Upper`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              validationConf={[
                {
                  validator: Validators.anyNumber,
                },
              ]}
              elementName={`${elementName} Upper`}
              debugElement={debugElement}
              elementData={Upper}
              changeAttribute={changeAttribute}
            />
          </div>
          {Array.isArray(extraFields) &&
            extraFields.map((extraField: any) => {
              //ToDo: Add keys
              return (
                <div>
                  <strong>{extraField}</strong>
                  <PlainNumber
                    mode={mode}
                    product={product}
                    path={`${path}['${extraField}']`}
                    elementName={`${elementName} ${extraField}`}
                    debugElement={debugElement}
                    elementData={elementData[extraField]}
                    changeAttribute={changeAttribute}
                  />
                </div>
              );
            })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0'}}
          onClick={() => {
            changeAttribute(path, { Lower: isFloat?0.01:1, Upper: isFloat?99.9:99 }, true);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default Range;

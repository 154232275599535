import * as React from 'react';
import axios from 'axios';
import * as FormData from 'form-data';
import { Input, Button, Select, MenuItem, Box, FormControl, InputLabel, TextField, Snackbar } from '@mui/material';
import { errorsList } from '../../../css/theme';
import { FileInputContentType, APIURL } from '../../../consts';
import { IFileInput } from '../../../interfaces';
import { object, string } from 'yup';
import { FlexRow } from '../../UserInterfaceElements';
import * as _ from 'lodash';
import { isNull } from 'lodash';

type FileReaderResultType = string | ArrayBuffer | null;

enum EFileInputType {
  localFileName,
  url,
}

type TFileInputType = EFileInputType;

const FileInput = ({ elementName, elementData, debugElement, changeHandlerFileInput, existingIds, errorHandler, fileUUID }: IFileInput) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  const changeIdHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setId(event.target.value);
  };

  const changeUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [eventTarget, setEventTarget] = React.useState({});
  const [fileType, setFileType] = React.useState<TFileInputType>(EFileInputType.localFileName);
  const [id, setId] = React.useState('');
  const [idError, setIdError] = React.useState([]);
  const [url, setUrl] = React.useState('');
  const [urlError, setUrlError] = React.useState([]);
  const [fileContentType, setFileContentType] = React.useState(FileInputContentType[15]);
  const [fileName, setFilename] = React.useState('');
  const [fileSize, setFileSize] = React.useState(0);
  const [fileData, setFileData] = React.useState<FileReaderResultType>('');
  return (
    <>
      <Box sx={{ p: 1 }}>
        <FlexRow align={'center'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
          <FormControl sx={{ minWidth: '100%' }} variant="filled" size="small">
            <InputLabel id="demo-simple-select-filled-label">File type</InputLabel>
            <Select
              value={fileContentType}
              onChange={(e) => {
                setFileContentType(e.target.value);
              }}>
              {FileInputContentType.map((fileInputContentType: string, idx: number) => {
                return (
                  <MenuItem key={idx} value={fileInputContentType}>
                    {fileInputContentType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </FlexRow>
        <FlexRow align={'center'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
          <FormControl sx={{ minWidth: '100%', marginBottom: '2rem' }} variant="filled" size="small">
            <InputLabel id="demo-simple-select-filled-label">Source</InputLabel>
            <Select
              value={fileType}
              onChange={(e) => {
                setUrl('');
                setFileData('');
                setFilename('');
                setFileSize(0);
                // setFileContentType(FileInputContentType[15]);
                setFileType(e.target.value as EFileInputType);
              }}>
              <MenuItem key={0} value={EFileInputType.localFileName}>
                Local File Name
              </MenuItem>
              <MenuItem key={1} value={EFileInputType.url}>
                URL
              </MenuItem>
            </Select>
          </FormControl>
        </FlexRow>
        {fileType === EFileInputType.localFileName && (
          <>
            <FlexRow align={'baseline'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
              <Input
                type="file"
                onChange={(fileChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
                  if (!fileChangeEvent.target.files) {
                    return;
                  }
                  setEventTarget(fileChangeEvent.target.files[0]);
                  console.log(fileChangeEvent.target.files[0]);
                  setFilename(fileChangeEvent.target.files[0].name);
                  setFileSize(fileChangeEvent.target.files[0].size);
                  const fileReader = new FileReader();
                  fileReader.readAsDataURL(fileChangeEvent.target.files[0]);
                  fileReader.onload = (fileReaderResultEvent) => {
                    if (fileReaderResultEvent.target !== null && fileChangeEvent.target !== null && fileChangeEvent.target.files !== null) {
                      setFileData(fileReaderResultEvent.target.result as FileReaderResultType);
                    } else {
                      errorHandler('ERROR');
                    }
                  };
                }}
              />
              {fileSize > 0 && (
                <span style={{ marginLeft: '2rem' }}>
                  {fileName} ({fileSize} bytes)
                </span>
              )}
            </FlexRow>
            <FlexRow align={'center'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
              <Button
                sx={{ height: '30px', marginLeft: 'auto' }}
                variant="contained"
                size={'small'}
                onClick={() => {
                  setId('');
                  setEventTarget({});
                  setFileData('');
                  setFilename('');
                  setFileSize(0);
                  // setFileContentType(FileInputContentType[15]);
                }}>
                Clear Upload
              </Button>
            </FlexRow>
          </>
        )}
        {fileType === EFileInputType.url && (
          <>
            <FlexRow align={'center'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
              <FormControl sx={{ minWidth: '100%' }} variant="filled" size="small">
                <TextField
                  fullWidth={true}
                  label={'URL'}
                  variant="filled"
                  style={{ color: 'white' }}
                  type="text"
                  value={url}
                  onChange={changeUrlHandler}
                  error={urlError.length > 0}
                />
                {urlError.length > 0 && <div style={errorsList}>{urlError.join('<br/>')}</div>}
              </FormControl>
            </FlexRow>
            <FlexRow align={'center'} justify={'flex-end'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
              <Button
                sx={{ height: '30px', marginLeft: 'auto' }}
                variant="contained"
                size={'small'}
                onClick={() => {
                  setId('');
                  setUrl('');
                  setEventTarget({});
                }}>
                Clear
              </Button>
            </FlexRow>
          </>
        )}
        <FlexRow align={'center'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
          <FormControl sx={{ minWidth: '100%' }} variant="filled" size="small">
            <TextField
              fullWidth={true}
              label={'fileID'}
              variant="filled"
              style={{ color: 'white' }}
              type="text"
              value={id}
              error={idError.length > 0}
              onChange={changeIdHandler}
            />
            {idError.length > 0 && <div style={errorsList}>{idError.join('<br/>')}</div>}
          </FormControl>
        </FlexRow>
        <FlexRow align={'center'} justify={'flex-start'} rowBg={'#081627'} minHeight={'53px'} padding={'.7rem'}>
          <Button
            sx={{
              height: '30px',
              marginLeft: 'auto',
              color: 'black',
              bgcolor: '#dea500',
            }}
            variant="outlined"
            size={'small'}
            onClick={() => {
              let idSchema = object({
                data: string()
                  .required('ID is required')
                  .test('number_first_char', 'first character should not be a number', (data) => {
                    if (_.isNil(data)) return true;
                    if ((data as string).length > 0) {
                      const firstCharacter = data[0];
                      const matched = firstCharacter.match(/\d/);
                      if (matched) return false;
                      return true;
                    }
                    return true;
                  })
                  .test('unique id', 'ID is already used', (data) => {
                    if (data) {
                      return existingIds.indexOf(data as string) < 0;
                    } else {
                      return false;
                    }
                  }),
              });

              let urlSchema = object({
                data: string().required('URL is required').url('URL must be valid'),
              });

              switch (fileType) {
                case EFileInputType.localFileName:
                  if (fileData === null || fileData === '' || fileData === 'ERROR' || fileSize === 0) {
                    window.alert('You must choose a valid file');
                  } else {
                    try {
                      idSchema.validateSync({ data: id });
                      setIdError([]);
                      if (fileContentType === 'ldc/eulumdat') {
                        // upload it to the server and get the url
                        // ToDo: handle IES / IESXML - LDT only for this time
                        let form = new FormData();
                        form.append('ldc', eventTarget);
                        form.append('filename', fileName);
                        axios
                          .post(`${APIURL}${fileUUID}/gldf/ldc/`, form, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          })
                          .then((resp) => {
                            console.log(resp);
                            if (resp.status === 200) {
                              changeHandlerFileInput(elementName, id, fileType, fileContentType, fileName, fileData, fileSize, '', true);
                              setSnackbarOpen(true);
                              setId('');
                              setEventTarget({});
                              setFileData('');
                              setFilename('');
                              setFileSize(0);
                              // setFileContentType(FileInputContentType[15]);
                            }
                          });
                      } else if (fileContentType === 'geo/l3d') {
                        // upload it to the server and get the url
                        // ToDo: handle G3D/M3D - L3D only for this time
                        let form = new FormData();
                        form.append('l3d', eventTarget);
                        form.append('filename', fileName);
                        axios
                          .post(`${APIURL}${fileUUID}/gldf/l3d/`, form, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          })
                          .then((resp) => {
                            console.log(resp);
                            if (resp.status === 200) {
                              changeHandlerFileInput(elementName, id, fileType, fileContentType, fileName, fileData, fileSize, '', true);
                              setSnackbarOpen(true);
                              setId('');
                              setEventTarget({});
                              setFileData('');
                              setFilename('');
                              setFileSize(0);
                              // setFileContentType(FileInputContentType[15]);
                            }
                          });
                      } else {
                        //ToDo: give different handler success message for base64 files
                        setSnackbarOpen(true);
                        setId('');
                        setEventTarget({});
                        setFileData('');
                        setFilename('');
                        setFileSize(0);
                        // setFileContentType(FileInputContentType[15]);
                        changeHandlerFileInput(elementName, id, fileType, fileContentType, fileName, fileData, fileSize, '', false);
                      }
                    } catch (e: any) {
                      setIdError(e.errors);
                    }
                  }
                  break;
                case EFileInputType.url:
                  try {
                    idSchema.validateSync({ data: id });
                    try {
                      setIdError([]);
                      urlSchema.validateSync({ data: url });
                      setUrlError([]);
                      changeHandlerFileInput(elementName, id, fileType, fileContentType, '', '', '', url, false);
                    } catch (e: any) {
                      setUrlError(e.errors);
                    }
                  } catch (e: any) {
                    setIdError(e.errors);
                  }
                  break;
                default:
                  break;
              }
            }}>
            Submit
          </Button>
        </FlexRow>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={1500}
          open={snackbarOpen}
          onClose={closeSnackbar}
          message="Successful upload!"
          key={'bottom' + 'right'}
        />
      </Box>
    </>
  );
};

export { FileInput, EFileInputType, TFileInputType };

import { DisplayElementTypes } from "../consts"
const filesConf = {
    blockName: "Files",
    blockClasses: ["fileClass-1", "fileClass-2"],
    blockList: [
        {
            displayElementName: "Files",
            displayElementType: DisplayElementTypes.Files,
            displayElementClasses: ["filesGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.Files.File"
        },
        
    ]
}

export default filesConf;
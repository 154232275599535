const APIURL = 'https://d3.relux.com/webapi/gldfapi/';
//const APIURL = 'https://127.0.0.1:8050/webapi/gldfapi/';
//import * as constants from './constants.json';
//const APIURL = (window as any).API_URL;
const L3DVIEWERURL = 'https://l3dviewer.relux.com';

enum DisplayElementTypes {
    PlainString,
    PlainNumber,
    LicenseKeys,
    ContactAddress,
    LocaleString,
    Variants,
    ProductSeries,
    HyperLink,
    Image,
    ProductMaintenance,
    DescriptiveAttributes,
    Variant,
    VariantDescriptiveAttributes,
    Range,
    Files,
    ControlGears,
    Equipments,
    Photometries,
    Emitters,
    Geometries,
    Spectrums,
    Sensors,
    LightSources,
    ValidationErrors
}

enum Validators {
    nonEmptyString,
    minLength,
    maxLength,
    exactLength,
    nonEmptyArray,
    anyNumber,
    integersOnly,
    range,
    nonNegativeNumber,
    nonNegativeOrZeroNumber,
}

const FileInputContentType = [
    'ldc/eulumdat',
    'ldc/ies',
    'ldc/iesxml',
    'image/png',
    'image/svg',
    'image/jpg',
    'geo/l3d',
    'geo/r3d',
    'geo/m3d',
    'document/pdf',
    'symbol/dxf',
    'symbol/svg',
    'sensor/sensxml',
    'sensor/sensldt',
    'spectrum/text',
    'other',
];

const CeilingTypes = [
    'Recessed',
    'SurfaceMounted',
    'Pendant'
]

const RecessedTypes = [
    'RectangularCutout',
    'CircularCutout'
]

const WallTypes = [
    'Recessed',
    'SurfaceMounted',
]

const GroundTypes = [
    'PoleTop',
    'PoleIntegrated',
    'FreeStanding',
    'SurfaceMounted',
    'Recessed'
]

export {
    DisplayElementTypes,
    Validators,
    FileInputContentType,
    CeilingTypes,
    RecessedTypes,
    WallTypes,
    GroundTypes,
    APIURL,
    L3DVIEWERURL
};

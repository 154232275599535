import * as React from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import * as queryString from 'query-string';
import Home from './Home';
import {validationErrorStyle} from "./css/theme";
import { APIURL } from "./consts";
import { GLDFContainer } from './components';
import {Button} from "@mui/material";
import buildDate from './consts/buildDate';

console.log(`[BOOT] GLDF Editor Build Date: ${buildDate.buildDate}`);

const AppContainer = () => {
  // ToDo: Add logic to handle default language
  // ToDo: Add Context for UI options (theme, language etc.)
  const parsedQueryString = queryString.parse(location.search);
  let lang = 'en';
  let fileUUID = 'c25e96c6fd213a694d7eac68050963d55066bbb456b6b5b472912dae';
  if (parsedQueryString?.lang) {
    if (parsedQueryString.lang !== null) {
      lang = parsedQueryString.lang as string;
    }
  }
  if (parsedQueryString?.uuid) {
    if (parsedQueryString.uuid !== null) {
      fileUUID = parsedQueryString.uuid as string;
    }
  }

  const [product, setProduct] = React.useState({});
  const [loadingStatus, setLoadingStatus] = React.useState({ loading: true, loadingError: '' });
  const [debugEnabled, setDebugEnabled] = React.useState(false);

  React.useEffect(() => {
    async function getData(cbSetProduct: Function, cbsetLoadingStatus: Function) {
      try {
        const response = await axios.get(`${APIURL}json/${fileUUID}/`);
        if (typeof response.data.error !== 'undefined') {
          cbsetLoadingStatus({ loading: false, loadingError: 'ERROR IN VALIDATION:' + JSON.stringify(response.data.errors) });
        } else {
          cbSetProduct(response.data.product);
          cbsetLoadingStatus({ loading: false, loadingError: '' });
        }
      } catch (error) {
        cbsetLoadingStatus({ loading: false, loadingError: 'ERROR' });
      }
    }
    getData(setProduct, setLoadingStatus);
  }, []);

  if (loadingStatus.loadingError !== '')
    return (
        <>
          <div style={validationErrorStyle}>
            {loadingStatus.loadingError}
            <br/>
          </div>
          <div>
            <Button
                sx={{height: '30px', marginLeft: '2rem'}}
                variant="outlined"
                size={'small'}>
              <Link to={`/`}>Home</Link>
            </Button>
          </div>
        </>
    );
  if (loadingStatus.loadingError === '' && loadingStatus.loading === true) return <div>Loading</div>;
  return (
    <>
      <GLDFContainer
        fileUUID={fileUUID}
        debugEnabled={debugEnabled}
        setDebugEnabled={setDebugEnabled}
        product={product}
        setProduct={setProduct}
        lang={lang}
      />
    </>
  );
};

render(
  <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="editor" element={<AppContainer />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('app')
);

import * as React from 'react';
import * as _ from 'lodash';
import { SelectList } from '..';
import { getPictureFileIds } from "../../../util";

const Image = ({
  elementName,
  elementData,
  debugElement,
  mode,
  product,
  path,
  changeAttribute,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div style={{ margin: '10px' }} key={`image-${element['@imageType']}`}>
              {`${element['@imageType']} - ${element['@fileId']}`}
              <div style={{ margin: '10px' }}>Pic</div>
              <SelectList
                mode={mode}
                availableListItems={getPictureFileIds(product)}
                product={product}
                path={`${path}[${idx}]['@fileId']`}
                key={`${elementName}-Picture`}
                classes="picture-class"
                elementName={`${elementName}-picture`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(product, `${path}[${idx}]['@fileId']`)}
              />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Image;

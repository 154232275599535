import * as React from "react";
import Mechanical from "./Mechanical";
import Electrical from "./Electrical";
import Emergency from "./Emergency";
import MountingAndAccessory from "./MountingAndAccessory";
import OperationsAndMaintenance from "./OperationsAndMaintenance";
import Marketing from "./Marketing";

import * as _ from "lodash";

const DescriptiveAttributes = ({
     mode,
     product,
     path,
     elementName,
     elementData,
     debugElement,
     validationErrors,
     setValidationErrorsHandler,
     unSetValidationErrorsHandler,
     blockSubConf,
     changeAttribute,
     deleteAttribute
 }: any) => {

    debugElement && console.log(`DATA[varDescrAttr] ${elementName}`, elementData, debugElement);

    if (elementData) {
        return (
            <>
                {blockSubConf === 'Mechanical' ?
                    <Mechanical
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Mechanical"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        elementData={_.get(elementData, "Mechanical")}
                        changeAttribute={changeAttribute}
                    /> : null}
                {blockSubConf === 'Electrical' ?
                    <Electrical
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Electrical"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        elementData={_.get(elementData, "Electrical")}
                        changeAttribute={changeAttribute}
                    /> : null}
                {blockSubConf === 'Emergency' ?
                    <Emergency
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Emergency"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        elementData={_.get(elementData, "Emergency")}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                    /> : null}
                {blockSubConf === 'MountingAndAccessory' ?
                    <MountingAndAccessory
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="MountingAndAccessory"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        elementData={_.get(elementData, "MountingAndAccessory")}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                    /> : null}
                {blockSubConf === 'Marketing' ?
                    <Marketing
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="Marketing"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        elementData={_.get(elementData, "Marketing")}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                    /> : null}
                {blockSubConf === 'OperationsAndMaintenance' ?
                    <OperationsAndMaintenance
                        mode={mode}
                        product={product}
                        path={path}
                        elementName="OperationsAndMaintenance"
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        elementData={_.get(elementData, "OperationsAndMaintenance")}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                    /> : null}
            </>
        );
    }

    return debugElement ? (
        <strong
            style={{color: "#f68f9c"}}
        >{`Element: ${elementName} could not be found`}</strong>
    ) : null;
};

export default DescriptiveAttributes;

import { object, string, number, array } from 'yup';
import { Validators } from '../consts';

export interface IValidationResponse {
  isValid: boolean;
  validationErrors: string[] | null;
}

const nonEmptyString = (data: string): IValidationResponse => {
  let schema = object({
    data: string().required('Required field.'),
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const minLength = (data: string, stringLength: number): IValidationResponse => {
  let schema = object({
    data: string().min(stringLength, `Text length must be at least ${stringLength} characters.`),
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const anyNumber = (data: number | string): IValidationResponse => {
  let schema = object({
    data: number().typeError('You must specify a number')
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const integersOnly = (data: number | string): IValidationResponse => {
  let schema = object({
    data: number()
        .typeError('You must specify an integer number')
        .integer('Value must be integer')
        .test('test: must be integer and positive', 'Value must be integer and positive.', (value) => typeof value !== 'undefined' && value >= 0)
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const nonNegativeNumber = (data: number | string): IValidationResponse => {
  let schema = object({
    data: number().typeError('You must specify a number').test('test: must be 0 or positive', 'Value must be 0 or positive.', (value) => typeof value !== 'undefined' && value >= 0),
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const nonNegativeOrZeroNumber = (data: number | string): IValidationResponse => {
  let schema = object({
    data: number().typeError('You must specify a number').moreThan(0, 'Value must be greater than zero.'),
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const range = (data: number | string, minValue: number, maxValue: number, isInteger: Boolean): IValidationResponse => {
  let schema;
  if (isInteger) {
    schema = object({
      data: number()
          .typeError('You must specify a number')
          .integer('Value must be an integer')
          .min(minValue, `Value must be at least ${minValue}`)
          .max(maxValue, `Value must be below ${maxValue}`)
          .test('test: must be in a range', `Value must be between ${minValue} and ${maxValue}`, (value) => typeof value !== 'undefined')
    });
  } else {
    schema = object({
      data: number()
          .typeError('You must specify a number')
          .min(minValue, `Value must be at least ${minValue}`)
          .max(maxValue, `Value must be below ${maxValue}`)
          .test('test: must be in a range', `Value must be between ${minValue} and ${maxValue}`, (value) => typeof value !== 'undefined')
    });
  }

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const nonEmptyArray = (data: Array<any> | string): IValidationResponse => {
  let schema = object({
    data: array().min(1, "Enter at least one element")
  });

  try {
    schema.validateSync({ data });
    return {
      isValid: true,
      validationErrors: [],
    };
  } catch (e: any) {
    return {
      isValid: false,
      validationErrors: e.errors,
    };
  }
};

const validateAll = (data: string, conf: any): IValidationResponse => {
  return conf
    .map((i: any) => {
      switch (i.validator) {
        case Validators.nonEmptyString:
          return nonEmptyString(data);
        case Validators.minLength:
          return minLength(data, i.minLength);
        case Validators.anyNumber:
          return anyNumber(data);
        case Validators.integersOnly:
          return integersOnly(data);
        case Validators.nonNegativeNumber:
          return nonNegativeNumber(data);
        case Validators.nonNegativeOrZeroNumber:
          return nonNegativeOrZeroNumber(data);
        case Validators.range:
          return range(data, i.minValue, i.maxValue, i.isInteger);
        case Validators.nonEmptyArray:
          return nonEmptyArray(data);
        default:
          return {
            isValid: false,
            validationErrors: [],
          };
      }
    })
    .reduce((previousValue: boolean, currentValue: boolean) => {
      return previousValue && currentValue;
    }, true);
};

export { nonEmptyString, minLength, anyNumber, range, integersOnly, nonNegativeNumber, validateAll, Validators };

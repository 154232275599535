import * as React from "react";
import { PlainString, PlainNumber, Range } from "..";
import * as _ from "lodash";
import {Validators} from "../../../consts";

const Voltage = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (typeof elementData !== "undefined") {
    return (
      <>
        {elementData.FixedVoltage && (
          <>
            <div style={{margin: '10px'}}>Fixed Voltage</div>
            <PlainNumber
              mode={mode}
              isFloat={true}
              product={product}
              path={`${path}.FixedVoltage`}
              key={`${elementName}-fixed-voltage`}
              classes="coltage-fixed-voltage-class"
              elementName={`${elementName}-FixedVoltage`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              validationConf={[
                {
                  validator: Validators.nonNegativeOrZeroNumber
                },
              ]}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.FixedVoltage`)}
            />
          </>
        )}
        <br />
        {elementData.VoltageRange && (
          <>
            <div style={{margin: '10px'}}>VoltageRange</div>
            <PlainNumber
              mode={mode}
              isFloat={true}
              product={product}
              path={`${path}.VoltageRange.Min`}
              key={`${elementName}-voltage-range-min`}
              classes="voltage-range-min-class"
              elementName={`${elementName}-VoltageRange-Min`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              validationConf={[
                {
                  validator: Validators.nonNegativeNumber
                },
              ]}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.VoltageRange.Min`)}
            />{" "}
            -
            <PlainNumber
              mode={mode}
              isFloat={true}
              product={product}
              path={`${path}.VoltageRange.Max`}
              key={`${elementName}-voltage-range-max`}
              classes="voltage-range-max-class"
              elementName={`${elementName}-VoltageRange-Max`}
              setValidationErrorsHandler={setValidationErrorsHandler}
              unSetValidationErrorsHandler={unSetValidationErrorsHandler}
              validationConf={[
                {
                  validator: Validators.nonNegativeOrZeroNumber
                },
              ]}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.VoltageRange.Max`)}
            />
          </>
        )}
        {elementData.Type && (
          <>
            <div style={{margin: '10px'}}>Type</div>
            <PlainString
              mode={mode}
              product={product}
              path={`${path}.Type`}
              key={`${elementName}-voltage-type`}
              classes="voltage-type-class"
              elementName={`${elementName}-Type`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.Type`)}
            />
          </>
        )}
        {elementData.Frequency && (
          <>
            <div style={{margin: '10px'}}>Type</div>
            <PlainString
              mode={mode}
              product={product}
              path={`${path}.Frequency`}
              key={`${elementName}-voltage-frequency`}
              classes="voltage-frequency-class"
              elementName={`${elementName}-Frequency`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(product, `${path}.Frequency`)}
            />
          </>
        )}
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default Voltage;

import * as React from 'react';
import { PlainNumber, PlainString, SelectList } from '..';
import { getControlGearIds, getLightSourceIds } from '../../../util';
import * as _ from 'lodash';
import { Validators } from '../../../consts';

const Equipments = ({ mode, product, path, elementName, elementData, validationErrors, setValidationErrors, debugElement, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  //ToDo: move up
  const setValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    if (tmpValidationErrors.indexOf(elementPath) < 0) {
      tmpValidationErrors.push(elementPath);
    }
    setValidationErrors(tmpValidationErrors);
  };

  const unSetValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    const index = tmpValidationErrors.indexOf(elementPath);
    if (index > -1) {
      _.pullAt(tmpValidationErrors, index);
      setValidationErrors(tmpValidationErrors);
    }
  };
  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div key={`equipments-${idx}`}>
              <PlainString
                mode={mode}
                product={product}
                path={`${path}[${idx}]['@id']`}
                key={`${elementName}-${idx}-id`}
                classes="element-id-class"
                elementName={`${elementName}-${idx}-id`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonEmptyString,
                  },
                ]}
                // Todo: make sure checks are in place in VariantInfo.tsx if we allow for edit
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={element['@id']}
              />
              <br />
              {element.LightSourceReference && (
                <>
                  <div style={{ margin: '10px' }}>Light Source Reference</div>
                  <SelectList
                    mode={mode}
                    availableListItems={getLightSourceIds(product)}
                    product={product}
                    path={`${path}[${idx}].LightSourceReference['@lightSourceId']`}
                    referencePrefix="lightSource"
                    key={`${elementName}-LightSourceReference`}
                    classes="equipment-LightSourceReference-class"
                    elementName={`${elementName}-Equipment-LightSourceReference`}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].LightSourceReference]['@lightSourceId']`)}
                  />
                  <br />
                </>
              )}

              {element.ControlGearReference && (
                <>
                  <div style={{ margin: '10px' }}>Control Gear Reference</div>
                  <SelectList
                    mode={mode}
                    availableListItems={getControlGearIds(product)}
                    product={product}
                    path={`${path}[${idx}].ControlGearReference['@controlGearId']`}
                    referencePrefix="controlGear"
                    key={`${elementName}-ControlGearReference`}
                    classes="equipment-ControlGearReference-class"
                    elementName={`${elementName}-Equipment-ControlGearReference`}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].ControlGearReference['@controlGearId']`)}
                  />
                  <br />
                </>
              )}

              <br />
              {element.RatedInputPower && (
                <>
                  <div style={{ margin: '10px' }}>Rated Input Power</div>
                  <PlainNumber
                    mode={mode}
                    product={product}
                    isFloat={true}
                    path={`${path}[${idx}].RatedInputPower`}
                    key={`${elementName}-rated-input-power`}
                    classes="equipment-rated-input-power-class"
                    elementName={`${elementName}-Equipment-RatedInputPower`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonNegativeNumber,
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].RatedInputPower`)}
                  />
                  <br />
                </>
              )}

              {element.RatedLuminousFlux && (
                <>
                  <div style={{ margin: '10px' }}>Rated Luminous Flux</div>
                  <PlainNumber
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}].RatedLuminousFlux`}
                    key={`${elementName}-rated-input-power`}
                    classes="equipment-rated-luminous-flux-class"
                    elementName={`${elementName}-Equipment-RatedLuminousFlux`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonNegativeNumber,
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].RatedLuminousFlux`)}
                  />
                  <br />
                </>
              )}
              {element.RatedLuminousFluxRGB && (
                <>
                  <div style={{ margin: '10px' }}>Rated Luminous Flux RGB</div>
                  <PlainNumber
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}].RatedLuminousFluxRGB`}
                    key={`${elementName}-RatedLuminousFluxRGB`}
                    classes="equipment-RatedLuminousFluxRGB-class"
                    elementName={`${elementName}-Equipment-RatedLuminousFluxRGB`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonNegativeNumber,
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].RatedLuminousFluxRGB`)}
                  />
                  <br />
                </>
              )}

              {element.EmergencyBallastLumenFactor && (
                <>
                  <div style={{ margin: '10px' }}>Emergency Ballast Lumen Factor</div>
                  <PlainNumber
                    mode={mode}
                    isFloat={true}
                    product={product}
                    path={`${path}[${idx}].EmergencyBallastLumenFactor`}
                    key={`${elementName}-EmergencyBallastLumenFactor`}
                    classes="equipment-EmergencyBallastLumenFactor-class"
                    elementName={`${elementName}-Equipment-EmergencyBallastLumenFactor`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.range,
                        minValue: 0,
                        maxValue: 1,
                        isInteger: false,
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].EmergencyBallastLumenFactor`)}
                  />
                  <br />
                </>
              )}
              {element.EmergencyRatedLuminousFlux && (
                <>
                  <div style={{ margin: '10px' }}>Emergency Rated Luminous Flux</div>
                  <PlainNumber
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}].EmergencyRatedLuminousFlux`}
                    key={`${elementName}-EmergencyRatedLuminousFlux`}
                    classes="equipment-EmergencyRatedLuminousFlux-class"
                    elementName={`${elementName}-Equipment-EmergencyRatedLuminousFlux`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonNegativeNumber,
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].EmergencyRatedLuminousFlux`)}
                  />
                </>
              )}
              <hr />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Equipments;

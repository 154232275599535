import * as React from 'react';
import * as _ from 'lodash';
import { EmittersFixedLightEmitter, EmittersSensor, PlainString } from '..';
import { Validators } from '../../../consts';

const Emitters = ({ mode, product, path, elementName, elementData, validationErrors, setValidationErrors, changeAttribute, debugElement }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  //ToDo: move up
  const setValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    if (tmpValidationErrors.indexOf(elementPath) < 0) {
      tmpValidationErrors.push(elementPath);
    }
    setValidationErrors(tmpValidationErrors);
  };

  const unSetValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    const index = tmpValidationErrors.indexOf(elementPath);
    if (index > -1) {
      _.pullAt(tmpValidationErrors, index);
      setValidationErrors(tmpValidationErrors);
    }
  };
  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div key={`equipments-${idx}`}>
              <div style={{ margin: '10px' }}>Id</div>
              <PlainString
                mode={mode}
                // ToDo: https://relux.atlassian.net/browse/OJ1323-64, that's why we keep it readonly for now
                readonly={true}
                product={product}
                path={`${path}[${idx}]['@id']`}
                key={`${elementName}-${idx}-ID`}
                classes="lightEmitter-id-class"
                elementName={`${elementName}-${idx}-id`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonEmptyString,
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={element['@id']}
              />
              {element.FixedLightEmitter && (
                <>
                  <div>
                    <EmittersFixedLightEmitter
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].FixedLightEmitter`}
                      key={`${elementName}-FixedLightEmitter`}
                      classes="emitter-FixedLightEmitter-class"
                      elementName={`${elementName}-Emitter-FixedLightEmitter`}
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].FixedLightEmitter`)}
                    />
                  </div>
                  <br />
                </>
              )}
              {element.Sensor && (
                <>
                  <div>
                    <EmittersSensor
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}].Sensor`}
                      key={`${elementName}-Sensor`}
                      classes="emitter-Sensor-class"
                      elementName={`${elementName}-Emitter-Sensor`}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={_.get(product, `${path}[${idx}].Sensor`)}
                    />
                  </div>
                </>
              )}
              <hr />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Emitters;

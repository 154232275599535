import * as React from "react";
import classNames from "classnames";
import { PlainString } from "..";
import {blockSubGrouping} from "../../../css/theme";
import {Validators} from "../../../consts";

const EnergyLabels = ({
  mode,
  product,
  path,
  elementName,
  classes,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  if (Array.isArray(elementData)) {
    const plainStringClassNames = classNames("energy-labels-array", classes);
    return (
      <span className={plainStringClassNames}>
        {elementData.map((element: any, idx: number) => {
          return (
              <div style={blockSubGrouping} key={`${elementName}-${idx}`}>
                <div style={{margin: '10px'}}>Label</div>
                <PlainString
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}]['$']`}
                    key={`${elementName}-${idx}-label`}
                    classes="energy-labels-label-class"
                    elementName={`${elementName}-${idx}-Label`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonEmptyString
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={element['$']}
                />
                <div style={{margin: '10px'}}>Region</div>
                <PlainString
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}]['@region']`}
                    key={`${elementName}-${idx}-region`}
                    classes="energy-labels-region-class"
                    elementName={`${elementName}-${idx}-Region`}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={element['@region']}
                />
              </div>
          );
        })}
      </span>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default EnergyLabels;

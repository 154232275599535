import * as React from 'react';
import { Button } from '@mui/material';
import { PlainNumber } from '..';
import { Validators } from '../../../util/validators';
import { blockSubGrouping } from '../../../css/theme';
import * as _ from 'lodash';

const PeakDivergence = ({
  mode,
  isFloat,
  path,
  product,
  elementName,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`REFERENCE DATA ${elementName}`, elementData, debugElement);
  if (!_.isNil(elementData)) {
    return (
      <>
        <div style={blockSubGrouping}>
          <div style={{ margin: '10px' }}>C0-C180</div>
          <PlainNumber
            mode={mode}
            isFloat={isFloat}
            product={product}
            path={`${path}['C0-C180']`}
            key={`${elementName}-C0-C180`}
            classes="peakdivergence-C0-C180-class"
            elementName={`${elementName}-C0-C180`}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            validationConf={[
              {
                validator: Validators.nonNegativeNumber,
              },
            ]}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData['C0-C180']}
          />
          <br />
          <div style={{ margin: '10px' }}>C90-270</div>
          <PlainNumber
            mode={mode}
            isFloat={isFloat}
            product={product}
            path={`${path}['C90-C270']`}
            key={`${elementName}-C90-C270`}
            classes="peakDivergence-C90-C27-class"
            elementName={`${elementName}-C90-C270`}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            validationConf={[
              {
                validator: Validators.nonNegativeNumber,
              },
            ]}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData['C90-C270']}
          />
          <br />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{
            border: '#8c6800 1px solid',
            color: '#fff',
            backgroundColor: '#ffbd002e',
            lineHeight: '0',
          }}
          onClick={() => {
            changeAttribute(path, { 'C0-C180': 1.01, 'C90-C270': 2.01 }, true, false);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default PeakDivergence;

import * as React from 'react';
import * as _ from 'lodash';
import { PlainNumber, PlainString, SelectList } from '../../';
import { GroundTypes, RecessedTypes } from '../../../../consts/index';
import RecessedOverrideFunction from '../common';

const GroundOverrideFunction = (product: any, pathToOverride: any, data: any) => {
  switch (data) {
    case 'PoleTop':
      return {
        PoleTop: {
          '@poleHeight': 900,
        },
      };
    case 'PoleIntegrated': {
      return {
        PoleIntegrated: {
          '@poleHeight': 950,
        },
      };
    }
    case 'FreeStanding':
      return {
        FreeStanding: null,
      };
    case 'SurfaceMounted':
      return {
        SurfaceMounted: null,
      };
    case 'Recessed':
      return {
        Recessed: {
          '@recessedDepth': 15,
          CircularCutout: {
            Diameter: 100,
            Depth: 30,
          },
        },
      };
    default:
      break;
  }
  return null;
};

const Ground = ({ mode, product, path, elementName, elementData, debugElement, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  return (
    <>
      <SelectList
        mode={mode}
        availableListItems={GroundTypes}
        product={product}
        path={`${path}`}
        key={`${elementName}-Ceiling`}
        classes="mountings-ground"
        elementName={`${elementName}-ceiling`}
        debugElement={debugElement}
        defaultValue="Recessed"
        changeAttribute={changeAttribute}
        preProcessFunction={GroundOverrideFunction}
        pathToOverride={`${path}`}
        elementData={_.keys(_.get(product, `${path}`))[0]}
      />
      {elementData.PoleTop && (
        <>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.PoleTop['@poleHeight']`}
            key={`${elementName}-poletop-poleheight`}
            classes="mountings-ground-poletop-poleheight"
            elementName={`${elementName}-PoleTop-PoleHeight`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.PoleTop['@poleHeight']}
          />
        </>
      )}
      {elementData.PoleIntegrated && (
        <>
          <div>Pendant Length</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.PoleIntegrated['@poleHeight']`}
            key={`${elementName}-poleintegrated-poleheight`}
            classes="mountings-ground-poleintegrated-poleheight"
            elementName={`${elementName}-PoleIntegrated-PoleHeight`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.PoleIntegrated['@poleHeight']}
          />
        </>
      )}
      {elementData.FreeStanding && (
        <>
          <PlainString
            readonly
            mode={mode}
            product={product}
            path={`${path}.FreeStanding`}
            key={`${elementName}-FreeStanding`}
            classes="mountings-ground-freestanding"
            elementName={`${elementName}`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={_.keys(_.get(product, `${path}`))[0]}
          />
        </>
      )}
      {elementData.SurfaceMounted && (
        <>
          <PlainString
            readonly
            mode={mode}
            product={product}
            path={`${path}.SurfaceMounted`}
            key={`${elementName}-SurfaceMounted`}
            classes="mountings-ground-surface-mounted"
            elementName={`${elementName}`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={_.keys(_.get(product, `${path}`))[0]}
          />
        </>
      )}
      {elementData.Recessed && (
        <>
          <SelectList
            mode={mode}
            availableListItems={RecessedTypes}
            product={product}
            path={`${path}.Recessed`}
            key={`${elementName}-Ground-Recessed`}
            classes="mountings-ground-"
            elementName={`${elementName}-ceiling`}
            debugElement={debugElement}
            defaultValue="Recessed"
            preProcessFunction={RecessedOverrideFunction}
            pathToOverride={`${path}.Recessed`}
            changeAttribute={changeAttribute}
            elementData={_.keys(_.get(product, `${path}.Recessed`))[1]}
          />
          <div>Recessed Depth</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed['@recessedDepth']`}
            key={`${elementName}-ground-recessed-recessedDepth`}
            classes="recessed-recessedDepth"
            elementName={`${elementName}-Recessed-RecessedDepth`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed['@recessedDepth']}
          />
        </>
      )}
      {elementData.Recessed?.RectangularCutout && (
        <>
          <div>Depth</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.RectangularCutout.Depth`}
            key={`${elementName}-ground-recessed-RectangularCutout-Depth`}
            classes="recessed-rectangularCutout-depth"
            elementName={`${elementName}-Recessed-RectangularCutout-Depth`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.RectangularCutout.Depth}
          />
          <div>Width</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.RectangularCutout.Width`}
            key={`${elementName}-ground-recessed-RectangularCutout-Width`}
            classes="recessed-rectangularCutout-Width"
            elementName={`${elementName}-Recessed-RectangularCutout-Width`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.RectangularCutout.Width}
          />
          <div>Length</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.RectangularCutout.Length`}
            key={`${elementName}-ground-recessed-RectangularCutout-Length`}
            classes="recessed-rectangularCutout-Length"
            elementName={`${elementName}-Recessed-RectangularCutout-Length`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.RectangularCutout.Length}
          />
        </>
      )}
      {elementData.Recessed?.CircularCutout && (
        <>
          <div>Diameter</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.CircularCutout.Diameter`}
            key={`${elementName}-ground-recessed-CircularCutout-Diameter`}
            classes="recessed-CircularCutout-diameter"
            elementName={`${elementName}-Recessed-CircularCutout-Diameter`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.CircularCutout.Diameter}
          />
          <div>Depth</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.CircularCutout.Depth`}
            key={`${elementName}-recessed-CircularCutout-Depth`}
            classes="recessed-CircularCutout-depth"
            elementName={`${elementName}-Recessed-CircularCutout-Depth`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.CircularCutout.Depth}
          />
        </>
      )}
    </>
  );

  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Ground;

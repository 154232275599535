import * as React from 'react';
import RecessedOverrideFunction from '../common';
import { PlainNumber, SelectList } from '../../';
import { RecessedTypes, WallTypes } from '../../../../consts/index';
import * as _ from 'lodash';

const WallOverrideFunction = (product: any, pathToOverride: any, data: any) => {
  switch (data) {
    case 'SurfaceMounted': {
      return {
        '@mountingHeight': 1750,
        SurfaceMounted: null,
      };
    }
    case 'Recessed':
      return {
        '@mountingHeight': 1750,
        Recessed: {
          '@recessedDepth': 15,
          CircularCutout: {
            Diameter: 100,
            Depth: 30,
          },
        },
      };
    default:
      break;
  }
  return null;
};

const Wall = ({ mode, product, path, elementName, elementData, debugElement, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  return (
    <>
      <div style={{ margin: '10px' }}>Mounting Height</div>
      <PlainNumber
        mode={mode}
        product={product}
        path={`${path}['@mountingHeight']`}
        key={`${elementName}-mounting-Height`}
        classes="mountingHeight"
        elementName={`${elementName}-mountingHeight`}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={elementData['@mountingHeight']}
      />
      <SelectList
        mode={mode}
        availableListItems={WallTypes}
        product={product}
        path={`${path}`}
        key={`${elementName}-Wall`}
        classes="wall-class"
        elementName={`${elementName}-wall`}
        debugElement={debugElement}
        defaultValue="Recessed"
        preProcessFunction={WallOverrideFunction}
        pathToOverride={`${path}`}
        changeAttribute={changeAttribute}
        elementData={Object.keys(elementData)[1]}
      />
      {elementData.Recessed && (
        <>
          <SelectList
            mode={mode}
            availableListItems={RecessedTypes}
            product={product}
            path={`${path}.Recessed`}
            key={`${elementName}-Ceiling`}
            classes="ceiling-class"
            elementName={`${elementName}-ceiling`}
            debugElement={debugElement}
            defaultValue="Recessed"
            preProcessFunction={RecessedOverrideFunction}
            pathToOverride={`${path}.Recessed`}
            changeAttribute={changeAttribute}
            elementData={_.keys(_.get(product, `${path}.Recessed`))[1]}
          />
          <div>Recessed Depth</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed['@recessedDepth']`}
            key={`${elementName}-recessed-recessedDepth`}
            classes="recessed-recessedDepth"
            elementName={`${elementName}-Recessed-RecessedDepth`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed['@recessedDepth']}
          />
        </>
      )}
      {elementData.Recessed?.RectangularCutout && (
        <>
          <div>Depth</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.RectangularCutout.Depth`}
            key={`${elementName}-recessed-rectangularcutout-depth`}
            classes="recessed-rectangularCutout-depth"
            elementName={`${elementName}-Recessed-RectangularCutout-Depth`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.RectangularCutout.Depth}
          />
          <div>Width</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.RectangularCutout.Width`}
            key={`${elementName}-recessed-rectangularcutout-Width`}
            classes="recessed-rectangularCutout-Width"
            elementName={`${elementName}-Recessed-RectangularCutout-Width`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.RectangularCutout.Width}
          />
          <div>Length</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.RectangularCutout.Length`}
            key={`${elementName}-recessed-rectangularcutout-Length`}
            classes="recessed-rectangularCutout-Length"
            elementName={`${elementName}-Recessed-RectangularCutout-Length`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.RectangularCutout.Length}
          />
        </>
      )}
      {elementData.Recessed?.CircularCutout && (
        <>
          <div>Diameter</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.CircularCutout.Diameter`}
            key={`${elementName}-recessed-CircularCutout-Diameter`}
            classes="recessed-CircularCutout-diameter"
            elementName={`${elementName}-Recessed-CircularCutout-Diameter`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.CircularCutout.Diameter}
          />
          <div>Depth</div>
          <PlainNumber
            mode={mode}
            product={product}
            path={`${path}.Recessed.CircularCutout.Depth`}
            key={`${elementName}-recessed-CircularCutout-Depth`}
            classes="recessed-CircularCutout-depth"
            elementName={`${elementName}-Recessed-CircularCutout-Depth`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={elementData.Recessed.CircularCutout.Depth}
          />
        </>
      )}
    </>
  );

  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Wall;

import * as React from "react";
import { LocaleString, HyperLink } from "..";
import * as _ from "lodash";

const ProductSeries = ({mode, product, path, elementName, elementData, debugElement, changeAttribute}: any) => {
    debugElement && console.log(`DATA ${elementName}`, elementData, debugElement)
    if (Array.isArray(elementData)) {
        return (<>{elementData.map((element: any, idx: number) => {
            return (<div key={`productSerie-${idx}`}>
                <LocaleString
                    mode={mode}
                    path={`${path}[${idx}].Name.Locale`}
                    classes={element.displayElementClasses}
                    elementName="Name"
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].Name.Locale`)}
                />
                <HyperLink
                    mode={mode}
                    path={`${path}[${idx}].Hyperlinks.Hyperlink`}
                    classes={element.displayElementClasses}
                    elementName="HyperLinks"
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].Hyperlinks.Hyperlink`)}
                />
            </div>)
        })}
        </>)
    }
    return (debugElement? <strong style={{color: "#f68f9c"}}>{`Element: ${elementName} could not be found`}</strong>: null);
}

export default ProductSeries;
import * as React from "react";

import * as _ from "lodash";
import { StringArray, PlainNumber, NumberArray, Range } from "../..";
import {blockTitleStyle} from "../../../../css/theme";
import {Validators} from "../../../../consts";

const OperationsAndMaintenance = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  debugElement,
  changeAttribute,
  deleteAttribute
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (elementData) {
    return (
      <>
        <h3 style={blockTitleStyle}>Operations and Maintenance</h3>
        <div style={{margin: '10px'}}>Useful Lifetimes</div>
        <StringArray
          mode={mode}
          product={product}
          path={`${path}.OperationsAndMaintenance.UsefulLifeTimes.UsefulLife`}
          elementName="UsefulLifeTimes"
          validationErrors={validationErrors}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          validationConf={[
              {
                  validator: Validators.nonEmptyString
              },
          ]}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          deleteAttribute={deleteAttribute}
          elementData={_.get(
            product,
            `${path}.OperationsAndMaintenance.UsefulLifeTimes.UsefulLife`
          )}
        />
        <div style={{margin: '10px'}}>Median Useful Lifetimes</div>
        <StringArray
          mode={mode}
          product={product}
          path={`${path}.OperationsAndMaintenance.MedianUsefulLifeTimes.MedianUsefulLife`}
          elementName="MedianUsefulLifeTimes"
          validationErrors={validationErrors}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          validationConf={[
              {
                  validator: Validators.nonEmptyString
              },
          ]}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          deleteAttribute={deleteAttribute}
          elementData={_.get(
            product,
            `${path}.OperationsAndMaintenance.MedianUsefulLifeTimes.MedianUsefulLife`
          )}
        />
        <div style={{ margin: '10px' }}>Operating Temperature</div>
        <Range
          mode={mode}
          product={product}
          path={`${path}.OperationsAndMaintenance.OperatingTemperature`}
          elementName="OperatingTemperature"
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.OperationsAndMaintenance.OperatingTemperature`)}
        />
        <div style={{ margin: '10px' }}>Ambient Temperature</div>
        <Range
          mode={mode}
          product={product}
          path={`${path}.OperationsAndMaintenance.AmbientTemperature`}
          elementName="AmbientTemperature"
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.OperationsAndMaintenance.AmbientTemperature`)}
        />
        <div style={{margin: '10px'}}>Rated Ambient Temperature</div>
        <PlainNumber
          mode={mode}
          product={product}
          path={`${path}.OperationsAndMaintenance.RatedAmbientTemperature`}
          elementName="RatedAmbientTemperature"
          validationConf={[
              {
                  validator: Validators.integersOnly
              },
          ]}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(
            product,
            `${path}.OperationsAndMaintenance.RatedAmbientTemperature`
          )}
        />
        {/* ADD ATEX */}
        <div style={{margin: '10px'}}>Acoustic Absorption Rates</div>
        <NumberArray
          mode={mode}
          product={product}
          isFloat={true}
          path={`${path}.OperationsAndMaintenance.AcousticAbsorptionRates.AbsorptionRate`}
          elementName="AcousticAbsorptionRates"
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          elementSelectorName="hertz"
          elementSelectorType="integer"
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          deleteAttribute={deleteAttribute}
          elementData={_.get(
            product,
            `${path}.OperationsAndMaintenance.AcousticAbsorptionRates.AbsorptionRate`
          )}
        />
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default OperationsAndMaintenance;

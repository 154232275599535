import { DisplayElementTypes } from "../consts"
import { Validators } from "../consts";

const productMetaDataDefinitionsConf = {
    blockName: "productMetaDataDefinitions",
    blockClasses: ["productMetaDataDefinitionsClass-1", "productMetaDataDefinitionsClass-2"],
    blockList: [
        {
            displayElementName: "ProductNumber",
            displayElementType: DisplayElementTypes.LocaleString,
            displayElementClasses: ["productNumberClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.ProductNumber.Locale",
            validationConf: [
                {
                    validator: Validators.nonEmptyString
                },
            ]

        },
        {
            displayElementName: "Name",
            displayElementType: DisplayElementTypes.LocaleString,
            displayElementClasses: ["productNameClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.Name.Locale",
            validationConf: [
                {
                    validator: Validators.nonEmptyString
                },
            ]
        },    
        {
            displayElementName: "Description",
            displayElementType: DisplayElementTypes.LocaleString,
            displayElementClasses: ["descriptionClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.Description.Locale",
        },            
        {
            displayElementName: "TenderText",
            displayElementType: DisplayElementTypes.LocaleString,
            displayElementClasses: ["tenderTextClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.TenderText.Locale"
        },
        {
            displayElementName: "ProductSeries",
            displayElementType: DisplayElementTypes.ProductSeries,
            displayElementClasses: ["productSeriesClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.ProductSeries.ProductSerie"
        },
        {
            displayElementName: "Pictures",
            displayElementType: DisplayElementTypes.Image,
            displayElementClasses: ["picturesClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.Pictures.Image"
        },
        {
            displayElementName: "ProductMaintenance",
            displayElementType: DisplayElementTypes.ProductMaintenance,
            displayElementClasses: ["productMaintenanceClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.Maintenance"
        }
    ]
}

export default productMetaDataDefinitionsConf;
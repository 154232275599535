import * as React from 'react';
import classNames from 'classnames';
import { TextField, Button } from '@mui/material';
import { validateAll } from '../../../util';
import { errorsList } from '../../../css/theme';
import './style.scss';
import { isNil } from 'lodash';

const PlainString = ({
  path,
  elementName,
  classes,
  elementData,
  debugElement,
  mode,
  readonly,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  validationConf,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  let initialValidationState = false;
  if (Array.isArray(validationErrors) && validationErrors.indexOf(path) > -1) initialValidationState = true;

  const [validation, setValidation] = React.useState(initialValidationState);
  const [localValidationErrors, setLocalValidationErrors] = React.useState<string[]>([]);

  const changeHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isNil(validationConf) && typeof(elementData)!=="undefined") {
      const { isValid, validationErrors } = validateAll(e.target.value, validationConf);
      if (!isValid) {
        setValidation(true);
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrors as string[]);
      } else {
        setValidation(false);
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
    changeAttribute(path, e.target.value, false);
    debugElement && console.log(this);
  };

  React.useEffect(() => {
    if (!isNil(validationConf) && typeof elementData !== 'undefined') {
      const { isValid, validationErrors: validationErrorsArray } = validateAll(elementData, validationConf);
      if (!isValid) {
        setValidation(true);
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrorsArray as string[]);
      } else {
        setValidation(false);
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
  }, []);

  if (!isNil(elementData)) {
    const plainStringClassNames = classNames('plain-string', classes);
    return (
      <>
        {mode === 'displayMode' || (typeof readonly !== 'undefined' && readonly) || !mode ? (
          <>
            {localValidationErrors.length > 0 && <div style={errorsList}>{localValidationErrors.join('<br/>')}</div>}
            <TextField fullWidth={true} variant="filled" disabled error={validation} style={{ color: 'white' }} size={'small'} value={elementData} />
          </>
        ) : (
          <>
            {localValidationErrors.length > 0 && <div style={errorsList}>{localValidationErrors.join('<br/>')}</div>}
            <TextField
              fullWidth={true}
              variant="filled"
              size={'small'}
              value={elementData}
              error={validation}
              onChange={(e) => {
                changeHandler(e);
              }}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0'}}
          onClick={() => {
            changeAttribute(path, '_empty_', true, false);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default PlainString;

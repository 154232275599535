import { DisplayElementTypes } from "../consts"

//ToDo: Support ChangeableLightSources
const lightSourcesConf = {
    blockName: "LightSources",
    blockClasses: ["lightSourcesClass-1", "lightSourcesClass-2"],
    blockList: [
        {
            displayElementName: "LightSources",
            displayElementType: DisplayElementTypes.LightSources,
            displayElementClasses: ["LightSourcesClassGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.LightSources.FixedLightSource"
        },
        
    ]
}

export default lightSourcesConf;
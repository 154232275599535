import * as React from 'react';
import classNames from 'classnames';
import { PlainNumber, PlainString } from '..';
import { Button } from '@mui/material';
import { blockSubGrouping } from '../../../css/theme';
import { Validators } from '../../../consts';
import * as _ from 'lodash';

const NumberArray = ({
  mode,
  product,
  isFloat,
  path,
  elementName,
  classes,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  elementSelectorName,
  elementSelectorType,
  changeAttribute,
  deleteAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, elementSelectorName, debugElement);

  if (Array.isArray(elementData)) {
    const plainStringClassNames = classNames('number-array', classes);
    return (
      <span className={plainStringClassNames}>
        {elementData.map((element: any, idx: number) => {
          return (
            <div style={blockSubGrouping} key={`${elementName}-${idx}`}>
              {
                <span>
                  {elementSelectorName}
                  {!elementSelectorType && (
                    <PlainString
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}]['@${elementSelectorName}']`}
                      elementName="elementSelectorName"
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.nonEmptyString,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={element[`@${elementSelectorName}`]}
                    />
                  )}
                  {elementSelectorType && (
                    <PlainNumber
                      mode={mode}
                      isFloat={elementSelectorType === 'float' ? true : false}
                      product={product}
                      path={`${path}[${idx}]['@${elementSelectorName}']`}
                      elementName="elementSelectorName"
                      setValidationErrorsHandler={setValidationErrorsHandler}
                      unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                      validationConf={[
                        {
                          validator: Validators.nonNegativeNumber,
                        },
                      ]}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={element[`@${elementSelectorName}`]}
                    />
                  )}
                  Value
                  <PlainNumber
                    mode={mode}
                    isFloat={isFloat}
                    product={product}
                    path={`${path}[${idx}]['$']`}
                    elementName="elementValue"
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonNegativeNumber,
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={element['$']}
                  />
                  <Button
                    sx={{ border: '#ff0000 1px solid', width: '99px', color: '#fff', backgroundColor: '#ff00003b', lineHeight: '0' }}
                    onClick={() => {
                      console.log('handle delete', path, idx);
                      deleteAttribute(path, true, idx);
                    }}>
                    Delete
                  </Button>
                </span>
              }
            </div>
          );
        })}
        <Button
          sx={{ border: '#00ff45 1px solid', marginTop: '.5rem', color: '#fff', backgroundColor: '#15ff0021', lineHeight: '0' }}
          onClick={() => {
            const updatedData = _.cloneDeep(elementData);
            updatedData.push({ $: isFloat?0.001:0, [`@${elementSelectorName}`]: elementSelectorType ? 0 : '_empty_selector_name_' });
            changeAttribute(path, updatedData, false, false);
          }}>
          ADD ANOTHER
        </Button>
      </span>
    );
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0' }}
          onClick={() => {
            changeAttribute(path, [{ $: isFloat?0.001:0, [`@${elementSelectorName}`]: elementSelectorType ? 0 : '_empty_selector_name_' }], true, true);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default NumberArray;

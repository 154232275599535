import * as React from "react";
import { Button } from "@mui/material";
import { LocaleString, Mountings, PlainString, SelectList } from "..";
import {
  getGeometryIds,
  getEmitterIds,
  getVariantEmitters,
} from "../../../util";
import {blockFileRefGrouping, blockSubGrouping} from "../../../css/theme";
import * as _ from "lodash";

const Variant = ({
  mode,
  path,
  product,
  elementName,
  elementData,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement &&
    console.log(`REFERENCE DATA ${elementName}`, elementData, debugElement);
  const emitterIds = getEmitterIds(product);
  if (elementData) {
    //ToDo: support also other cases (fix getVariantEmitters)
    const variantEmitters = getVariantEmitters(elementData);
    return (
      <>
        <div>
          <h3> Edit Variant </h3>
          <div style={blockSubGrouping}>
            <div style={{ margin: "10px" }}>Variant Name</div>
            <LocaleString
              mode={mode}
              path={`${path}.Name.Locale`}
              classes="variant-name"
              elementName={`${elementName}-Variant Name`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(elementData, `Name.Locale`)}
            />
          </div>
          <div style={blockSubGrouping}>
            <div style={{ margin: "10px" }}>Description</div>
            <LocaleString
              mode={mode}
              path={`${path}.Description.Locale`}
              classes="variant-description"
              elementName={`${elementName}-LocaleString`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(elementData, `Description.Locale`)}
            />
          </div>
          <div style={{ margin: "10px" }}>GTIN</div>
          <PlainString
            mode={mode}
            path={`${path}.GTIN`}
            classes={["variant-plain-string", "variant-plain-string-gtin"]}
            elementName={`${elementName}-GTIN`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={_.get(elementData, `GTIN`)}
          />
          <div style={blockSubGrouping}>
            <div style={{ margin: "10px" }}>Mountings</div>

            <div style={{ margin: "10px" }}>Ceiling</div>
            {!_.isNil(_.get(elementData, "Mountings.Ceiling")) ? (
              <Mountings
                mode={mode}
                mountingType={"Ceiling"}
                product={product}
                path={`${path}.Mountings.Ceiling`}
                classes={["variant-plain-string", "variant-plain-string-gtin"]}
                elementName={`${elementName}-GTIN`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(elementData, `Mountings.Ceiling`)}
              />
            ) : (
              <>
                <Button
                  sx={{
                    border: "#8c6800 1px solid",
                    color: "#fff",
                    backgroundColor: "#ffbd002e",
                    lineHeight: "0",
                  }}
                  onClick={() => {
                    if (_.get(product, `${path}.Mountings`)) {
                      changeAttribute(
                        `${path}.Mountings.Ceiling`,
                        {
                          SurfaceMounted: null,
                        },
                        true,
                        false
                      );
                    } else {
                      changeAttribute(
                        `${path}.Mountings`,
                        {
                          Ceiling: {
                            SurfaceMounted: null,
                          },
                        },
                        true,
                        false
                      );
                    }
                  }}
                >
                  ADD
                </Button>
              </>
            )}
            <hr />
            <div style={{ margin: "10px" }}>Wall</div>
            {!_.isNil(_.get(elementData, "Mountings.Wall")) ? (
              <Mountings
                mode={mode}
                mountingType={"Wall"}
                product={product}
                path={`${path}.Mountings.Wall`}
                classes={["variant-plain-string", "variant-plain-string-gtin"]}
                elementName={`${elementName}-GTIN`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(elementData, `Mountings.Wall`)}
              />
            ) : (
              <>
                <Button
                  sx={{
                    border: "#8c6800 1px solid",
                    color: "#fff",
                    backgroundColor: "#ffbd002e",
                    lineHeight: "0",
                  }}
                  onClick={() => {
                    if (_.get(product, `${path}.Mountings`)) {
                      changeAttribute(
                        `${path}.Mountings.Wall`,
                        {
                          "@mountingHeight": 1750,
                          Recessed: {
                            "@recessedDepth": 15,
                            CircularCutout: {
                              Diameter: 100,
                              Depth: 30,
                            },
                          },
                        },
                        true,
                        false
                      );
                    } else {
                      changeAttribute(
                        `${path}.Mountings`,
                        {
                          Wall: {
                            "@mountingHeight": 1750,
                            Recessed: {
                              "@recessedDepth": 15,
                              CircularCutout: {
                                Diameter: 100,
                                Depth: 30,
                              },
                            },
                          },
                        },
                        true,
                        false
                      );
                    }
                  }}
                >
                  ADD
                </Button>
              </>
            )}
            <hr />
            <div style={{ margin: "10px" }}>WorkingPlane</div>
            {!_.isNil(_.get(elementData, "Mountings.WorkingPlane")) ? (
              <Mountings
                mode={mode}
                mountingType={"WorkingPlane"}
                product={product}
                path={`${path}.Mountings.WorkingPlane`}
                classes={["variant-plain-string", "variant-plain-string-gtin"]}
                elementName={`${elementName}-GTIN`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(elementData, `Mountings.WorkingPlane`)}
              />
            ) : (
              <>
                <Button
                  sx={{
                    border: "#8c6800 1px solid",
                    color: "#fff",
                    backgroundColor: "#ffbd002e",
                    lineHeight: "0",
                  }}
                  onClick={() => {
                    if (_.get(product, `${path}.Mountings`)) {
                      changeAttribute(
                        `${path}.Mountings.WorkingPlane`,
                        {
                          FreeStanding: null,
                        },
                        true,
                        false
                      );
                    } else {
                      changeAttribute(
                        `${path}.Mountings`,
                        {
                          WorkingPlane: {
                            FreeStanding: null,
                          },
                        },
                        true,
                        false
                      );
                    }
                  }}
                >
                  ADD
                </Button>
              </>
            )}
            <hr />
            <div style={{ margin: "10px" }}>Ground</div>
            {!_.isNil(_.get(elementData, "Mountings.Ground")) ? (
              <Mountings
                mode={mode}
                mountingType={"Ground"}
                product={product}
                path={`${path}.Mountings.Ground`}
                classes={["variant-plain-string", "variant-plain-string-gtin"]}
                elementName={`${elementName}-GTIN`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(elementData, `Mountings.Ground`)}
              />
            ) : (
              <>
                <Button
                  sx={{
                    border: "#8c6800 1px solid",
                    color: "#fff",
                    backgroundColor: "#ffbd002e",
                    lineHeight: "0",
                  }}
                  onClick={() => {
                    if (_.get(product, `${path}.Mountings`)) {
                      changeAttribute(
                        `${path}.Mountings.Ground`,
                        {
                          FreeStanding: null,
                        },
                        true,
                        false
                      );
                    } else {
                      changeAttribute(
                        `${path}.Mountings`,
                        {
                          Ground: {
                            FreeStanding: null,
                          },
                        },
                        true,
                        false
                      );
                    }
                  }}
                >
                  ADD
                </Button>
              </>
            )}
          </div>
          <div style={{ margin: "10px" }}>Product Number</div>
          <LocaleString
            mode={mode}
            path={`${path}.ProductNumber`}
            classes={[
              "variant-plain-string",
              "variant-plain-string-product-number",
            ]}
            elementName={`${elementName}-ProductNumber`}
            debugElement={debugElement}
            changeAttribute={changeAttribute}
            elementData={_.get(elementData, `ProductNumber.Locale`)}
          />
          <div style={blockSubGrouping}>
            <div style={{ margin: "10px" }}>Tender Text</div>
            <LocaleString
              mode={mode}
              path={`${path}.TenderText.Locale`}
              classes="variant-tender-text"
              elementName={`${elementName}-TenderText`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(elementData, `TenderText.Locale`)}
            />
          </div>
          <div style={blockFileRefGrouping}>
            <div style={{ margin: "10px" }}>ModelGeometryReference</div>
            <SelectList
              product={product}
              mode={mode}
              availableListItems={getGeometryIds(product)}
              path={`${path}.Geometry.ModelGeometryReference['@geometryId']`}
              classes={[
                "variant-plain-string",
                "variant-plain-string-geometry-reference",
              ]}
              elementName={`${elementName}-EmitterReference`}
              debugElement={debugElement}
              changeAttribute={changeAttribute}
              elementData={_.get(
                elementData,
                `Geometry.ModelGeometryReference['@geometryId']`
              )}
            />
          </div>
          <div style={{ margin: "10px" }}>Emitters</div>
          {variantEmitters.map((emitter: any, idx_emitter: number) => {
            return (
              <div style={blockFileRefGrouping}>
                <div key={`variantemitter-${idx_emitter}`}>
                  <SelectList
                    product={product}
                    mode={mode}
                    availableListItems={emitterIds}
                    path={`${path}.Geometry.ModelGeometryReference.EmitterReference[${idx_emitter}]['@emitterId']`}
                    classes={["variant-emitter-reference"]}
                    elementName={`${elementName}-EmitterReference-emitterid`}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(emitter, `['@emitterId']`)}
                  />
                  <PlainString
                    product={product}
                    mode={mode}
                    path={`${path}.Geometry.ModelGeometryReference.EmitterReference[${idx_emitter}].EmitterObjectExternalName`}
                    classes={["variant-emitter-reference"]}
                    elementName={`${elementName}-EmitterReference-EmitterObjectExternalName`}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(emitter, `EmitterObjectExternalName`)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default Variant;

import { DisplayElementTypes } from "../consts"

//ToDo Support all SimpleGeometry
const geometriesConf = {
    blockName: "Geometries",
    blockClasses: ["geometriesClass-1", "geometriesClass-2"],
    blockList: [
        {
            displayElementName: "Model Geometries",
            displayElementType: DisplayElementTypes.Geometries,
            displayElementClasses: ["GeometriesClasssGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.Geometries.ModelGeometry"
        },
        // {
        //     displayElementName: "Simple Geometries",
        //     displayElementType: DisplayElementTypes.Geometries,
        //     displayElementClasses: ["GeometriesClasssGeneralClass-1"],
        //     displayElementPath: "GeneralDefinitions.Geometries.SimpleGeometry"
        // },
        
    ]
}

export default geometriesConf;
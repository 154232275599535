import { DisplayElementTypes } from "../consts"
const validationErrorsConf = {
    blockName: "ValidationErrors",
    blockClasses: ["validationErrorsClass-1", "validationErrorsClass-2"],
    blockList: [
        {
            displayElementName: "Validation Errors",
            displayElementType: DisplayElementTypes.ValidationErrors,
            displayElementClasses: ["validationErrorsGeneralClass-1"],
        },
        
    ]
}

export default validationErrorsConf;
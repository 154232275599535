import * as React from "react";
import "./style.scss";

const FlexRow = (props: {
    children?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    flexDirection?: string;
    padding?: string;
    margin?: string;
    rowBg?: string;
    justify?: string;
    width?: string;
    align?: string;
    minHeight?: string;
    maxHeight?: string;
    color?: string;
    overflow?: string;
}) => {
    return (
        <div style={{
            color: props.color,
            overflow: props.overflow,
            padding: props.padding,
            margin: props.margin,
            maxHeight: props.maxHeight,
            background: props.rowBg,
            justifyContent: props.justify,
            width: props.width,
            alignItems: props.align,
            minHeight: props.minHeight
        }}
             className={`flex-row ${props.flexDirection ? props.flexDirection : ""}`}>
            {props.children}
        </div>
    )
}

export default FlexRow;
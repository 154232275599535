const RecessedOverrideFunction = (product: any, pathToOverride: any, data: any) => {
    switch (data) {
      case 'RectangularCutout':
        return {
          '@recessedDepth': 2,
          RectangularCutout: {
            Width: 4,
            Length: 5,
            Depth: 6,
          },
        };
      case 'CircularCutout':
        return {
          '@recessedDepth': 2,
          CircularCutout: {
            Diameter: 3,
            Depth: 1,
          },
        };
      default:
        break;
    }
    return null;
  };

export default RecessedOverrideFunction
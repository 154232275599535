import { DisplayElementTypes } from "../consts"

const variantDescriptiveAttributesConf = {
    blockName: "VariantDescriptiveAttributes",
    blockClasses: ["VariantDescriptiveAttributesClass-1", "VariantDescriptiveAttributesClass-2"],
    blockList: [
        {
            displayElementName: "VariantDescriptiveAttributes",
            displayElementType: DisplayElementTypes.VariantDescriptiveAttributes,
            displayElementClasses: ["VariantDescriptiveAttributesClass-1"],
            displayElementPath: ""
        },
    ]
}

export default variantDescriptiveAttributesConf;
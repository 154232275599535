import * as React from 'react';
import classNames from 'classnames';
import { TextField } from '@mui/material';
import * as _ from 'lodash';

const PlainEmail = ({ path, product, elementName, classes, elementData, debugElement, mode, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  /*Todo: match state with props value to be able to update the input field*/

  // const [value, setValue] = useState(elementData);

  if (elementData) {
    const plainStringClassNames = classNames('plain-string', classes);
    return (
      <>
        {mode === 'displayMode' || !mode ? (
          <>
            <TextField variant={"filled"} fullWidth={true} disabled style={{ color: 'white' }} size={'small'} value={elementData['@mailto']} />
            <TextField variant={"filled"} fullWidth={true} disabled style={{ color: 'white' }} size={'small'} value={elementData['$']} />
          </>
        ) : (
          <>
            <TextField variant={"filled"}
              fullWidth={true}
              size={'small'}
              value={elementData['@mailto']}
              onChange={(e) => changeAttribute(`${path}['@mailto']`, e.target.value)}
            />
            <TextField variant={"filled"}
              fullWidth={true}
              size={'small'}
              value={elementData['$']}
              onChange={(e) => changeAttribute(`${path}['$']`, e.target.value)}
            />
          </>
        )}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default PlainEmail;

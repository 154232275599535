import * as React from "react";
import {TextField} from '@mui/material';

const LicenseKeys = ({mode, path, elementName, elementData, debugElement, changeAttribute}: any) => {
    debugElement && console.log(`DATA ${elementName}`, elementData, debugElement)
    if (Array.isArray(elementData)) {
        return <>
            {elementData.map((element: any, idx: number) => {
                if (mode === "editMode") {
                    return (
                        <div key={`licence-${element['@application']}`}>
                            <div style={{margin: '10px 10px'}}>
                                {element['@application']}
                            </div>
                            <TextField
                                variant={"filled"}
                                type="text"
                                fullWidth={true}
                                size={'small'}
                                value={element['$']}
                                // onChange={(e) => setValue(e.target.value)}
                                onChange={(e) => changeAttribute(`${path}[${idx}]['$']`, e.target.value)}
                            />
                        </div>)
                }
                return (
                    <div key={`licence-${element['@application']}`}>
                        <div style={{margin: '10px 10px'}}>
                            {element['@application']}
                        </div>
                        <TextField
                            variant={"filled"}
                            type="text"
                            disabled={true}
                            fullWidth={true}
                            size={'small'}
                            value={element['$']}
                        />
                    </div>)
            })}
        </>
    }
    return (debugElement ?
        <strong style={{color: "#f68f9c"}}>{`Element: ${elementName} could not be found`}</strong> : null);
}

export default LicenseKeys;
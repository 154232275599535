import * as React from 'react';
import './style.scss';
import {Alert} from "@mui/material";

const PlainString = ({
     validationErrors,
 }: any) => {

    if (validationErrors.length) {
        return (
            <>
                <>
                    <span style={{ marginBottom: '2rem'}}>Make sure all the validation errors have been fixed before saving your file.</span>
                        {validationErrors.map((error: any, idx: number) => {
                            return (
                                <>
                                    <div key={`lightSources-${idx}`} style={{ margin: '1rem 0'}}>
                                        <Alert variant="outlined" severity="error" sx={{ backgroundColor: 'transparent '}}>
                                            <span style={{color: '#ef5350'}}>{ error }</span>
                                        </Alert>
                                    </div>
                                </>
                            )
                        })}
                </>
            </>
        )
    } else {
        return null
    }
};

export default PlainString;

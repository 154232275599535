import * as React from "react";
import { Validators } from "../../../consts";
import * as _ from "lodash";
import {blockSubGrouping} from "../../../css/theme";

import {
  PlainString,
  PlainNumber,
  PlainBoolean,
  LocaleString,
  EnergyLabels,
  Voltage,
  CheckboxArray,  
} from "..";

const ControlGears = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  validationErrors,
  setValidationErrors,
  debugElement,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  //ToDo: move up
  const setValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    if (tmpValidationErrors.indexOf(elementPath) < 0) {
      tmpValidationErrors.push(elementPath);
    }
    setValidationErrors(tmpValidationErrors);
  };

  const unSetValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    const index = tmpValidationErrors.indexOf(elementPath);
    if (index > -1) {
      _.pullAt(tmpValidationErrors, index);
      setValidationErrors(tmpValidationErrors);
    }
  };

  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div key={`control-gear-${idx}`}>
              <PlainString
                mode={mode}
                product={product}
                path={`${path}[${idx}]["@id"]`}
                key={`${elementName}-${idx}-id`}
                classes="element-id-class"
                elementName={`${elementName}-${idx}-id`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonEmptyString
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={element["@id"]}
              />
              <div style={blockSubGrouping}>
                <div style={{margin: '10px'}}>Name</div>
                <LocaleString
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}].Name.Locale`}
                  key={`${elementName}-name-locale`}
                  classes="control-gear-Name-class"
                  elementName={`${elementName}-Name`}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(
                    product,
                    `${path}[${idx}].Name.Locale`
                  )}
                />
              </div>
              <div style={blockSubGrouping}>
              <div style={{margin: '10px'}}>Description</div>
              <LocaleString
                mode={mode}
                product={product}
                path={`${path}[${idx}].Description.Locale`}
                key={`${elementName}-description-locale`}
                classes="control-gear-description-class"
                elementName={`${elementName}-Description`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(
                  product,
                  `${path}[${idx}].Description.Locale`
                )}
              />
              </div>
              <div style={blockSubGrouping}>
                <span style={{fontWeight: '700', marginTop: '10px'}}>Nominal Voltage</span>
                <Voltage
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}].NominalVoltage`}
                    key={`${elementName}-nominal-voltage-${idx}`}
                    classes="element-nominal-voltage-class"
                    elementName={`${elementName}-NominalVoltage-${idx}`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].NominalVoltage`)}
                />
              </div>
              <div style={{margin: '10px'}}>Standby Power</div>
              <PlainNumber
                mode={mode}
                product={product}
                isFloat={true}
                path={`${path}[${idx}].StandbyPower`}
                key={`${elementName}-StandbyPower-${idx}`}
                classes="element-StandbyPower-class"
                elementName={`${elementName}-StandbyPower`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonNegativeNumber
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(product, `${path}[${idx}].StandbyPower`)}
              />
              <div style={{margin: '10px'}}>Constant Light Output Start Power</div>
              <PlainNumber
                mode={mode}
                product={product}
                isFloat={true}
                path={`${path}[${idx}].ConstantLightOutputStartPower`}
                key={`${elementName}-ConstantLightOutputStartPower-${idx}`}
                classes="element-ConstantLightOutputStartPower-class"
                elementName={`${elementName}-ConstantLightOutputStartPower`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonNegativeNumber
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(
                  product,
                  `${path}[${idx}].ConstantLightOutputStartPower`
                )}
              />
              <div style={{margin: '10px'}}>Constant Light Output End Power</div>
              <PlainNumber
                mode={mode}
                product={product}
                isFloat={true}
                path={`${path}[${idx}].ConstantLightOutputEndPower`}
                key={`${elementName}-ConstantLightOutputEndPower-${idx}`}
                classes="element-ConstantLightOutputEndPower-class"
                elementName={`${elementName}-ConstantLightOutputEndPower`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonNegativeNumber
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(
                  product,
                  `${path}[${idx}].ConstantLightOutputEndPower`
                )}
              />
              <div style={{margin: '10px'}}>Power Consumption Controls</div>
              <PlainNumber
                mode={mode}
                isFloat={true}
                product={product}
                path={`${path}[${idx}].PowerConsumptionControls`}
                key={`${elementName}-PowerConsumptionControls-${idx}`}
                classes="element-PowerConsumptionControls-class"
                elementName={`${elementName}-PowerConsumptionControls`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonNegativeNumber
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(
                  product,
                  `${path}[${idx}].PowerConsumptionControls`
                )}
              />
              <div style={{margin: '10px'}}>Dimmable</div>
              <PlainBoolean
                mode={mode}
                product={product}
                path={`${path}[${idx}].Dimmable`}
                key={`${elementName}-dimmable-${idx}`}
                classes="element-dimmable-class"
                elementName={`${elementName}-Dimmable`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                defaultValue={false}
                elementData={_.get(product, `${path}[${idx}].Dimmable`)}
              />
              <div style={{margin: '10px'}}>Color Controllable</div>
              <PlainBoolean
                mode={mode}
                product={product}
                path={`${path}[${idx}].ColorControllable`}
                key={`${elementName}-color-controllable-${idx}`}
                classes="element-color-controllable-class"
                elementName={`${elementName}-ColorControllable`}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                defaultValue={false}
                elementData={_.get(
                  product,
                  `${path}[${idx}].ColorControllable`
                )}
              />
              <div style={blockSubGrouping}>
                <div style={{fontWeight: '700', margin: '10px 0px'}}>Interfaces</div>
                <CheckboxArray
                    mode={mode}
                    path={`${path}[${idx}].Interfaces.Interface`}
                    key={`${elementName}-interfaces-${idx}`}
                    classes="element-intefaces-class"
                    elementName={`${elementName}-Interfaces`}
                    availableCheckboxList={[
                      "DALI Broadcast",
                      "DALI Addressable",
                      "KNX",
                      "0-10V",
                      "1-10V",
                      "230V",
                      "RF",
                      "WiFi",
                      "Bluetooth",
                      "Inter-Connection",
                      "DMX",
                      "DMX/RDM",
                    ]}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    validationConf={[
                      {
                        validator: Validators.nonEmptyArray
                      },
                    ]}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(
                        product,
                        `${path}[${idx}].Interfaces.Interface`
                    )}
                />
              </div>
              <div style={{fontWeight: '700', margin: '10px'}}>EnergyLabels</div>
              <EnergyLabels
                mode={mode}
                product={product}
                path={`${path}[${idx}].EnergyLabels.EnergyLabel`}
                key={`${elementName}-energy-labels`}
                classes="element-energy-labels-class-${idx}"
                elementName={`${elementName}-EnergyLabels`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={_.get(
                  product,
                  `${path}[${idx}].EnergyLabels.EnergyLabel`
                )}
              />
              <hr />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default ControlGears;

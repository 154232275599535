import { DisplayElementTypes } from "../consts"
const descriptiveAttributesConf = {
    blockName: "DescriptiveAttributes",
    blockClasses: ["DescriptiveAttributesClass-1", "DescriptiveAttributesClass-2"],
    blockList: [
        {
            displayElementName: "DescriptiveAttributes",
            displayElementType: DisplayElementTypes.DescriptiveAttributes,
            displayElementClasses: ["descriptiveAttributesClass-1"],
            displayElementPath: "ProductDefinitions.ProductMetaData.DescriptiveAttributes"
        },    
    ]
}

export default descriptiveAttributesConf;
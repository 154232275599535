import * as React from 'react';
import { Range, PlainNumber, LocaleString, SelectList } from '../..';
import * as _ from 'lodash';
import { blockSubGrouping, blockTitleStyle } from '../../../../css/theme';
import { Validators } from '../../../../consts';

const Mechanical = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  return (
    <>
      <h3 style={blockTitleStyle}>Mechanical</h3>
      <div style={{ margin: '10px' }}>ProductSize</div>
      <div style={blockSubGrouping}>
        <div style={{ margin: '10px' }}>Length</div>
        <PlainNumber
          mode={mode}
          product={product}
          path={`${path}.Mechanical.ProductSize.Length`}
          elementName="Length"
          validationConf={[
            {
              validator: Validators.nonNegativeNumber,
            },
          ]}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.Mechanical.ProductSize.Length`)}
        />
        <div style={{ margin: '10px' }}>Width</div>
        <PlainNumber
          mode={mode}
          product={product}
          path={`${path}.Mechanical.ProductSize.Width`}
          elementName="Width"
          validationConf={[
            {
              validator: Validators.nonNegativeNumber,
            },
          ]}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.Mechanical.ProductSize.Width`)}
        />
        <div style={{ margin: '10px' }}>Height</div>
        <PlainNumber
          mode={mode}
          product={product}
          path={`${path}.Mechanical.ProductSize.Height`}
          elementName="Height"
          validationConf={[
            {
              validator: Validators.nonNegativeNumber,
            },
          ]}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.Mechanical.ProductSize.Height`)}
        />
      </div>
      <div>
        <div style={{ margin: '10px' }}>Sealing Material</div>
        <LocaleString
          mode={mode}
          product={product}
          path={`${path}.Mechanical.SealingMaterial.Locale`}
          elementName="SealingMaterial"
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          validationConf={[
            {
              validator: Validators.nonEmptyString,
            },
          ]}
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.Mechanical.SealingMaterial.Locale`)}
        />
        <div style={{ margin: '10px' }}>IKRating</div>
        <SelectList
          mode={mode}
          availableListItems={['IK00', 'IK01', 'IK02', 'IK03', 'IK04', 'IK05', 'IK06', 'IK07', 'IK08', 'IK09', 'IK10', 'IK01+']}
          product={product}
          path={`${path}.Mechanical.IKRating`}
          key={`${elementName}-IKRating`}
          classes="lightEmitter-IKRating-class"
          elementName="IKRating"
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          defaultValue="IK02"
          elementData={_.get(product, `${path}.Mechanical.IKRating`)}
        />
        <div style={{ margin: '10px' }}>Weight</div>
        <PlainNumber
          mode={mode}
          isFloat={true}
          product={product}
          path={`${path}.Mechanical.Weight`}
          validationConf={[
            {
              validator: Validators.nonNegativeNumber,
            },
          ]}
          setValidationErrorsHandler={setValidationErrorsHandler}
          unSetValidationErrorsHandler={unSetValidationErrorsHandler}
          elementName="Weight"
          debugElement={debugElement}
          changeAttribute={changeAttribute}
          elementData={_.get(product, `${path}.Mechanical.Weight`)}
        />
      </div>
      {debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null}
    </>
  );
};

export default Mechanical;

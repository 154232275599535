import * as React from "react";
import {PlainString, Range, StringArray} from "../..";
import * as _ from "lodash";
import {blockSubGrouping, blockTitleStyle} from "../../../../css/theme";
import {Validators} from "../../../../consts";

const MountingAndAccessory = ({
      mode,
      product,
      path,
      elementName,
      elementData,
      validationErrors,
      setValidationErrorsHandler,
      unSetValidationErrorsHandler,
      changeAttribute,
      deleteAttribute,
      debugElement,
  }: any) => {
    debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
    if (elementData) {
        return (
            <>
                <h3 style={blockTitleStyle}>Mounting and Accessory</h3>
                <div style={blockSubGrouping}>
                    <div style={{margin: '10px', fontWeight: '700'}}>ATEX</div>
                    <div style={{margin: '10px'}}>Directives</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.Directives.Directive`}
                        elementName="Directives"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.Directives.Directive`
                        )}
                    />
                    <div style={{margin: '10px'}}>Classes</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.Classes.Class`}
                        elementName="Classes"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.Classes.Class`
                        )}
                    />
                    <div style={{margin: '10px'}}>Divisions</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.Divisions.Division`}
                        elementName="Divisions"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.Divisions.Division`
                        )}
                    />
                    <div style={blockSubGrouping}>
                        <div style={{margin: '10px', fontWeight: '700'}}>Division Groups</div>
                        <div style={{margin: '10px'}}>Division Group - Gas</div>
                        <StringArray
                            mode={mode}
                            product={product}
                            path={`${path}.MountingAndAccessory.ATEX.DivisionGroups.Gas.Group`}
                            elementName="Division Groups - Gas"
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            deleteAttribute={deleteAttribute}
                            validationErrors={validationErrors}
                            setValidationErrorsHandler={setValidationErrorsHandler}
                            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                            validationConf={[
                                {
                                    validator: Validators.nonEmptyString
                                },
                            ]}
                            elementData={_.get(
                                product,
                                `${path}.MountingAndAccessory.ATEX.DivisionGroups.Gas.Group`
                            )}
                        />
                        <div style={{margin: '10px'}}>Division Group - Dust</div>
                        <StringArray
                            mode={mode}
                            product={product}
                            path={`${path}.MountingAndAccessory.ATEX.DivisionGroups.Dust.Group`}
                            elementName="Division Groups - Dust"
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            deleteAttribute={deleteAttribute}
                            validationErrors={validationErrors}
                            setValidationErrorsHandler={setValidationErrorsHandler}
                            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                            validationConf={[
                                {
                                    validator: Validators.nonEmptyString
                                },
                            ]}
                            elementData={_.get(
                                product,
                                `${path}.MountingAndAccessory.ATEX.DivisionGroups.Dust.Group`
                            )}
                        />
                    </div>
                    <div style={blockSubGrouping}>
                        <div style={{margin: '10px', fontWeight: '700'}}>Zone Groups</div>
                        <div style={{margin: '10px'}}>Zone Group - Gas</div>
                        <StringArray
                            mode={mode}
                            product={product}
                            path={`${path}.MountingAndAccessory.ATEX.ZoneGroups.Gas.Group`}
                            elementName="ZoneGroups - Gas"
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            deleteAttribute={deleteAttribute}
                            validationErrors={validationErrors}
                            setValidationErrorsHandler={setValidationErrorsHandler}
                            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                            validationConf={[
                                {
                                    validator: Validators.nonEmptyString
                                },
                            ]}
                            elementData={_.get(
                                product,
                                `${path}.MountingAndAccessory.ATEX.ZoneGroups.Gas.Group`
                            )}
                        />
                        <div style={{margin: '10px'}}>Zone Group - Dust</div>
                        <StringArray
                            mode={mode}
                            product={product}
                            path={`${path}.MountingAndAccessory.ATEX.ZoneGroups.Dust.Group`}
                            elementName="ZoneGroups - Dust"
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            deleteAttribute={deleteAttribute}
                            validationErrors={validationErrors}
                            setValidationErrorsHandler={setValidationErrorsHandler}
                            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                            validationConf={[
                                {
                                    validator: Validators.nonEmptyString
                                },
                            ]}
                            elementData={_.get(
                                product,
                                `${path}.MountingAndAccessory.ATEX.ZoneGroups.Dust.Group`
                            )}
                        />
                    </div>
                    <div style={{margin: '10px'}}>Maximum Surface Temperature</div>
                    <PlainString
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.MaximumSurfaceTemperature`}
                        elementName="MaximumSurfaceTemperature"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.MaximumSurfaceTemperature`
                        )}
                    />
                    <div style={{margin: '10px'}}>Temperature Classes</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.TemperatureClasses.TemperatureClass`}
                        elementName="TemperatureClasses"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.TemperatureClasses.TemperatureClass`
                        )}
                    />
                    <div style={{margin: '10px'}}>Ex Codes</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.ExCodes.ExCode`}
                        elementName="ExCodes"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.ExCodes.ExCode`
                        )}
                    />
                    <div style={{margin: '10px'}}>Equipment ProtectionLevels</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.EquipmentProtectionLevels.EquipmentProtectionLevel`}
                        elementName="EquipmentProtectionLevels"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.EquipmentProtectionLevels.EquipmentProtectionLevel`
                        )}
                    />
                    <div style={{margin: '10px'}}>Equipment Groups</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.EquipmentGroups.EquipmentGroup`}
                        elementName="EquipmentGroups"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.EquipmentGroups.EquipmentGroup`
                        )}
                    />
                    <div style={{margin: '10px'}}>Equipment Categories</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.EquipmentCategories.EquipmentCategory`}
                        elementName="EquipmentCategories"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.EquipmentCategories.EquipmentCategory`
                        )}
                    />
                    <div style={{margin: '10px'}}>Atmospheres</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.Atmospheres.Atmosphere`}
                        elementName="Atmospheres"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.Atmospheres.Atmosphere`
                        )}
                    />
                    <div style={{margin: '10px'}}>Groups</div>
                    <StringArray
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.ATEX.Groups.Group`}
                        elementName="Group"
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        deleteAttribute={deleteAttribute}
                        validationErrors={validationErrors}
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        validationConf={[
                            {
                                validator: Validators.nonEmptyString
                            },
                        ]}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.ATEX.Groups.Group`
                        )}
                    />
                    <div style={{margin: '10px'}}>Ambient Temperature</div>
                    <Range
                        mode={mode}
                        product={product}
                        path={`${path}.MountingAndAccessory.AmbientTemperature`}
                        elementName="MountingAndAccessory"
                        setValidationErrorsHandler={setValidationErrorsHandler}
                        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                        debugElement={debugElement}
                        changeAttribute={changeAttribute}
                        elementData={_.get(
                            product,
                            `${path}.MountingAndAccessory.AmbientTemperature`
                        )}
                    />
                </div>
            </>
        );
    }
    return debugElement ? (
        <strong
            style={{color: "#f68f9c"}}
        >{`Element: ${elementName} could not be found`}</strong>
    ) : null;
};

export default MountingAndAccessory;

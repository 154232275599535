import * as React from 'react';
import './style.scss';

import ListItemButton from '@mui/material/ListItemButton';
import { itemVariantinfo } from '../../FlexDrawer/const';
import ListItem from '@mui/material/ListItem';
import { Architecture, Bolt, Close, Construction, Engineering, ExitToApp, LocalOffer } from '@mui/icons-material';
import FlexButtonGroup from '../../FlexButtonGroup';

/* Todo: this component obviously needs some refactoring */

const VariantDescriptiveAttributesList = ({
  selectedVariant,
  isVariantPanelOpen,
  toggleVariantPane,
  openModal,
  isModalOpen,
  setModalOpen,
  setModalView,
}: any) => {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleListItemClick = (event: any, index: React.SetStateAction<number>) => {
    setSelectedIndex(index);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, descriptiveAttribute: string, index: React.SetStateAction<number>) => {
    // if (selectedVariant.hasOwnProperty('DescriptiveAttributes') &&
    //     selectedVariant.DescriptiveAttributes.hasOwnProperty(descriptiveAttribute)) {
    if (toggleVariantPane) {
      toggleVariantPane(selectedVariant.DescriptiveAttributes[descriptiveAttribute], descriptiveAttribute);
      handleListItemClick(event, index);
    } else if (openModal) {
      handleListItemClick(event, index);
      openModal('editMode', 'VariantDescriptiveAttributesConf', descriptiveAttribute, isModalOpen, setModalOpen, setModalView);
    }
    // } else {
    //     /* Swallow */
    // }
  };

  if (selectedVariant) {
    return (
      <>
        <ListItem disablePadding sx={{ width: '50px' }}>
          <ListItemButton selected={selectedIndex === 1} sx={itemVariantinfo} onClick={(event) => handleClick(event, 'Mechanical', 1)}>
            {selectedIndex === 1 && isVariantPanelOpen ? (
              <Close />
            ) : (
              <Architecture
                color={!selectedVariant.DescriptiveAttributes || !selectedVariant.DescriptiveAttributes.Mechanical ? 'disabled' : 'primary'}
              />
            )}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ width: '50px' }}>
          <ListItemButton selected={selectedIndex === 0} sx={itemVariantinfo} onClick={(event) => handleClick(event, 'Electrical', 0)}>
            {selectedIndex === 0 && isVariantPanelOpen ? (
              <Close />
            ) : (
              <Bolt color={!selectedVariant.DescriptiveAttributes || !selectedVariant.DescriptiveAttributes.Electrical ? 'disabled' : 'primary'} />
            )}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ width: '50px' }}>
          <ListItemButton selected={selectedIndex === 2} sx={itemVariantinfo} onClick={(event) => handleClick(event, 'Mountings', 2)}>
            {selectedIndex === 2 && isVariantPanelOpen ? (
              <Close />
            ) : (
              <Construction
                color={!selectedVariant.DescriptiveAttributes || !selectedVariant.DescriptiveAttributes.Mountings ? 'disabled' : 'primary'}
              />
            )}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ width: '50px' }}>
          <ListItemButton selected={selectedIndex === 3} sx={itemVariantinfo} onClick={(event) => handleClick(event, 'OperationsAndMaintenance', 3)}>
            {selectedIndex === 3 && isVariantPanelOpen ? (
              <Close />
            ) : (
              <Engineering
                color={
                  !selectedVariant.DescriptiveAttributes || !selectedVariant.DescriptiveAttributes.OperationsAndMaintenance ? 'disabled' : 'primary'
                }
              />
            )}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ width: '50px' }}>
          <ListItemButton selected={selectedIndex === 4} sx={itemVariantinfo} onClick={(event) => handleClick(event, 'Emergency', 4)}>
            {selectedIndex === 4 && isVariantPanelOpen ? (
              <Close />
            ) : (
              <ExitToApp
                color={!selectedVariant.DescriptiveAttributes || !selectedVariant.DescriptiveAttributes.Emergency ? 'disabled' : 'primary'}
              />
            )}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ width: '50px' }}>
          <ListItemButton selected={selectedIndex === 5} sx={itemVariantinfo} onClick={(event) => handleClick(event, 'Marketing', 5)}>
            {selectedIndex === 5 && isVariantPanelOpen ? (
              <Close />
            ) : (
              <LocalOffer
                color={!selectedVariant.DescriptiveAttributes || !selectedVariant.DescriptiveAttributes.Marketing ? 'disabled' : 'primary'}
              />
            )}
          </ListItemButton>
        </ListItem>
      </>
    );
  }
  return <strong style={{ color: '#ffffff' }}> {`Element could not be found`}</strong>;
};

export default VariantDescriptiveAttributesList;

import * as React from 'react';
import { Box, Button } from '@mui/material';
import { BlockRenderer } from './';
import { FlexModal, FlexRow, VariantDescriptiveAttributesList } from './UserInterfaceElements';
import FlexButtonGroup from './UserInterfaceElements/FlexButtonGroup';
import { IGeneralModal } from "../interfaces";


const GeneralModal = ({
  product,
  GLDFBase64FileArray,
  fileUUID,
  debugEnabled,
  selectedVariant,
  variant,
  variantName,
  isModalOpen,
  setModalOpen,
  openModal,
  closeModal,
  modalStyle,
  modalView,
  setModalView,
  validationErrors,
  setValidationErrors,
  changeAttribute,
  deleteAttribute
}:IGeneralModal) => (
    <FlexModal isModalOpen={isModalOpen} closeModal={() => closeModal(setModalOpen)}>
    <Box sx={modalStyle}>
      <FlexRow align={'center'} justify={'flex-start'} rowBg={'#003a7f'} minHeight={'53px'} padding={'.7rem'}>
        {modalView.menuConf.blockName === 'variant' || modalView.menuConf.blockName === 'VariantDescriptiveAttributes' ? (
          <>
            {typeof selectedVariant !== 'undefined' && (
              <>
                <Button
                  sx={{
                    height: '30px',
                    marginLeft: '2rem',
                    marginRight: '2rem',
                  }}
                  variant="outlined"
                  size={'small'}
                  onClick={() => {
                    openModal('editMode', 'variantConf', '', isModalOpen, setModalOpen, setModalView);
                  }}>
                  {variantName}
                </Button>
                <VariantDescriptiveAttributesList
                  openModal={openModal}
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  setModalView={setModalView}
                  selectedVariant={variant}
                />
              </>
            )}
            <FlexButtonGroup modalView={modalView} openModal={openModal} closeModal={() => setModalOpen(false)} />
          </>
        ) : (
          <FlexButtonGroup modalView={modalView} openModal={openModal} setModalView={setModalView} closeModal={() => setModalOpen(false)} />
        )}
      </FlexRow>
      <Box sx={{ p: 2 }}>
        <BlockRenderer
          fileUUID={fileUUID}
          GLDFBase64FileArray={GLDFBase64FileArray}
          debugElements={debugEnabled}
          style={{ padding: '16px' }}
          product={product}
          mode={modalView.mode}
          selectedVariant={selectedVariant}
          blockConf={modalView.menuConf}
          blockSubConf={modalView.subMenuConf}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          changeAttribute={changeAttribute}
          deleteAttribute={deleteAttribute}
        />
      </Box>
    </Box>
  </FlexModal>
);

export default GeneralModal;

import * as React from 'react';
import classNames from 'classnames';
import { PlainNumber, SelectList } from '..';
import * as _ from 'lodash';
import {blockSubGrouping} from "../../../css/theme";
import {Validators} from "../../../consts";

const MaintenanceFactor = ({
   path, roomCondition, elementName, classes, elementData, debugElement, mode, changeAttribute,
   setValidationErrorsHandler,
   unSetValidationErrorsHandler,
   validationErrors
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  if (elementData) {
    const plainStringClassNames = classNames('plain-maintenance-factor', classes);
    return (
      <>
        {elementData.map((elmt: any, idx: number) => {
          return (
            <div style={blockSubGrouping} key={`elmt-${idx}`}>
              <PlainNumber
                mode={mode}
                isFloat={true}
                path={`${path}[${idx}]['$']`}
                key={`${elementName}-${idx}-CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-Value`}
                classes="CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-Value"
                elementName={`${elementName}-CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-${idx}-Value`}
                debugElement={debugElement}
                validationErrors={validationErrors}
                validationConf={[
                  {
                    validator: Validators.nonNegativeNumber
                  },
                ]}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                changeAttribute={changeAttribute}
                elementData={elmt['$']}
              />
              <br />
              <div style={{margin: '10px'}}>Room Condition</div>
              <SelectList
                mode={mode}
                requiredField
                path={`${path}[${idx}]['@roomCondition']`}
                key={`${elementName}-${idx}-CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-RoomCondition`}
                classes="CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-RoomCondition"
                elementName={`${elementName}-CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-${idx}-RoomCondition`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonEmptyString
                  },
                ]}
                debugElement={debugElement}
                availableListItems={roomCondition}
                changeAttribute={changeAttribute}
                elementData={elmt['@roomCondition']}
              />
              <div style={{margin: '10px'}}>Years</div>
              <PlainNumber
                mode={mode}
                path={`${path}[${idx}]['@years']`}
                key={`${elementName}-${idx}-CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-Years`}
                classes="CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-Years"
                elementName={`${elementName}-CieLuminaireMaintenanceFactors-LuminaireMaintenanceFactor-${idx}-Years`}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                validationConf={[
                  {
                    validator: Validators.nonNegativeNumber
                  },
                ]}
                debugElement={debugElement}
                changeAttribute={changeAttribute}
                elementData={elmt['@years']}
              />
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default MaintenanceFactor;

import { DisplayElementTypes } from "../consts"
const equipmentsConf = {
    blockName: "Equipments",
    blockClasses: ["equipmentsClass-1", "equipmentsClass-2"],
    blockList: [
        {
            displayElementName: "Equipments",
            displayElementType: DisplayElementTypes.Equipments,
            displayElementClasses: ["EquipmentsClasssGeneralClass-1"],
            displayElementPath: "GeneralDefinitions.Equipments.Equipment"
        },
        
    ]
}

export default equipmentsConf;
import * as React from 'react';
import { Box } from '@mui/material';
import { BlockRenderer } from './';
import { FlexRow } from './UserInterfaceElements';
import { IVariantPanel } from "../interfaces";


const VariantPanel = ({
  product,
  debugEnabled,
  selectedVariant,
  blockConf,
  blockSubConf,
  validationErrors,
  setValidationErrors,
  changeAttribute,
}:IVariantPanel) => (
  <Box sx={{ display: 'flex' }}>
    <div style={{ width: '320px' }}>
      <FlexRow flexDirection={'column'} minHeight={'100vh'} maxHeight={'100vh'} overflow={'scroll'}>
        <BlockRenderer
          debugElements={debugEnabled}
          product={product}
          selectedVariant={selectedVariant}
          blockConf={blockConf}
          blockSubConf={blockSubConf}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          changeAttribute={changeAttribute}
        />
      </FlexRow>
    </div>
  </Box>
);

export default VariantPanel;

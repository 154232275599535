import * as React from "react";

import {
  PlainString,
  PlainNumber,
  LicenseKeys,
  ContactAddress,
  LocaleString,
  Variants,
  ProductSeries,
  Image,
  ProductMaintenance,
  DescriptiveAttributes,
  VariantDescriptiveAttributes,
  Files,
  ControlGears,
  Equipments,
  Photometries,
  Emitters,
  Geometries,
  LightSources,
  Variant,
} from "./DisplayElements";
import {
  returnVariantDescriptiveAttributesPath,
  returnVariantPath,
} from "../util";
import { DisplayElementTypes } from "../consts";
import * as _ from "lodash";
import { FlexRow } from "./UserInterfaceElements";
import {Alert} from "@mui/material";
import {blockTitleStyle} from "../css/theme";
import ValidationErrors from "./DisplayElements/ValidationErrors";

const BlockRenderer = ({
  debugElements,
  fileUUID,
  blockConf,
  blockSubConf,
  product,
  mode,
  setSelectedVariant,
  selectedVariant,
  validationErrors,
  setValidationErrors,
  changeAttribute,
  deleteAttribute,
  GLDFBase64FileArray
}: any) => {
  const { blockList, blockName } = blockConf;
  //ToDo: move up
  const setValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    if (tmpValidationErrors.indexOf(elementPath) < 0) {
      tmpValidationErrors.push(elementPath);
    }
    setValidationErrors(tmpValidationErrors);
  };

  const unSetValidationErrorsHandler = (elementPath: string) => {
    const tmpValidationErrors = _.cloneDeep(validationErrors);
    const index = tmpValidationErrors.indexOf(elementPath);
    if (index > -1) {
      _.pullAt(tmpValidationErrors, index);
      setValidationErrors(tmpValidationErrors);
    }
  };

  const renderElements = (elementsList: any) => {
    return elementsList.map((element: any) => (
      <div
        key={`element-key-${element.displayElementName}`}
        className={element.displayElementClasses.join(", ")}
      >
        {/* Block title */}

        {element.displayElementName !== "DescriptiveAttributes" &&
            element.displayElementName !== "Variant" &&
            element.displayElementName !== "Variants" &&
            element.displayElementName !== "VariantDescriptiveAttributes" && (
                <>
                  <h3 style={blockTitleStyle}>{element.displayElementName}</h3>
                </>
            )}
        {element.displayElementType === DisplayElementTypes.PlainString && (
          <PlainString
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            classes={element.displayElementClasses}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            readonly={element?.readonly}
            validationConf={element?.validationConf}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.PlainNumber && (
          <PlainNumber
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            classes={element.displayElementClasses}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            readonly={element?.readonly}
            validationConf={element?.validationConf}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.LicenseKeys && (
          <LicenseKeys
            mode={mode}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.ContactAddress && (
          <ContactAddress
            mode={mode}
            product={product}
            parentElement={element}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.LocaleString && (
          <LocaleString
            mode={mode}
            path={element.displayElementPath}
            classes={element.displayElementClasses}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            validationConf={element?.validationConf}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Variants && (
          <Variants
            mode={mode}
            setSelectedVariant={setSelectedVariant}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Variant && (
          <Variant
            mode={mode}
            product={product}
            selectedVariant={selectedVariant}
            path={returnVariantPath(selectedVariant)}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, returnVariantPath(selectedVariant))}
            blockSubConf={blockSubConf}
          />
        )}
        {element.displayElementType ===
          DisplayElementTypes.VariantDescriptiveAttributes && (
          <VariantDescriptiveAttributes
            mode={mode}
            product={product}
            selectedVariant={selectedVariant}
            path={returnVariantDescriptiveAttributesPath(selectedVariant)}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            deleteAttribute={deleteAttribute}
            elementData={_.get(
              product,
              returnVariantDescriptiveAttributesPath(selectedVariant)
            )}
            blockSubConf={blockSubConf}
          />
        )}
        {element.displayElementType === DisplayElementTypes.ProductSeries && (
          <ProductSeries
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Image && (
          <Image
            product={product}
            mode={mode}
            path={element.displayElementPath}
            changeAttribute={changeAttribute}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            elementName={element.displayElementName}
            debugElement={debugElements}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType ===
          DisplayElementTypes.ProductMaintenance && (
          <ProductMaintenance
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType ===
          DisplayElementTypes.DescriptiveAttributes && (
          <DescriptiveAttributes
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            validationErrors={validationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            deleteAttribute={deleteAttribute}
            elementData={_.get(product, element.displayElementPath)}
            blockSubConf={blockSubConf}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Files && (
          <Files
            GLDFBase64FileArray={GLDFBase64FileArray}
            mode={mode}
            fileUUID={fileUUID}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.ControlGears && (
          <ControlGears
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Equipments && (
          <Equipments
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Photometries && (
          <Photometries
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            debugElement={debugElements}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Emitters && (
          <Emitters
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.Geometries && (
          <Geometries
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.LightSources && (
          <LightSources
            mode={mode}
            product={product}
            path={element.displayElementPath}
            elementName={element.displayElementName}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            setValidationErrorsHandler={setValidationErrorsHandler}
            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
            debugElement={debugElements}
            changeAttribute={changeAttribute}
            elementData={_.get(product, element.displayElementPath)}
          />
        )}
        {element.displayElementType === DisplayElementTypes.ValidationErrors && (
            <ValidationErrors
                mode={mode}
                product={product}
                path={element.displayElementPath}
                elementName={element.displayElementName}
                classes={element.displayElementClasses}
                debugElement={debugElements}
                changeAttribute={changeAttribute}
                readonly={element?.readonly}
                validationConf={element?.validationConf}
                validationErrors={validationErrors}
                setValidationErrorsHandler={setValidationErrorsHandler}
                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                elementData={_.get(product, element.displayElementPath)}
            />
        )}
      </div>
    ));
  };

  return (
      <>
        { blockName === 'variants' &&
            (
            <div>
              {validationErrors.length > 0 && (
                  <FlexRow
                      align={"center"}
                      rowBg={'#081627'}
                      justify={"space-between"}
                      maxHeight={"53px"}
                      padding={".7rem"}
                  >
                    <Alert variant="outlined" severity="error" sx={{ backgroundColor: 'transparent '}}>
                      <span style={{color: '#ef5350'}}>{validationErrors.length} invalid field(s).</span>
                    </Alert>
                    {/* ToDo: dirty check on block names, refactor. We need to ship some UI.. */}

                  </FlexRow>
              )}
              {validationErrors.length === 0 && (
                  <FlexRow
                      align={"center"}
                      rowBg={'#081627'}
                      justify={"space-between"}
                      maxHeight={"53px"}
                      padding={".7rem"}
                  >
                    <Alert variant="outlined" severity="success" sx={{ backgroundColor: 'transparent'}}>
                      <span style={{color: '#4caf50'}}>Valid GLDF</span>
                    </Alert>
                  </FlexRow>
              )}
            </div>
        )}
        {renderElements(blockList)}
      </>
  )
};

export default BlockRenderer;

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Switch, Paper, Dialog, DialogTitle, Snackbar, Alert } from '@mui/material';
import { FlexRow, VariantDescriptiveAttributesList, VariantInfo, ImageOrBase64, EmitterRow } from './UserInterfaceElements';
import { Download, Home, OpenInNew, Save } from '@mui/icons-material';
import { APIURL, L3DVIEWERURL } from '../consts';
import { submitProduct, getActiveGeometryReference } from '../util';
import { IMainBox } from '../interfaces';
import Divider from '@mui/material/Divider';
import * as _ from 'lodash';

const MainBox = ({
  product,
  validationErrors,
  fileUUID,
  GLDFBase64FileArray,
  debugEnabled,
  setDebugEnabled,
  selectedVariant,
  variantEmitters,
  variant,
  variantName,
  selectedEquipment,
  openModal,
  isModalOpen,
  setModalOpen,
  setModalView,
  toggleVariantPane,
}: IMainBox) => {
  // Images dialogs state and handlers

  const [openDialog, setDialogOpen] = React.useState(false);
  const [assetURL, setAssetURL] = React.useState('');
  const [typeImg64, setTypeImg64] = React.useState(false);
  const [typeGeo, setTypeGeo] = React.useState(false);

  const handleClickOpen = (type: string, assetURL?: any) => {
    if (type === 'Img64') {
      setAssetURL('');
      setTypeImg64(true);
    } else if (type === 'Img') {
      setAssetURL(assetURL);
    } else if (type === 'Geo') {
      setAssetURL(assetURL);
      setTypeGeo(true);
    }
    setDialogOpen(true);
  };

  const handleClose = (value: any) => {
    setAssetURL('');
    setDialogOpen(false);
    setTypeImg64(false);
    setTypeGeo(false);
  };

  // Save-snackbar state and handlers

  const [snackbarState, setSnackbarState] = React.useState({
    snackBarOpen: false,
    vertical: 'top',
    horizontal: 'center',
    success: true,
  });

  const { vertical, horizontal, snackBarOpen, success } = snackbarState;

  const handleSave = () => {
    if (validationErrors.length) {
      openModal('editMode', 'validationErrorsConf', '', isModalOpen, setModalOpen, setModalView);
    } else {
      submitProduct(
        fileUUID,
        {
          product,
          base64Files: GLDFBase64FileArray,
        },
        (isSuccessful: boolean) => {
          setSnackbarState({ snackBarOpen: true, vertical: 'top', horizontal: 'center', success: isSuccessful });
        }
      );
    }
  };

  const closeSnackbar = () => {
    setSnackbarState({ ...snackbarState, snackBarOpen: false });
  };

  let currentActiveGeometryReferenceFileName = getActiveGeometryReference(product, variant);
  if (currentActiveGeometryReferenceFileName) {
    if (
      (currentActiveGeometryReferenceFileName as string).indexOf('r3d') > -1 ||
      (currentActiveGeometryReferenceFileName as string).indexOf('m3d') > -1
    ) {
      (currentActiveGeometryReferenceFileName as string) = '';
    }
  }
  const url = currentActiveGeometryReferenceFileName ? `${APIURL}${fileUUID}/gldf/l3d/${currentActiveGeometryReferenceFileName}.png` : 'undefined';
  const geometryId = {
    url,
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 300px)',
        minHeight: '100vh',
      }}>
      <FlexRow align={'center'} justify={'flex-start'} minHeight={'53px'}>
        {typeof selectedVariant !== 'undefined' && (
          <VariantDescriptiveAttributesList toggleVariantPane={toggleVariantPane} selectedVariant={variant} />
        )}
        <Button
          sx={{ height: '30px', marginLeft: '2rem' }}
          variant="outlined"
          size={'small'}
          onClick={() => {
            openModal('editMode', 'variantConf', '', isModalOpen, setModalOpen, setModalView);
          }}>
          Edit Variant
        </Button>
        <FlexRow justify={'flex-end'} margin={'0 0 0 auto'} align={'baseline'} width={'300px'}>
          <span style={{ color: 'white', marginLeft: 'auto', minWidth: '100px' }}>
            Debug:
            <Switch
              checked={debugEnabled}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDebugEnabled(e.target.checked);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </span>
          <span style={{ color: 'white', marginLeft: 'auto', margin: '1rem' }}>
            <Link to={`/`}>
              <Button startIcon={<Home />} sx={{ height: '30px', marginLeft: '2rem' }} variant="outlined" size={'small'}>
                HOME
              </Button>
            </Link>
          </span>
          <span style={{ color: 'white', marginLeft: 'auto' }}>
            <Button
              disabled={false && Array.isArray(validationErrors) && validationErrors.length > 0}
              startIcon={<Save sx={{ color: 'black' }} />}
              sx={{ height: '30px', marginLeft: '2rem', color: 'black', bgcolor: '#dea500', margin: '1rem' }}
              variant="outlined"
              size={'small'}
              onClick={() => {
                handleSave();
              }}>
              SAVE
            </Button>
          </span>
          <span style={{ color: 'white', marginLeft: 'auto' }}>
            <Button
              startIcon={<Download sx={{ color: 'black' }} />}
              sx={{
                height: '30px',
                marginLeft: '2rem',
                marginRight: '2rem',
                color: 'black',
                bgcolor: '#dea500',
                margin: '1rem',
              }}
              variant="outlined"
              size={'small'}
              onClick={() => {
                window.open(APIURL + fileUUID + '/gldf/gldf/' + fileUUID + '.gldf');
              }}>
              GLDF
            </Button>
          </span>
        </FlexRow>
      </FlexRow>

      {/* Variant Quick Info Bar  */}

      <FlexRow justify={'flex-start'} color={'#fff'} rowBg={'#113b6f'} align={'center'} flexDirection={'row'} minHeight={'53px'}>
        <div
          style={{
            fontSize: '12px',
            fontWeight: 700,
            marginLeft: '2rem',
            width: '182px',
          }}>
          {variantName}
        </div>
      </FlexRow>

      <Box sx={{ padding: '2rem' }}>
        <FlexRow padding={'1rem'} flexDirection={'row'} justify={'flex-start'} rowBg={'#fff'}>
          <Paper elevation={3} sx={{ padding: '1rem' }}>
            <div style={{ height: 'auto', position: 'relative' }}>
              <ImageOrBase64
                fileId={_.get(variant, `Pictures.Image[0]['@fileId']`, null)}
                fileUUID={fileUUID}
                product={product}
                GLDFBase64FileArray={GLDFBase64FileArray}
              />
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  position: 'absolute',
                  top: '5px',
                  color: 'white',
                  right: '5px',
                  background: '#dea500',
                }}
                onClick={() => handleClickOpen('Img64')}>
                <OpenInNew sx={{ color: '#fff', marginLeft: '6px', marginTop: '5px' }} />
              </div>
            </div>
          </Paper>
          <VariantInfo selectedEquipment={selectedEquipment} product={product} selectedVariant={variant} />
          <div style={{ marginLeft: '2rem' }}>
            <Paper elevation={3} sx={{ padding: '2rem', marginRight: '2rem', minWidth: '200px' }}>
              <div style={{ height: 'auto', position: 'relative' }}>
                {geometryId?.url?.includes('undefined') && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      minWidth: '360px',
                      minHeight: '220px',
                      background: '#5555558a',
                    }}>
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '1.5rem',
                      }}>
                      Geometry screenshot not available
                    </div>
                  </div>
                )}

                {geometryId.url.length && !geometryId.url.includes('undefined') && (
                  <>
                    <img style={{ maxWidth: '360px', minHeight: '150px', background: '#8080801a' }} src={geometryId.url} alt={'geometry'} />
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                        borderRadius: '20px',
                        position: 'absolute',
                        top: '5px',
                        color: 'white',
                        right: '5px',
                        background: '#dea500',
                      }}
                      onClick={() => handleClickOpen('Geo', geometryId.url)}>
                      <OpenInNew style={{ color: '#fff', marginLeft: '6px', marginTop: '5px' }} />
                    </div>
                  </>
                )}
              </div>
            </Paper>
          </div>
        </FlexRow>

        {variantEmitters.map((variantEmitter: any, idx: number) => {
          return (
            <EmitterRow
              key={`emitterRow${idx}`}
              product={product}
              variantEmitter={variantEmitter}
              variantEmitters={variantEmitters}
              idx={idx}
              APIURL={APIURL}
              fileUUID={fileUUID}
              handleClickOpen={handleClickOpen}
            />
          );
        })}
      </Box>

      {/* Save Snackbar */}

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBarOpen}
        onClose={() => {
          setSnackbarState({ snackBarOpen: true, vertical: 'top', horizontal: 'center', success: success });
        }}
        key={vertical + horizontal}>
        <Alert
          onClose={closeSnackbar}
          severity={success ? 'success' : 'error'}
          sx={{ width: '100%', backgroundColor: success ? '#2e7d32' : '#d32f2f' }}>
          {success ? 'GLDF has been saved' : 'GLDF has not been saved'}
        </Alert>
      </Snackbar>

      {/* Image Dialog */}

      <Dialog onClose={handleClose} open={openDialog} maxWidth={'lg'}>
        <DialogTitle style={{ background: '#113b6f', color: 'white' }}>Resource</DialogTitle>
        <Divider />
        {assetURL !== '' && !typeGeo && <img src={assetURL} style={{ width: '100%' }} />}
        {typeImg64 && (
          <ImageOrBase64
            wideDisplay
            fileId={_.get(variant, `Pictures.Image[0]['@fileId']`, null)}
            fileUUID={fileUUID}
            product={product}
            GLDFBase64FileArray={GLDFBase64FileArray}
          />
        )}
        {typeGeo && (
          <iframe
            id="geometryFrame"
            frameBorder="0"
            style={{ width: '900px', height: '900px' }}
            src={`${L3DVIEWERURL}?uuid=${fileUUID}&filename=${getActiveGeometryReference(product, variant)}`}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default MainBox;

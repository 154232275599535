import * as React from 'react';
import classNames from 'classnames';
import {Button, Switch} from '@mui/material';
import './style.scss';

const PlainBoolean = ({ mode, path, defaultValue, elementName, classes, elementData, debugElement, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (typeof elementData !== 'undefined') {
    const plainStringClassNames = classNames('plain-boolean', classes);
    return (
      <>
        <Switch
          size={"small"}
          disabled={mode === 'displayMode' || !mode}
          checked={elementData}
          onChange={(e) => {
            changeAttribute(path, e.target.checked);
          }}
        />
      </>
    );
  }
    return (
        <>
            <Button
                sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0'}}
                onClick={() => {
                    changeAttribute(path, defaultValue, true);
                }}>
                ADD
            </Button>
        </>
    );

};

export default PlainBoolean;

import * as React from 'react';
import classNames from 'classnames';
import { Button } from '@mui/material';
import { Bolt } from '@mui/icons-material';
import { PlainString, SelectList } from '..';
import { blockSubGrouping } from '../../../css/theme';
import * as _ from 'lodash';

const StringArray = ({
  requiredField,
  mode,
  product,
  path,
  elementName,
  classes,
  elementData,
  validationConf,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  deleteAttribute,
  debugElement,
  isSelectList,
  availableListItems,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  if (Array.isArray(elementData)) {
    const plainStringClassNames = classNames('string-array', classes);
    if (_.isNil(isSelectList)) {
      return (
        <div style={blockSubGrouping} className={plainStringClassNames}>
          {elementData.map((element: any, idx: number) => {
            return (
              <div key={`${elementName}-${idx}`}>
                <PlainString
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}]`}
                  validationConf={validationConf}
                  elementName={`${elementName}-${idx}`}
                  validationErrors={validationErrors}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={element}
                />
                <Button
                  sx={{ border: '#ff0000 1px solid', width: '99px', color: '#fff', backgroundColor: '#ff00003b', lineHeight: '0' }}
                  onClick={() => {
                    console.log('handle delete', path, idx);
                    deleteAttribute(path, true, idx);
                  }}>
                  Delete
                </Button>
              </div>
            );
          })}
          <Button
            sx={{ border: '#00ff45 1px solid', marginTop: '.5rem', color: '#fff', backgroundColor: '#15ff0021', lineHeight: '0' }}
            onClick={() => {
              const updatedData = _.cloneDeep(elementData);
              updatedData.push('empty');
              changeAttribute(path, updatedData, false, false);
            }}>
            ADD ANOTHER
          </Button>
        </div>
      );
    } else {
      return (
        <div style={blockSubGrouping} className={plainStringClassNames}>
          {elementData.map((element: any, idx: number) => {
            return (
              <div key={`${elementName}-${idx}`}>
                <SelectList
                  requiredField={requiredField}
                  validationConf={validationConf}
                  mode={mode}
                  availableListItems={availableListItems}
                  product={product}
                  path={`${path}[${idx}]`}
                  classes=""
                  elementName={`${elementName}-${idx}`}
                  validationErrors={validationErrors}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={element}
                />
                <Button
                  sx={{ border: '#ff0000 1px solid', width: '99px', color: '#fff', backgroundColor: '#ff00003b', lineHeight: '0' }}
                  onClick={() => {
                    console.log('handle delete', path, idx);
                    deleteAttribute(path, true, idx);
                  }}>
                  Delete
                </Button>
              </div>
            );
          })}
          <Button
            sx={{ border: '#00ff45 1px solid', marginTop: '.5rem', color: '#fff', backgroundColor: '#15ff0021', lineHeight: '0' }}
            onClick={() => {
              const updatedData = _.cloneDeep(elementData);
              updatedData.push(availableListItems[0]);
              changeAttribute(path, updatedData, false, false);
            }}>
            ADD ANOTHER
          </Button>
        </div>
      );
    }
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0' }}
          onClick={() => {
            if (_.isNil(isSelectList)) {
              changeAttribute(path, ['_empty_'], true, true);
            } else {
              changeAttribute(path, [availableListItems[0]], true, true);
            }
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default StringArray;

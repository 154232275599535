import * as React from 'react';
import classNames from 'classnames';
import { Button } from '@mui/material';
import { LocaleString } from '..';
import * as _ from 'lodash';

const LocaleArray = ({ mode, product, path, elementName, classes, elementData, elementSelectorName, debugElement, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, elementSelectorName, debugElement);

  if (Array.isArray(elementData)) {
    const plainStringClassNames = classNames('locale-array', classes);
    return (
      <span className={plainStringClassNames}>
        {elementData.map((element: any, idx: number) => {
          return (
            <div style={{ margin: '10px' }} key={`${elementName}-${idx}`}>
              {
                <span>
                  {typeof elementSelectorName !== 'undefined' ? `${elementSelectorName}: ${element[`@${elementSelectorName}`]} - ` : null}
                  Value:
                  <LocaleString
                    mode={mode}
                    path={`${path}[${idx}].Locale`}
                    key={`${elementName}-${idx}-locale`}
                    classes="element-locale-text"
                    elementName={`${elementName}-Locale`}
                    debugElement={debugElement}
                    elementData={_.get(product, `${path}[${idx}].Locale`)}
                    changeAttribute={changeAttribute}
                  />
                </span>
              }
            </div>
          );
        })}
      </span>
    );
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0'}}
          onClick={() => {
            changeAttribute(path, [{[`@${elementSelectorName}`]:[{ $: '_empty_', '@language': 'zz' }]}], true, true);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default LocaleArray;

import * as React from "react";
import Modal from '@mui/material/Modal';
import "./style.scss";

const FlexModal = (props: {
    children: any,
    closeModal: any,
    isModalOpen?: boolean,
    isUploadModalOpen?: boolean,
}) => {

    const handleClose = (event: any, reason: string) => {
        if (reason && reason == "backdropClick")
            return;
        props.closeModal;
    };

    return (
        <Modal
            open={props.isModalOpen || props.isUploadModalOpen || false}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            {props.children}
        </Modal>
    )
}

export default FlexModal;
import * as React from 'react';
import {Button, Chip, Paper} from '@mui/material';
import {FlexRow, VariantSelectedLightSource} from '../../';
import {getActiveLightDistribution, getActiveSensorDistribution, getVariantEmitterLightEmittersOrSensors, getLightSource} from '../../../../util';
import * as _ from 'lodash';
import './style.scss';
import {OpenInNew} from "@mui/icons-material";

const EmitterRow = ({product, variantEmitters, variantEmitter, idx, APIURL, fileUUID, handleClickOpen}: any) => {
  const [selectedLightEmitter, setSelectedLightEmitter] = React.useState(0);
  let photometryURL: string | null | undefined = null;
  let selectedLightSource = null;
  const variantLightOrSensorEmitters: any[] = getVariantEmitterLightEmittersOrSensors(variantEmitters[idx], product);
  
  let currentPhotometryReferenceId = _.get(variantLightOrSensorEmitters[selectedLightEmitter], `PhotometryReference['@photometryId']`);
  if (_.isNil(currentPhotometryReferenceId)){
    //It's a sensor emitter
    const currentSensorId = _.get(variantLightOrSensorEmitters[selectedLightEmitter], '@sensorId');
    photometryURL = `${APIURL}${fileUUID}/gldf/ldc/${getActiveSensorDistribution(product, currentSensorId)}.png`;
  } else {
    photometryURL = `${APIURL}${fileUUID}/gldf/ldc/${getActiveLightDistribution(product, currentPhotometryReferenceId)}.png`;
    const currentLightSourceReferenceId = _.get(variantLightOrSensorEmitters[selectedLightEmitter], `LightSourceReference['@fixedLightSourceId']`);
    selectedLightSource = getLightSource(product, currentLightSourceReferenceId);
  }
  

  const handleChangeLightSource = (newValue: string) => {
    setSelectedLightEmitter(Number.parseInt(newValue));
  };

  React.useEffect(() => {
    setSelectedLightEmitter(0);
  }, [variantEmitter]);

  return (
    <>
      <FlexRow key={`variantEmitter-${idx}`} padding={'1rem'} flexDirection={'row'} justify={'flex-start'} rowBg={'#fff'}>
        <div>
          {variantLightOrSensorEmitters.map((lightEmitter: any, idx2: any) => {
            let selected;
            if (idx2 === selectedLightEmitter) {
              selected = true;
            }
            return (
              <Button
                sx={{ height: '27px' }}
                style={{ background: selected ? '#0074ff' : '#fff', color: selected ? 'white' : 'black' }}
                variant={'outlined'}
                key={`lightEmitter-${idx2}`}
                 onClick={(e) => {
                   e.preventDefault();
                   e.stopPropagation();
                   handleChangeLightSource(idx2);
                 }}>
                {_.get(lightEmitter, `LightSourceReference['@fixedLightSourceId']`, idx2)}
              </Button>
            );
          })}
        </div>
      </FlexRow>
      <FlexRow key={`variantEmitter-2-${idx}`} padding={'1rem'} flexDirection={'row'} justify={'flex-start'}  rowBg={'#fff'}>
        <Paper elevation={3} sx={{padding: '2rem', marginRight: '2rem', minWidth: 'inherit'}}>
          <div style={{height: 'auto', position: 'relative'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
              <div style={{ color: '#113b6f', fontWeight: '700' }}>Emitter Name </div>
              <div> {_.get(variantEmitter, 'EmitterObjectExternalName', _.get(variantEmitter, '@emitterId', idx))}</div>
            </div>
            <div>
              <img style={{maxWidth: '230px'}} src={photometryURL} alt={'photometry'}/>
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  position: 'absolute',
                  top: '45px',
                  color: 'white',
                  right: '5px',
                  background: '#dea500',
                }}
                onClick={() => handleClickOpen('Img', photometryURL)}>
                <OpenInNew style={{ color: '#fff', marginLeft: '6px', marginTop: '5px' }} />
              </div>
            </div>
            {/* <Button
            style={{ position: 'absolute', bottom: '0', right: '0', background: '#0074ff3d' }}
            size={'small'}
            variant="text"
            onClick={() => handleClickOpen('Img', photometryURL)}>
            <OpenInNew style={{ color: '#0074ff' }} />
          </Button>*/}
          </div>
        </Paper>
        {selectedLightSource && (
          <VariantSelectedLightSource
            variantLightEmitters={variantLightOrSensorEmitters}
            selectedLightSource={selectedLightSource}
            selectedLightEmitter={selectedLightEmitter}
          />
        )}
      </FlexRow>

    </>
  );
};

export default EmitterRow;

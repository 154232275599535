import * as React from "react";
import { item, categories } from "./const";
import "./style.scss";

import * as _ from "lodash";

import {useEffect, useState} from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Badge,
  Box, Button
} from "@mui/material";

import {ErrorOutline} from "@mui/icons-material";

import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { ExpandMore } from "@mui/icons-material";
import { BlockRenderer } from "../../index";
import { variantsConf } from "../../../conf";

const FlexDrawer = ({
  debugEnabled,
  setSelectedVariant,
  isModalOpen,
  setModalOpen,
  setUploadModalOpen,
  setModalView,
  drawerOpen,
  openModal,
  product,
  validationErrors,
  setValidationErrors,
  changeAttribute,
}: any) => {
  const [isShown, setIsShown] = useState("");

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };



  const checkIfPathsExistInValidationErrors = (
      paths: string[],
      errors: string[]
  ) => {
    return paths
        .map((path: string) => checkIfPathExistsInValidationErrors(path, errors))
        .reduce((i, j) => {
          return i || j;
        }, false);
  };

  const checkIfPathExistsInValidationErrors = (
      path: string,
      errors: string[]
  ) => {
    return errors
        .map((i) => _.startsWith(i, path))
        .reduce((i, j) => {
      return i || j;
    }, false)
  };

  const countValidationErrorsIfPathExists = (
      path: string,
      errors: string[]
  ) => {
    return errors.map((i) => _.startsWith(i, path)).filter(Boolean).length;
  };

  return (
    <Box component="nav"  sx={{ display: drawerOpen ? 'block' : 'none', width: { sm: 300 }, flexShrink: { sm: 0 } }}>
      <div className="flex-drawer">
      <Drawer sx={{ width: '300px' }} variant="persistent" open={drawerOpen} >
          <List disablePadding={true}>
            <Box sx={{ bgcolor: "#101F33" }}>
              <BlockRenderer
                debugElements={debugEnabled}
                mode={"listMode"}
                product={product}
                blockConf={variantsConf}
                setSelectedVariant={setSelectedVariant}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                changeAttribute={changeAttribute}
              />
            </Box>
          </List>
          <List disablePadding sx={{ marginTop: "auto" }}>
            <Button sx={{ height: '30px', color: 'black', bgcolor: '#dea500', margin: '1rem'}} variant="outlined" size={'small'} onClick={() => setUploadModalOpen(true)}>
              Upload Files
            </Button>
            {categories.map(({ id, children, basePaths }, catIdx) => (
              <Box key={id} sx={{ bgcolor: "#101F33" }}>
                <Accordion
                  expanded={expanded === "panel" + catIdx}
                  onChange={handleChange("panel" + catIdx)}
                  disableGutters={true}
                >
                  <AccordionSummary
                    sx={{ bgcolor: "#101F33", color: "#fff", alignItems: "center" }}
                    expandIcon={<ExpandMore color={"info"} />}
                  >
                    {checkIfPathsExistInValidationErrors(
                      basePaths,
                      validationErrors
                    ) && (
                      <ListItemIcon>
                        <ErrorOutline style={{ color: "#ef5350" }} />
                      </ListItemIcon>
                    )}
                    <ListItem sx={{ py: 2, px: 3 }}>
                      <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
                    </ListItem>
                  </AccordionSummary>
                  <AccordionDetails>
                    {children.map(({ id: id, icon, MenuConf, MenuSubConf, basePath },
                        index ) => (
                        <ListItem
                          disablePadding
                          key={id}
                          selected={false}
                          onMouseEnter={() =>
                            setIsShown(String(String(index) + String(catIdx)))
                          }
                          onMouseLeave={() => setIsShown("")}
                        >
                          <ListItemButton
                            sx={item}
                            onClick={() => {
                              setModalView({
                                activeMenuConf: MenuConf,
                                activeMenuSubConf: MenuSubConf,
                              });
                              openModal(
                                "editMode",
                                MenuConf,
                                MenuSubConf,
                                isModalOpen,
                                setModalOpen,
                                setModalView
                              );
                            }}
                          >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText>{id}</ListItemText>
                            {checkIfPathExistsInValidationErrors(
                              basePath,
                              validationErrors
                            ) && (
                              <ListItemIcon>
                                  <Badge badgeContent={countValidationErrorsIfPathExists(basePath, validationErrors)} />
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                  </AccordionDetails>
                  <Divider />
                </Accordion>
              </Box>
            ))}
          </List>
        </Drawer>
      </div>
    </Box>
  );
};

export default FlexDrawer;

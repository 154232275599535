import * as React from 'react';
import { PlainString, PlainEmail } from '..';
import * as _ from 'lodash';
import './style.scss';

const ContactAddress = ({ product, path, parentElement, elementName, elementData, debugElement, mode, changeAttribute }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          debugElement && console.log('ELEMENT', element, `${parentElement.displayElementPath}[${idx}].FirstName`);
          return (
              <div key={`contactAddress-${idx}`}>
                {['FirstName', 'Name', 'Street', 'City', 'ZIPCode', 'Country', 'Phone'].map((field: string) => (
                    <div key={`contactAddress-${idx}-${field}`}>
                      <div style={{margin: '10px'}}>{field}</div>
                      <PlainString
                          mode={mode}
                          path={`${parentElement.displayElementPath}[${idx}].${field}`}
                          classes={[`contact-address-string`, `contact-address-string-${field}`]}
                          elementName={`${parentElement.displayElementName}-${field}`}
                          debugElement={debugElement}
                          elementData={_.get(product, `${parentElement.displayElementPath}[${idx}].${field}`)}
                          changeAttribute={changeAttribute}
                      />
                    </div>
                ))}
                <div key={`contactAddress-${idx}-emailAddresses`}>
                  <div style={{margin: '10px'}} >E-mail</div>
                  {element.EMailAddresses.EMail.map((emailElement: any, idx2: number) => (
                      <PlainEmail
                          mode={mode}
                          path={`${parentElement.displayElementPath}[${idx}].EMailAddresses.EMail[${idx2}]`}
                          classes={[`contact-address-email-string`]}
                          elementName={`emailAddress-${idx}`}
                          debugElement={debugElement}
                          elementData={emailElement}
                          changeAttribute={changeAttribute}
                      />
                  ))}
                </div>
              </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default ContactAddress;

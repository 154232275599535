import * as React from 'react';
import { Range, PlainNumber, PlainString, SelectList, PlainBoolean } from '../..';
import * as _ from 'lodash';
import { blockTitleStyle } from '../../../../css/theme';
import { Validators } from '../../../../consts';

const Electrical = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  validationErrors,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  debugElement,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  return (
    <>
      <h3 style={blockTitleStyle}>Electrical</h3>
      <div style={{ margin: '10px' }}>ClampingRange</div>
      <Range
        mode={mode}
        isFloat={true}
        product={product}
        path={`${path}.Electrical.ClampingRange`}
        elementName="ClampingRange"
        setValidationErrorsHandler={setValidationErrorsHandler}
        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Electrical.ClampingRange`)}
      />
      <div style={{ margin: '10px' }}>Switching Capacity</div>
      <PlainString
        mode={mode}
        product={product}
        path={`${path}.Electrical.SwitchingCapacity`}
        elementName="SwitchingCapacity"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Electrical.SwitchingCapacity`)}
      />
      <div style={{ margin: '10px' }}>Electrical Safety Class</div>
      <SelectList
        mode={mode}
        availableListItems={['0', 'I', '0I', 'II', 'III']}
        product={product}
        path={`${path}.Electrical.ElectricalSafetyClass`}
        key={`${elementName}-ElectricalSafetyClass`}
        classes="lightEmitter-ElectricalSafetyClass-class"
        elementName="ElectricalSafetyClass"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        defaultValue="II"
        elementData={_.get(product, `${path}.Electrical.ElectricalSafetyClass`)}
      />
      <div style={{ margin: '10px' }}>Ingress Protection IP Code</div>
      <SelectList
        mode={mode}
        availableListItems={[
          'IP20',
          'IP21',
          'IP22',
          'IP23',
          'IP24',
          'IP25',
          'IP26',
          'IP27',
          'IP28',
          'IP30',
          'IP31',
          'IP32',
          'IP33',
          'IP34',
          'IP35',
          'IP36',
          'IP37',
          'IP38',
          'IP40',
          'IP41',
          'IP42',
          'IP43',
          'IP44',
          'IP45',
          'IP46',
          'IP47',
          'IP48',
          'IP50',
          'IP51',
          'IP52',
          'IP53',
          'IP54',
          'IP55',
          'IP56',
          'IP57',
          'IP58',
          'IP60',
          'IP61',
          'IP62',
          'IP63',
          'IP64',
          'IP65',
          'IP66',
          'IP67',
          'IP68',
          'IP69',
          'IP69K',
        ]}
        product={product}
        path={`${path}.Electrical.IngressProtectionIPCode`}
        key={`${elementName}-IngressProtectionIPCode`}
        classes="lightEmitter-IngressProtectionIPCode-class"
        elementName="IngressProtectionIPCode"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        defaultValue="IP30"
        elementData={_.get(product, `${path}.Electrical.IngressProtectionIPCode`)}
      />
      <div style={{ margin: '10px' }}>PowerFactor</div>
      <PlainNumber
        mode={mode}
        isFloat={true}
        product={product}
        path={`${path}.Electrical.PowerFactor`}
        elementName="PowerFactor"
        setValidationErrorsHandler={setValidationErrorsHandler}
        unSetValidationErrorsHandler={unSetValidationErrorsHandler}
        validationConf={[
          {
            validator: Validators.range,
            minValue: 0,
            maxValue: 1,
            isInteger: false,
          },
        ]}
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        elementData={_.get(product, `${path}.Electrical.PowerFactor`)}
      />
      <div style={{ margin: '10px' }}>ConstantLightOutput</div>
      <PlainBoolean
        mode={mode}
        product={product}
        path={`${path}.Electrical.ConstantLightOutput`}
        elementName="ConstantLightOutput"
        debugElement={debugElement}
        changeAttribute={changeAttribute}
        defaultValue={false}
        elementData={_.get(product, `${path}.Electrical.ConstantLightOutput`)}
      />
      {debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null}
    </>
  );
};

export default Electrical;

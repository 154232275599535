import * as _ from 'lodash';
import { splitParentPath, getOrder } from '../util';

interface IOut<T> {
  [id: string]: T;
}

export const deleteAttributeCallback = (product: any, path: any, isArray: boolean, index: number, callBackFn: any) => {
  const tmp = Object.assign({}, product);
  if (_.isNil(isArray)) {
    _.unset(product, path);
  } else {
    const tmpArray = _.get(product, path);
    _.pullAt(tmpArray, index);
    if (tmpArray.length === 0) {
      const parentPathAndCurrentObjectName = splitParentPath(path, false);
      const { path: parentPath } = parentPathAndCurrentObjectName;
      _.unset(product, parentPath);  
    } else {
      _.set(product, path, tmpArray);
    }
  }
  callBackFn(tmp);
};

export const changeAttributeCallback = (
  product: any,
  callBackFn: any,
  path: any,
  data: any,
  isAddElement: boolean | undefined,
  isArrayAttribute: boolean | undefined,
  debugEnabled: boolean
) => {
  debugEnabled && console.log('product', product, 'path', path, 'data', data);
  debugEnabled &&
    console.log(
      'changeAttributeProps:',
      'product: ',
      product,
      'path',
      path,
      'data',
      data,
      'isAddElement',
      isAddElement,
      'isArrayAttribute',
      isArrayAttribute
    );

  const tmp = Object.assign({}, product);
  if (!_.isNil(isAddElement) && isAddElement) {
    // handle ADD LOGIC

    // get the path
    const parentPathAndCurrentObjectName = splitParentPath(path, isArrayAttribute);
    const { path: parentPath, last: currentObjectName } = parentPathAndCurrentObjectName;
    // get the parent Object and get ready to reconstruct it
    const partialData = _.get(product, parentPath);
    console.log('partialData', partialData);
    // reiterate the parentObject

    // first find what are talking about
    if (parentPath === '') {
      // something is wrong abort
    } else {
      //get the order
      let out: IOut<any> = {};
      const orderArray = getOrder(parentPath);
      if (Array.isArray(orderArray)) {
        //reconstruct the partialData
        orderArray.map((element: string) => {
          let tmpOut = _.get(partialData, element);
          if (element === currentObjectName) {
            //handle case we've got a Locale object
            if (!_.isNil(isArrayAttribute) && isArrayAttribute) {
              let currentObjectNameArray = currentObjectName.split('.');
              //ToDo: add a traversal function in the future
              //Note: we now support two level
              out[currentObjectNameArray[0]] = {};
              out[currentObjectNameArray[0]][currentObjectNameArray[1]] = data;
            } else {
              out[element] = data;
            }
          }
          if (!_.isNil(tmpOut) && element !== currentObjectName) {
            if (element.indexOf('.') > -1) {
              let currentObjectNameArray = element.split('.');
              //ToDo: add a traversal function in the future
              //Note: we now support two level
              out[currentObjectNameArray[0]] = {};
              out[currentObjectNameArray[0]][currentObjectNameArray[1]] = tmpOut;
            } else {
              out[element] = tmpOut;
            }
          }
        });
        console.log(out);
        console.log(JSON.stringify(out));
        _.set(tmp, parentPath, out);
        // if it's an object that needs full substitution like Mountings
      } else {
        _.set(tmp, path, data);
      }
    }
  } else {
    _.set(tmp, path, data);
  }

  callBackFn(tmp);
};

export const getFileIds = (product: any) => {
  let existingFiles = _.get(product, 'GeneralDefinitions.Files.File');
  return existingFiles.map((fileItem: any) => {
    return fileItem['@id'];
  });
};

//ToDo: support changeable light sources
export const getLightSourceIds = (product: any): string[] => {
  let lightSourceArray = _.get(product, 'GeneralDefinitions.LightSources.FixedLightSource');
  if (!Array.isArray(lightSourceArray)) {
    return [];
  }
  return lightSourceArray.map((lightSourceArrayItem: any) => lightSourceArrayItem['@id']);
};

export const getControlGearIds = (product: any): string[] => {
  let controlGearArray = _.get(product, 'GeneralDefinitions.ControlGears.ControlGear');
  if (!Array.isArray(controlGearArray)) {
    return [];
  }
  return controlGearArray.map((controlGearItem: any) => controlGearItem['@id']);
};

export const getPhotometryIds = (product: any): string[] => {
  let photometriesArray = _.get(product, 'GeneralDefinitions.Photometries.Photometry');
  if (!Array.isArray(photometriesArray)) {
    return [];
  }
  return photometriesArray.map((photometryItem: any) => photometryItem['@id']);
};

export const getEquipmentIds = (product: any): string[] => {
  let equipmentsArray = _.get(product, 'GeneralDefinitions.Equipments.Equipment');
  if (!Array.isArray(equipmentsArray)) {
    return [];
  }
  return equipmentsArray.map((equipmentItem: any) => equipmentItem['@id']);
};

export const getEmitterIds = (product: any): string[] => {
  let emitterArray = _.get(product, 'GeneralDefinitions.Emitters.Emitter');
  if (!Array.isArray(emitterArray)) {
    return [];
  }
  return emitterArray.map((emitterItem: any) => emitterItem['@id']);
};

export const getGeometryIds = (product: any): string[] => {
  //ToDo: Support other SimpleGeometries
  let geometryArray = _.get(product, 'GeneralDefinitions.Geometries.ModelGeometry');
  return geometryArray.map((geometryItem: any) => geometryItem['@id']);
};

export const getPhotometryFileIds = (product: any): string[] => {
  let photometryFilesArray = _.get(product, 'GeneralDefinitions.Files.File');
  return photometryFilesArray
    .filter((photometryFilesItem: any) => {
      return photometryFilesItem['@contentType'] === 'ldc/ies' || photometryFilesItem['@contentType'] === 'ldc/eulumdat';
    })
    .map((photometryFilesItem: any) => photometryFilesItem['@id']);
};

export const getGeometryFileIds = (product: any): string[] => {
  let geometryFilesArray = _.get(product, 'GeneralDefinitions.Files.File');
  return geometryFilesArray
    .filter((geometryFilesArrayItem: any) => {
      return (
        geometryFilesArrayItem['@contentType'] === 'geo/l3d' ||
        geometryFilesArrayItem['@contentType'] === 'geo/m3d' ||
        geometryFilesArrayItem['@contentType'] === 'geo/r3d'
      );
    })
    .map((geometryFilesArrayItem: any) => geometryFilesArrayItem['@id']);
};

export const getPictureFileIds = (product: any): string[] => {
  let picturesFilesArray = _.get(product, 'GeneralDefinitions.Files.File');
  return picturesFilesArray
    .filter((picturesFilesArrayItem: any) => {
      return (
        picturesFilesArrayItem['@contentType'] === 'image/jpg' ||
        picturesFilesArrayItem['@contentType'] === 'image/svg' ||
        picturesFilesArrayItem['@contentType'] === 'image/png'
      );
    })
    .map((picturesFilesArrayItem: any) => picturesFilesArrayItem['@id']);
};

import * as React from "react";
import * as _ from "lodash";

import {
  LocaleString,
  Changeable,
  ColorInformation,
  Reference,
  Image,
  PlainString,
} from "..";
import {blockSubGrouping, blockTopGrouping} from "../../../css/theme";

const LightSources = ({
  mode,
  product,
  path,
  elementName,
  elementData,
  debugElement,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (Array.isArray(elementData)) {
    return (
        <>
          {elementData.map((element: any, idx: number) => {
            return (
                <div key={`lightSources-${idx}`}>
                  <PlainString
                      mode={mode}
                      product={product}
                      path={`${path}[${idx}]['@id']`}
                      key={`${elementName}-${idx}-ID`}
                      classes="lightSource-ID-class"
                      elementName={`${elementName}-lightsource-${idx}-ID`}
                      debugElement={debugElement}
                      changeAttribute={changeAttribute}
                      elementData={element['@id']}
                  />
                      <>
                        <div style={blockSubGrouping}>
                          <div style={{margin: '10px'}}>Name</div>
                          <LocaleString
                              mode={mode}
                              product={product}
                              path={`${path}[${idx}].Name.Locale`}
                              referencePrefix="name"
                              key={`${elementName}-Name`}
                              classes="lightSource-Locale-class"
                              elementName={`${elementName}-LightSource-Locale`}
                              debugElement={debugElement}
                              changeAttribute={changeAttribute}
                              elementData={_.get(product, `${path}[${idx}].Name.Locale`)}
                          />
                        </div>
                      </>
                      <>
                        <div style={blockSubGrouping}>
                          <div style={{margin: '10px'}}>Description</div>
                          <LocaleString
                              mode={mode}
                              product={product}
                              path={`${path}[${idx}].Description.Locale`}
                              referencePrefix="name"
                              key={`${elementName}-Description`}
                              classes="lightSource-Description-class"
                              elementName={`${elementName}-LightSource-Description`}
                              debugElement={debugElement}
                              changeAttribute={changeAttribute}
                              elementData={_.get(
                                  product,
                                  `${path}[${idx}].Description.Locale`
                              )}
                          />
                        </div>
                      </>
                  {/* {element.Changeable && (
                      <>
                        <div style={{margin: '10px'}}>Changeable</div>
                        <Changeable
                            mode={mode}
                            product={product}
                            path={`${path}[${idx}].Changeable`}
                            referencePrefix="name"
                            key={`${elementName}-Changeable`}
                            classes="lightSource-Changeable-class"
                            elementName={`${elementName}-LightSource-Changeable`}
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            setValidationErrorsHandler={setValidationErrorsHandler}
                            unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                            elementData={_.get(product, `${path}[${idx}].Changeable`)}
                        />
                      </>
                  )}
                  {element.SpectrumReference && (
                      <>
                        <div style={{margin: '10px'}}>Spectrum Reference</div>
                        <Reference
                            mode={mode}
                            product={product}
                            path={`${path}[${idx}].SpectrumReference`}
                            referencePrefix="spectrum"
                            key={`${elementName}-SpectrumReference`}
                            classes="lightSource-SpectrumReference-class"
                            elementName={`${elementName}-LightSource-SpectrumReference`}
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            elementData={_.get(
                                product,
                                `${path}[${idx}].SpectrumReference`
                            )}
                        />
                      </>
                  )}
                  {element.PhotometryReference && (
                      <>
                        <div style={{margin: '10px'}}>Photometry Reference</div>
                        <Reference
                            mode={mode}
                            product={product}
                            path={`${path}[${idx}].PhotometryReference`}
                            referencePrefix="photometry"
                            key={`${elementName}-PhotometryReference`}
                            classes="lightSource-PhotometryReference-class"
                            elementName={`${elementName}-LightSource-PhotometryReference`}
                            debugElement={debugElement}
                            changeAttribute={changeAttribute}
                            elementData={_.get(
                                product,
                                `${path}[${idx}].PhotometryReference`
                            )}
                        />
                      </>
                  )}
                  {element.ActivePowerTable && (
                      <>
                        <div style={{margin: '10px'}}>Active Power Table</div>
                        <div style={{margin: '10px'}}>Type | <strong>{element.ActivePowerTable["@type"]}</strong></div>
                        <div style={blockTopGrouping}>
                          {element.ActivePowerTable.FluxFactor.map(
                              (fluxFactor: any, idx2: number) => {
                                return (
                                    <>
                                      <>
                                        <div style={blockSubGrouping}>Flux Factor</div>
                                        <Reference
                                            mode={mode}
                                            product={product}
                                            path={`${path}[${idx}].ActivePowerTable.FluxFactor[${idx2}]`}
                                            referencePrefix=""
                                            customFields={[
                                              "inputPower",
                                              "flickerPstLM",
                                              "stroboscopicEffectsSVM",
                                              "description",
                                            ]}
                                            key={`${elementName}-ActivePowerTable-FluxFactor`}
                                            classes="lightSource-ActivePowerTable-FluxFactor-class"
                                            elementName={`${elementName}-LightSource-ActivePowerTable-FluxFactor`}
                                            debugElement={debugElement}
                                            changeAttribute={changeAttribute}
                                            elementData={_.get(
                                                product,
                                                `${path}[${idx}].ActivePowerTable.FluxFactor[${idx2}]`
                                            )}
                                        />
                                      </>
                                    </>
                                );
                              }
                          )}
                        </div>
                      </>
                  )} */}
                  {element.ColorInformation && (
                      <>
                        <div style={blockSubGrouping}>
                          <div style={{margin: '10px'}}>Color Information</div>
                          <div style={blockTopGrouping}>
                            <ColorInformation
                                mode={mode}
                                product={product}
                                path={`${path}[${idx}].ColorInformation`}
                                key={`${elementName}-ColorInformation`}
                                classes="lightSource-ColorInformation-class"
                                setValidationErrorsHandler={setValidationErrorsHandler}
                                unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                                elementName={`${elementName}-LightSource-ColorInformation`}
                                debugElement={debugElement}
                                changeAttribute={changeAttribute}
                                elementData={_.get(
                                    product,
                                    `${path}[${idx}].ColorInformation`
                                )}
                            />
                            </div>
                        </div>
                      </>
                  )}
                  {/* {element.LightSourceImages && (
                      <>
                        <div style={{margin: '10px'}}>LightSourceImages</div>
                        <Image
                            product={product}
                            path={`${path}[${idx}].LightSourceImages.Image`}
                            key={`${elementName}-LightSourceImages`}
                            classes="lightSource-LightSourceImages-class"
                            elementName={`${elementName}-LightSource-LightSourceImages`}
                            debugElement={debugElement}
                            elementData={_.get(
                                product,
                                `${path}[${idx}].LightSourceImages.Image`
                            )}
                        />
                      </>
                  )} */}
                  <hr/>
                </div>
            );
          })}
        </>
    );
  }
  return debugElement ? (
    <strong
      style={{ color: "#f68f9c" }}
    >{`Element: ${elementName} could not be found`}</strong>
  ) : null;
};

export default LightSources;

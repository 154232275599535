import * as React from 'react';
import * as _ from 'lodash';
import { LocaleString, SelectList } from '..';
import { getPhotometryIds, getLightSourceIds, getControlGearIds, emergencyBehaviorList } from '../../../util';
import { blockSubGrouping } from '../../../css/theme';
import { Validators } from '../../../consts';
import PlainNumber from '../PlainNumber';

const EmittersFixedLightEmitter = ({
  mode,
  path,
  product,
  elementName,
  elementData,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  changeAttribute,
  debugElement,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (elementData) {
    return (
      <>
        {elementData.map((element: any, idx: number) => {
          return (
            <div style={blockSubGrouping} key={`emitters-fixedlightemitter-${idx}`}>
              <>
                <div style={blockSubGrouping}>
                  <div style={{ margin: '10px' }}>Display Name</div>
                  <LocaleString
                    mode={mode}
                    product={product}
                    path={`${path}[${idx}].Name.Locale`}
                    key={`${elementName}-locale`}
                    classes="fixedLightEmitter-Name-class"
                    elementName={`${elementName}-Name`}
                    setValidationErrorsHandler={setValidationErrorsHandler}
                    unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                    debugElement={debugElement}
                    changeAttribute={changeAttribute}
                    elementData={_.get(product, `${path}[${idx}].Name.Locale`)}
                  />
                </div>
                <div style={{ margin: '10px' }}>Photometry ID</div>
                <SelectList
                  mode={mode}
                  requiredField
                  availableListItems={getPhotometryIds(product)}
                  product={product}
                  path={`${path}[${idx}].PhotometryReference['@photometryId']`}
                  key={`${elementName}-photometryID`}
                  classes="fixedLightEmitter-PhotometryID-class"
                  elementName={`${elementName}-PhotometryID`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  validationConf={[
                    {
                      validator: Validators.nonEmptyString,
                    },
                  ]}
                  defaultValue={getPhotometryIds(product)[0]}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].PhotometryReference['@photometryId']`)}
                />
                <div style={{ margin: '10px' }}>Light Source ID</div>
                <SelectList
                  mode={mode}
                  requiredField
                  availableListItems={getLightSourceIds(product)}
                  product={product}
                  path={`${path}[${idx}].LightSourceReference['@fixedLightSourceId']`}
                  key={`${elementName}-LightSourceID`}
                  classes="fixedLightEmitter-LightSourceID-class"
                  elementName={`${elementName}-LightSourceID`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  validationConf={[
                    {
                      validator: Validators.nonEmptyString,
                    },
                  ]}
                  defaultValue={getLightSourceIds(product)[0]}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].LightSourceReference['@fixedLightSourceId']`)}
                />
                <div style={{ margin: '10px' }}>Control Gear ID</div>
                <SelectList
                  mode={mode}
                  requiredField
                  availableListItems={getControlGearIds(product)}
                  product={product}
                  path={`${path}[${idx}].ControlGearReference['@controlGearId']`}
                  key={`${elementName}-controlgearID`}
                  classes="fixedLightEmitter-controlgearID-class"
                  elementName={`${elementName}-controlgearID`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  validationConf={[
                    {
                      validator: Validators.nonEmptyString,
                    },
                  ]}
                  defaultValue={getControlGearIds(product)[0]}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].ControlGearReference['@controlGearId']`)}
                />
                <div style={{ margin: '10px' }}>Rated Luminous Flux</div>
                <PlainNumber
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}].RatedLuminousFlux`}
                  key={`${elementName}-RatedLuminousFlux`}
                  classes="fixedLightEmitter-RatedLuminousFlux-class"
                  elementName={`${elementName}-RatedLuminousFlux`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].RatedLuminousFlux`)}
                />
                <div style={{ margin: '10px' }}>Rated Luminous Flux RGB</div>
                <PlainNumber
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}].RatedLuminousFluxRGB`}
                  key={`${elementName}-RatedLuminousFluxRGB`}
                  classes="fixedLightEmitter-RatedLuminousFluxRGB-class"
                  elementName={`${elementName}-RatedLuminousFluxRGB`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].RatedLuminousFluxRGB`)}
                />
                <div style={{ margin: '10px' }}>Emergency Ballast Lumen Factor</div>
                <PlainNumber
                  mode={mode}
                  isFloat={true}
                  product={product}
                  path={`${path}[${idx}].EmergencyBallastLumenFactor`}
                  key={`${elementName}-EmergencyBallastLumenFactor`}
                  classes="fixedLightEmitter-EmergencyBallastLumenFactor-class"
                  elementName={`${elementName}-EmergencyBallastLumenFactor`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  validationConf={[
                    {
                      validator: Validators.range,
                      minValue: 0,
                      maxValue: 1,
                      isInteger: false,
                    },
                  ]}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].EmergencyBallastLumenFactor`)}
                />
                <div style={{ margin: '10px' }}>Emergency Rated Luminous Flux</div>
                <PlainNumber
                  mode={mode}
                  product={product}
                  path={`${path}[${idx}].EmergencyRatedLuminousFlux`}
                  key={`${elementName}-EmergencyRatedLuminousFlux`}
                  classes="fixedLightEmitter-EmergencyRatedLuminousFlux-class"
                  elementName={`${elementName}-EmergencyRatedLuminousFlux`}
                  setValidationErrorsHandler={setValidationErrorsHandler}
                  unSetValidationErrorsHandler={unSetValidationErrorsHandler}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={_.get(product, `${path}[${idx}].EmergencyRatedLuminousFlux`)}
                />

                <div style={{ margin: '10px' }}>Emergency Behavior</div>
                <SelectList
                  mode={mode}
                  product={product}
                  availableListItems={emergencyBehaviorList}
                  path={`${path}[${idx}]['@emergencyBehaviour']`}
                  key={`${elementName}-EmergencyBehaviour`}
                  classes="fixedLightEmitter-EmergencyBehaviour-class"
                  elementName={`${elementName}-EmergencyBehaviour`}
                  debugElement={debugElement}
                  changeAttribute={changeAttribute}
                  elementData={element['@emergencyBehaviour']}
                />
              </>
            </div>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default EmittersFixedLightEmitter;

import * as React from 'react';
import {Button, Chip} from '@mui/material';
import { ImageOrBase64 } from '../../UserInterfaceElements';
import { APIURL, L3DVIEWERURL } from '../../../consts';
import {Home, Launch, OpenInNew, Save} from "@mui/icons-material";

const Files = ({ elementName, elementData, debugElement, fileUUID, product, GLDFBase64FileArray }: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);
  if (Array.isArray(elementData)) {
    return (
      <>
        {elementData.map((element: any) => {
          return (
              <React.Fragment key={`file-${element['$']}`}>
                  {/* First flex element */}
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <div>
                      <div>
                        <Chip
                            label={element['@contentType']}
                            sx={{
                              width: '100%',
                              marginLeft: '0',
                              marginBottom: '1rem',
                              height: '22px',
                              color: '#fff',
                              backgroundColor: '#0074ff',
                              borderRadius: '5px',
                            }}
                        />
                      </div>
                      {(element['@contentType'] === 'image/jpg' || element['@contentType'] === 'image/png') && (
                          <ImageOrBase64
                              fileId={element['@id']}
                              product={product}
                              fileUUID={fileUUID}
                              GLDFBase64FileArray={GLDFBase64FileArray}/>
                              // <img src={`${APIURL}${fileUUID}/gldf/image/${element['$']}`} width="150" />
                      )}
                      {element['@contentType'] === 'geo/l3d' &&
                          <>
                            <img src={`${APIURL}${fileUUID}/gldf/l3d/${element['$']}.png`} width="300"/>
                          </>
                      }
                      {element['@contentType'] === 'ldc/eulumdat' &&
                          <img src={`${APIURL}${fileUUID}/gldf/ldc/${element['$']}.png`} width="300"/>}
                    </div>
                    <div style={{ marginLeft: '2rem'}}>
                      {/* Second flex element*/}
                      <div style={{ marginBottom: '1rem'}}>
                        <Chip
                            label={'Filename'}
                            sx={{
                              width: '100%',
                              marginLeft: '0',
                              marginBottom: '.5rem',
                              height: '22px',
                              color: '#000000',
                              backgroundColor: '#dea500',
                              borderRadius: '5px',
                            }}
                        />
                        <span style={{ margin: '.5rem 0'}}>{element['$']}</span>
                      </div>
                      <div style={{ margin: '1rem 0'}}>
                        <Chip
                            label={'ID'}
                            sx={{
                              width: '100%',
                              marginLeft: '0',
                              marginBottom: '.5rem',
                              height: '22px',
                              color: '#000000',
                              backgroundColor: '#dea500',
                              borderRadius: '5px',
                            }}
                        />
                        <span style={{ margin: '.5rem 0'}}>{element['@id']}</span>
                      </div>
                      <div style={{ margin: '1rem 0'}}>
                        <Chip
                            label={'File type'}
                            sx={{
                              width: '100%',
                              marginLeft: '0',
                              marginBottom: '.5rem',
                              height: '22px',
                              color: '#000000',
                              backgroundColor: '#dea500',
                              borderRadius: '5px',
                            }}
                        />
                        <span style={{ margin: '.5rem 0'}}>{element['@type']}</span>
                      </div>
                      <div>
                        {element['@contentType'] === 'geo/l3d' &&
                            <>
                              <div>
                                <a href={`${L3DVIEWERURL}?uuid=${fileUUID}&filename=${element['$']}`} target="new">
                                  <Button sx={{height: '30px', margin: '1rem 0', width: '100%'}} startIcon={<OpenInNew/>} variant="outlined" size={'small'}
                                  > Open in L3D Viewer
                                  </Button>
                                </a>
                              </div>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                <hr/>
              </React.Fragment>
          );
        })}
      </>
    );
  }
  return debugElement ? <strong style={{ color: '#f68f9c' }}>{`Element: ${elementName} could not be found`}</strong> : null;
};

export default Files;

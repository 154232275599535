import * as React from 'react';
import { TextField, Button } from '@mui/material';
import { validateAll } from '../../../util';
import { errorsList } from '../../../css/theme';
import './style.scss';
import { isNil } from 'lodash';

const PlainNumber = ({
  mode,
  path,
  elementName,
  elementData,
  isFloat,
  readonly,
  validationConf,
  setValidationErrorsHandler,
  unSetValidationErrorsHandler,
  debugElement,
  changeAttribute,
}: any) => {
  debugElement && console.log(`DATA ${elementName}`, elementData, debugElement);

  const [validation, setValidation] = React.useState(false);
  const [localValidationErrors, setLocalValidationErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!isNil(validationConf) && typeof elementData !== 'undefined') {
      const { isValid, validationErrors: validationErrorsArray } = validateAll(elementData, validationConf);
      if (!isValid) {
        setValidation(true);
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrorsArray as string[]);
      } else {
        setValidation(false);
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
  }, []);

  const changeHandler = (e: any) => {
    let castToFloat = false;
    if (!isNil(isFloat)) {
      castToFloat = isFloat;
    }
    e.preventDefault();
    e.stopPropagation();

    if (!isNil(validationConf)) {
      const { isValid, validationErrors } = validateAll(e.target.value, validationConf);
      if (!isValid) {
        setValidation(true);
        setValidationErrorsHandler(path);
        setLocalValidationErrors(validationErrors as string[]);
      } else {
        setValidation(false);
        unSetValidationErrorsHandler(path);
        setLocalValidationErrors([]);
      }
    }
    let outValue = e.target.value as string;
    if (!castToFloat) {
      if (outValue.indexOf('.') > -1) {
        let out = outValue.split('.');
        if (Number.parseFloat(outValue) % 1  === 0) {
          outValue = out[0];
        }        
      }      
    }
    changeAttribute(path, castToFloat ? Number.parseFloat(outValue) : Number.parseInt(outValue), false);

    debugElement && console.log(this);
  };

  if (!isNil(elementData)) {
    return (
      <>
        {mode === 'displayMode' || (typeof readonly !== 'undefined' && readonly) || !mode ? (
          <>
            {Array.isArray(localValidationErrors) && localValidationErrors.length > 0 && (
              <div style={errorsList}>{localValidationErrors.join('<br/>')}</div>
            )}
            <TextField type={'number'} variant="filled" fullWidth={true} disabled style={{ color: 'white' }} size={'small'} value={elementData} />
          </>
        ) : (
          <>
            {Array.isArray(localValidationErrors) && localValidationErrors.length > 0 && (
              <div style={errorsList}>{localValidationErrors.join('<br/>')}</div>
            )}
            <TextField
              type={'number'}
              variant="filled"
              error={validation}
              fullWidth={true}
              size={'small'}
              value={elementData}
              onChange={changeHandler}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ border: '#8c6800 1px solid', color: '#fff', backgroundColor: '#ffbd002e', lineHeight: '0' }}
          onClick={() => {
            changeAttribute(path, isNil(isFloat) ? 0 : 0.01, true, false);
          }}>
          ADD
        </Button>
      </>
    );
  }
};

export default PlainNumber;

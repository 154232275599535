import * as React from "react";
import {
  headerConf,
  productMetaDataDefinitionsConf,
  variantConf,
  descriptiveAttributesConf,
  variantDescriptiveAttributesConf,
  filesConf,
  controlGearsConf,
  equipmentsConf,
  photometriesConf,
  emittersConf,
  geometriesConf,
  lightSourcesConf,
  validationErrorsConf
} from "../conf";

const openModal = (
  mode: string,
  MenuConf: React.SetStateAction<{}>,
  MenuSubConf: React.SetStateAction<{}>,
  isModalOpen: boolean,
  setModalOpen: Function,
  setModalView: Function
) => {
  switch (true) {
    case MenuConf === 'validationErrorsConf':
      setModalView({ mode: mode, menuConf: validationErrorsConf, subMenuConf: "" });
      break;
    case MenuConf === "filesConf":
      setModalView({ mode: mode, menuConf: filesConf, subMenuConf: "" });
      break;
    case MenuConf === "headerConf":
      setModalView({ mode: mode, menuConf: headerConf, subMenuConf: "" });
      break;
    case MenuConf === "equipmentsConf":
      setModalView({ mode: mode, menuConf: equipmentsConf, subMenuConf: "" });
      break;
    case MenuConf === "photometriesConf":
      setModalView({
        mode: mode,
        menuConf: photometriesConf,
        subMenuConf: "",
      });
      break;
    case MenuConf === "controlGearsConf":
      setModalView({
        mode: mode,
        menuConf: controlGearsConf,
        subMenuConf: "",
      });
      break;
    case MenuConf === "productMetaDataDefinitionsConf":
      setModalView({
        mode: mode,
        menuConf: productMetaDataDefinitionsConf,
        subMenuConf: "",
      });
      break;
    case MenuConf === "emittersConf":
      setModalView({ mode: mode, menuConf: emittersConf, subMenuConf: "" });
      break;
    case MenuConf === "geometriesConf":
      setModalView({ mode: mode, menuConf: geometriesConf, subMenuConf: "" });
      break;
    case MenuConf === "lightSourcesConf":
      setModalView({
        mode: mode,
        menuConf: lightSourcesConf,
        subMenuConf: "",
      });
      break;
    case MenuConf === "variantConf":
      setModalView({ mode: mode, menuConf: variantConf, subMenuConf: "" });
      break;
    case MenuConf === "VariantDescriptiveAttributesConf" &&
      MenuSubConf === "Mechanical":
      setModalView({
        mode: mode,
        menuConf: variantDescriptiveAttributesConf,
        subMenuConf: "Mechanical",
      });
      break;
    case MenuConf === "VariantDescriptiveAttributesConf" &&
      MenuSubConf === "Electrical":
      setModalView({
        mode: mode,
        menuConf: variantDescriptiveAttributesConf,
        subMenuConf: "Electrical",
      });
      break;
    case MenuConf === "VariantDescriptiveAttributesConf" &&
      MenuSubConf === "Emergency":
      setModalView({
        mode: mode,
        menuConf: variantDescriptiveAttributesConf,
        subMenuConf: "Emergency",
      });
      break;
    case MenuConf === "VariantDescriptiveAttributesConf" &&
      MenuSubConf === "MountingAndAccessory":
      setModalView({
        mode: mode,
        menuConf: variantDescriptiveAttributesConf,
        subMenuConf: "MountingAndAccessory",
      });
      break;
    case MenuConf === "VariantDescriptiveAttributesConf" &&
      MenuSubConf === "Marketing":
      setModalView({
        mode: mode,
        menuConf: variantDescriptiveAttributesConf,
        subMenuConf: "Marketing",
      });
      break;
    case MenuConf === "VariantDescriptiveAttributesConf" &&
      MenuSubConf === "OperationsAndMaintenance":
      setModalView({
        mode: mode,
        menuConf: variantDescriptiveAttributesConf,
        subMenuConf: "OperationsAndMaintenance",
      });
      break;
    case MenuConf === "DescriptiveAttributesConf" &&
      MenuSubConf === "Mechanical":
      setModalView({
        mode: mode,
        menuConf: descriptiveAttributesConf,
        subMenuConf: "Mechanical",
      });
      break;
    case MenuConf === "DescriptiveAttributesConf" &&
      MenuSubConf === "Electrical":
      setModalView({
        mode: mode,
        menuConf: descriptiveAttributesConf,
        subMenuConf: "Electrical",
      });
      break;
    case MenuConf === "DescriptiveAttributesConf" &&
      MenuSubConf === "Emergency":
      setModalView({
        mode: mode,
        menuConf: descriptiveAttributesConf,
        subMenuConf: "Emergency",
      });
      break;
    case MenuConf === "DescriptiveAttributesConf" &&
      MenuSubConf === "MountingAndAccessory":
      setModalView({
        mode: mode,
        menuConf: descriptiveAttributesConf,
        subMenuConf: "MountingAndAccessory",
      });
      break;
    case MenuConf === "DescriptiveAttributesConf" &&
      MenuSubConf === "Marketing":
      setModalView({
        mode: mode,
        menuConf: descriptiveAttributesConf,
        subMenuConf: "Marketing",
      });
      break;
    case MenuConf === "DescriptiveAttributesConf" &&
      MenuSubConf === "OperationsAndMaintenance":
      setModalView({
        mode: mode,
        menuConf: descriptiveAttributesConf,
        subMenuConf: "OperationsAndMaintenance",
      });
      break;
  }
  if (!isModalOpen) setModalOpen(true);
};

const closeModal = (setModalOpen: Function) => {
  setModalOpen(false);
};

export { openModal, closeModal };

import { DisplayElementTypes } from "../consts"
const variantsConf = {
    blockName: "variants",
    blockClasses: ["variantsClass-1", "variantsClass-2"],
    blockList: [
        {
            displayElementName: "Variants",
            displayElementType: DisplayElementTypes.Variants,
            displayElementClasses: ["variantsClass-1"],
            displayElementPath: "ProductDefinitions.Variants.Variant"
        },
    ]
}

export default variantsConf;